import React from "react";

interface ModalProps {
  onClose: () => void;
  onConfirm: () => void;
  onDiscard: () => void;
  changes: string[];
}

const Modal: React.FC<ModalProps> = ({
  onClose,
  onConfirm,
  onDiscard,
  changes,
}) => {
  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-white rounded-lg max-w-md w-11/12 shadow-md'>
        <div className='bg-red-500 text-white p-2 rounded-t'>
          <h2 className='text-lg font-semibold'>Unsaved Changes</h2>
        </div>
        <div className='bg-white p-4 rounded-b border border-gray-200'>
          <p className='mb-2'>
            You have unsaved changes! Would you like to save them before
            leaving?
          </p>
          {changes.length > 0 && (
            <ul className='list-disc list-inside'>
              {changes.map((change, index) => (
                <li key={index} className='font-semibold'>
                  {change}
                </li>
              ))}
            </ul>
          )}
          <div className='flex justify-end mt-5 gap-2'>
            <button
              onClick={onConfirm}
              className='bg-blue-600 text-white font-semibold px-5 py-2 rounded cursor-pointer hover:bg-blue-700'
            >
              Save
            </button>
            <button
              onClick={onDiscard}
              className='bg-red-600 text-white font-semibold px-5 py-2 rounded cursor-pointer hover:bg-red-700'
            >
              Discard
            </button>
            <button
              onClick={onClose}
              className='bg-gray-300 text-black px-5 py-2 rounded cursor-pointer hover:bg-gray-700'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
