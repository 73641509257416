import React, { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa6";

// Define the interface for the component props
interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: React.ElementType;
  type: "pageNavigation" | "textEdit" | "click";
}

interface ActionPickerForAccordionProps {
  events: TimelineEvent[];
  onHoverEvent: (time: number) => void;
  onClickEvent: (time: number) => void;
}

const ActionPickerForAccordion: React.FC<ActionPickerForAccordionProps> = ({
  events,
  onHoverEvent,
  onClickEvent,
}) => {
  // State to manage which page navigations are expanded
  const [expandedSections, setExpandedSections] = useState<Set<number>>(
    new Set(),
  );

  // Helper function to toggle expand/collapse
  const toggleExpand = (index: number) => {
    const newExpandedSections = new Set(expandedSections);
    if (newExpandedSections.has(index)) {
      newExpandedSections.delete(index);
    } else {
      newExpandedSections.add(index);
    }
    setExpandedSections(newExpandedSections);
  };

  // Sort events by time, smallest to largest
  const sortedEvents = [...events].sort((a, b) => a.time - b.time);

  // Group events by page navigation
  const groupedEvents = sortedEvents.reduce(
    (acc, event, index) => {
      if (event.type === "pageNavigation") {
        acc.push({ parent: event, children: [], index });
      } else if (acc.length > 0) {
        acc[acc.length - 1].children.push(event);
      }
      return acc;
    },
    [] as { parent: TimelineEvent; children: TimelineEvent[]; index: number }[],
  );

  return (
    <div className='action-picker-accordion'>
      {groupedEvents.map(({ parent, children, index }) => (
        <div key={index}>
          <div className='flex items-center'>
            {/* Expand/Collapse icon */}
            <div className='cursor-pointer' onClick={() => toggleExpand(index)}>
              {expandedSections.has(index) ? <FaCaretUp /> : <FaCaretDown />}
            </div>

            {/* Parent page navigation event */}
            <div
              className='action-item flex items-center cursor-pointer py-1 px-2 hover:bg-gray-200'
              onMouseEnter={() => onHoverEvent(parent.time)}
              onClick={() => onClickEvent(parent.time)} // Seek to the time in the timeline
            >
              <parent.Icon size={20} className='mr-2' />
              <span>{parent.text}</span>
            </div>
          </div>

          {/* Child events (clicks and text edits) */}
          {expandedSections.has(index) && (
            <div
              className='ml-8 pl-2 border-l-2 border-gray-300 relative'
              style={{ marginLeft: "2.1rem" }}
            >
              {children.map((child, childIndex) => (
                <div
                  key={childIndex}
                  className='action-item flex items-center cursor-pointer py-1 px-2 hover:bg-gray-200'
                  onMouseEnter={() => onHoverEvent(child.time)}
                  onClick={() => onClickEvent(child.time)}
                >
                  <child.Icon size={20} className='mr-2' />
                  <span>{child.text}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ActionPickerForAccordion;
