import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

interface MenuItem {
  title: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  selected?: boolean;
  hoveredNotSelectedClassName?: string;
  hoveredSelectedClassName?: string;
  notHoveredNotSelectedClassName?: string;
  notHoveredSelectedClassName?: string;
  subMenu?: React.ReactNode;
  subMenuPosition?: "left" | "right" | "auto";
}

interface HoverMenuButtonProps {
  titlePrimary: string;
  titleSecondary?: string;
  titleHoverPrimary?: string;
  titleHoverSecondary?: string;
  items: MenuItem[];
  bgPrimaryClass?: string;
  bgSecondaryClass?: string;
  bgPrimaryHoverClass?: string;
  bgSecondaryHoverClass?: string;
  textPrimaryClass?: string;
  textSecondaryClass?: string;
  textPrimaryHoverClass?: string;
  textSecondaryHoverClass?: string;
  fontBold?: boolean;
  additionalClassNames?: string;
  icon?: React.ReactNode;
  isPrimary?: boolean;
  hideDelay?: number;
}

const HoverMenuButton: React.FC<HoverMenuButtonProps> = ({
  titlePrimary,
  titleSecondary,
  titleHoverPrimary,
  titleHoverSecondary,
  items,
  bgPrimaryClass = "bg-gray-200",
  bgSecondaryClass = "bg-gray-300",
  bgPrimaryHoverClass,
  bgSecondaryHoverClass,
  textPrimaryClass = "text-black",
  textSecondaryClass = "text-white",
  textPrimaryHoverClass = "hover:text-black",
  textSecondaryHoverClass = "hover:text-white",
  fontBold = false,
  additionalClassNames = "",
  icon = null,
  isPrimary = true,
  hideDelay = 200,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [maxButtonWidth, setMaxButtonWidth] = useState<number>(0);

  // Calculate max button width based on titles
  useEffect(() => {
    if (buttonRef.current) {
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      document.body.appendChild(tempSpan);

      const titles = [
        titlePrimary,
        titleSecondary,
        titleHoverPrimary,
        titleHoverSecondary,
      ].filter(Boolean) as string[];

      let maxWidth = 0;
      titles.forEach((title) => {
        tempSpan.innerText = title;
        maxWidth = Math.max(maxWidth, tempSpan.offsetWidth);
      });

      document.body.removeChild(tempSpan);
      setMaxButtonWidth(maxWidth);
    }
  }, [titlePrimary, titleSecondary, titleHoverPrimary, titleHoverSecondary]);

  // Handle mouse enter on the entire container (button + submenu area)
  const handleMouseEnterContainer = () => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    setIsHovered(true);
  };

  // Handle mouse leave on the entire container
  const handleMouseLeaveContainer = () => {
    hideTimeoutRef.current = setTimeout(() => {
      setIsHovered(false);
      setHoveredItemIndex(null);
    }, hideDelay);
  };

  // Handle mouse enter on an individual menu item
  const handleItemMouseEnter = (index: number) => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
    }
    setHoveredItemIndex(index);
  };

  // Handle mouse leave on an individual menu item
  const handleItemMouseLeave = () => {
    hideTimeoutRef.current = setTimeout(() => {
      setHoveredItemIndex(null);
    }, hideDelay);
  };

  // Determine current title based on hover state and primary/secondary state
  const currentTitle = isHovered
    ? isPrimary
      ? titleHoverPrimary || titlePrimary
      : titleHoverSecondary || titleSecondary || titlePrimary
    : isPrimary
    ? titlePrimary
    : titleSecondary || titlePrimary;

  // Determine the background class based on hover state and primary/secondary state
  const currentBgClass = isHovered
    ? isPrimary
      ? bgPrimaryHoverClass || bgPrimaryClass
      : bgSecondaryHoverClass || bgSecondaryClass
    : isPrimary
    ? bgPrimaryClass
    : bgSecondaryClass;

  const currentTextClass = isHovered
    ? isPrimary
      ? textPrimaryHoverClass || textPrimaryClass
      : textSecondaryHoverClass || textSecondaryClass
    : isPrimary
    ? textPrimaryClass
    : textSecondaryClass;

  const buttonClass = classNames(
    "inline-flex items-center h-10 px-5 transition-colors duration-150 rounded-lg focus:shadow-outline",
    fontBold ? "font-bold" : "",
    additionalClassNames,
    currentBgClass,
    currentTextClass,
  );

  // MenuItemComponent to handle individual item hover and subMenu
  const MenuItemComponent: React.FC<{ item: MenuItem; index: number }> = ({
    item,
    index,
  }) => {
    const handleMouseEnter = () => {
      handleItemMouseEnter(index);
    };

    const handleMouseLeave = () => {
      handleItemMouseLeave();
    };

    // Determine submenu position
    const subMenuPosition = item.subMenuPosition || "auto";

    const subMenuStyles = {
      left:
        subMenuPosition === "left"
          ? "right-full mr-[-1px]"
          : subMenuPosition === "right"
          ? "left-full ml-[-1px]"
          : "left-full ml-[-1px]", // Default to right if 'auto' or unspecified
    };

    // Determine the appropriate class name based on the state of the item
    const itemClassName = classNames(
      "flex items-center w-full text-left px-4 py-2 transition-colors duration-150",
      item.selected
        ? hoveredItemIndex === index
          ? item.hoveredSelectedClassName // Hovered and Selected
          : item.notHoveredSelectedClassName // Not Hovered but Selected
        : hoveredItemIndex === index
        ? item.hoveredNotSelectedClassName // Hovered but Not Selected
        : item.notHoveredNotSelectedClassName, // Not Hovered and Not Selected
    );

    return (
      <div
        className='relative'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button onClick={item.onClick} className={itemClassName}>
          {item.icon && <span className='mr-2'>{item.icon}</span>}
          {item.title}
        </button>
        {hoveredItemIndex === index && item.subMenu && (
          <div
            className={classNames(
              "absolute top-0 mt-0 rounded-lg shadow-lg bg-white z-10",
              subMenuStyles.left,
            )}
            style={{
              minWidth: "200px",
              width: "max-content",
              overflow: "visible",
            }}
          >
            {item.subMenu}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className='relative inline-block'
      onMouseEnter={handleMouseEnterContainer}
      onMouseLeave={handleMouseLeaveContainer}
    >
      {/* Main Button */}
      <button
        ref={buttonRef}
        className={buttonClass}
        style={{
          width: `calc(${maxButtonWidth}px + 40px + ${icon ? "40px" : "0"})`,
        }}
      >
        <span className='flex-1'>{currentTitle}</span>
        {icon && <span className='ml-3'>{icon}</span>}
      </button>

      {/* Dropdown Menu */}
      {isHovered && (
        <div
          className='absolute left-0 mt-2 rounded-lg shadow-lg bg-white z-10'
          style={{
            width: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {items.map((item, index) => (
            <MenuItemComponent key={index} item={item} index={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default HoverMenuButton;
