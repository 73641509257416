import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import RemainingHeightContainer from "../components/RemainingHeightContainer";

const PAGE_PATH = "/insights/embedded";
const SUPERSET_PAGE = "https://analytics.sailfishqa.com";

const EmbeddedSupersetPage = () => {
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [pathWithoutToken, setPathWithoutToken] = useState<string | null>(null);
  const token = localStorage.getItem("jwt");
  const navigate = useNavigate();
  const location = useLocation();
  const ignoreIframeMessagesRef = useRef(false); // Ref to track iframe navigation

  // Function to remove the "auth_token" from the query parameters
  const stripAuthTokenFromQuery = (query: string) => {
    const params = new URLSearchParams(query);
    params.delete("auth_token"); // Remove auth_token if present
    return params.toString() ? `?${params.toString()}` : ""; // Rebuild query string without auth_token
  };

  // Update iframe URL based on current path and query params from React Router
  useEffect(() => {
    // Don't change the iframe's URL when reacting to its own navigation
    if (ignoreIframeMessagesRef.current) {
      ignoreIframeMessagesRef.current = false;
      return;
    }

    const iframePath = location.pathname.replace(PAGE_PATH, "");
    let queryParams = location.search;

    // Strip the "auth_token" from the query parameters for the pathWithoutToken
    const queryWithoutToken = stripAuthTokenFromQuery(queryParams);

    // Create the path without token for display in the browser address bar
    const pathWithoutTokenIframe = `${iframePath}${queryWithoutToken}`;
    const pathWithoutTokenPage = `${PAGE_PATH}${iframePath}${queryWithoutToken}`;
    setPathWithoutToken(pathWithoutTokenPage); // Set state for the browser URL without the token

    // Append the token to the iframe URL only (not in the browser URL)
    let targetUrl = `${SUPERSET_PAGE}${pathWithoutTokenIframe}`;
    if (token) {
      const url = new URL(targetUrl);
      targetUrl += url.search ? `&auth_token=${token}` : `?auth_token=${token}`;
    }

    setIframeUrl(targetUrl); // Set the final iframe URL (with the token)
  }, [token, location]);

  // Listen for messages from the iframe (for cross-origin scenarios)
  useEffect(() => {
    const handleIframeMessage = (event: MessageEvent) => {
      // Ensure the message is from the expected iframe source
      if (event.origin !== SUPERSET_PAGE) return;

      // Extract the path from the iframe URL (e.g., "/superset/welcome/")
      const iframePath = new URL(event.data.iframeUrl).pathname;
      const searchParams = new URL(event.data.iframeUrl).search;

      // Construct the new URL based on the parent page's current location, without the token
      const newUrl = `${PAGE_PATH}${iframePath}${searchParams}`;

      // Prevent React from updating the iframe's URL for iframe-originated navigation
      ignoreIframeMessagesRef.current = true;

      // Use React Router's navigate function to update the route, excluding the token
      navigate(newUrl, { replace: true }); // Replace the current history entry
    };

    window.addEventListener("message", handleIframeMessage);

    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [navigate]);

  return (
    <ApplicationPage pageName='Analytics'>
      <RemainingHeightContainer
        ids={["applicationPageHeader"]}
        initialClassName='bg-sky-50 rounded-lg'
        minHeight='400px'
        onHeightChange={() => {}}
      >
        <iframe
          id='supersetIframe'
          src={iframeUrl || SUPERSET_PAGE}
          style={{ width: "100%", height: "100vh", border: "none" }}
          title='Superset'
          sandbox='allow-same-origin allow-scripts allow-forms allow-popups'
          allow='cross-origin'
        />
      </RemainingHeightContainer>
    </ApplicationPage>
  );
};

export default EmbeddedSupersetPage;
