import { useCallback, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useNavigate } from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import DashboardTile from "../components/DashboardTile";
import ExpandableAndSelectableLists from "../components/ExpandableAndSelectableLists";
import LoadingAnimation from "../components/LoadingAnimation";
import RemainingHeightContainer from "../components/RemainingHeightContainer";
import SearchResultsNavList from "../components/Search/ResultsNavList";
import SearchBar from "../components/Search/SearchBar";
import { useCompany } from "../contexts/CompanyContext";
import formatRelativeTime from "../utils/dateFns";

const DEFAULT_ITEMS_PER_PAGE = 25;
const DEBUG = import.meta.env.VITE_DEBUG ? import.meta.env.VITE_DEBUG : false;

const ResponsiveGridLayout = WidthProvider(Responsive);

const ChartReportsPage = () => {
  const { selectedCompany } = useCompany();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE);
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0); // New state for dynamic height
  const navigate = useNavigate();

  const handleSearchResults = useCallback((results: any[]) => {
    if (DEBUG) console.log("handleSearchResults", "results", results);
    setResults(results);
  }, []);

  const handleTotalItemsCount = (count: number) => {
    setTotalItemsCount(count);
  };

  const headerTemplate = (item: any) => (
    <div>
      <div className='text-slate-800 text-lg'>
        <span className='font-bold'>Reports:</span> {item.title}
      </div>
      <div className='text-slate-500'>
        <span className='font-semibold'>Description:</span>{" "}
        <span className=''>{formatRelativeTime(item.description)}</span>
      </div>
      <div className='text-slate-500'>
        <span className='font-semibold'>Created:</span>{" "}
        <span className=''>{formatRelativeTime(item.createdAt)}</span>
      </div>
    </div>
  );

  const gutterTemplate = (item: any) => (
    <div>
      <strong>Timestamp:</strong> {item.timestampFormatted},{" "}
      <strong>Recording ID:</strong> {item.recordingId}
    </div>
  );

  const handleRowDoubleClick = (id: string) => {
    navigate(`/insights/reports/${id}`);
  };

  // Define the tiles configuration
  const tiles = [
    {
      id: "reports",
      title: "Reports",
      content: (
        <div className='flex flex-col h-full relative'>
          {/* Search Bar at the Top */}
          <div className='flex-shrink-0'>
            <SearchBar
              entity='reports'
              limit={itemsPerPage}
              offset={(page - 1) * itemsPerPage}
              onSearchResults={handleSearchResults}
              setTotalItemsCount={handleTotalItemsCount}
              onLoadingChange={setLoading}
              orderBy='-created_at'
            />
          </div>

          {/* List that expands and scrolls */}
          <div className='flex-grow overflow-auto'>
            {loading ? (
              <LoadingAnimation text='Loading...' />
            ) : (
              <ExpandableAndSelectableLists
                items={results}
                headerTemplate={headerTemplate}
                gutterTemplate={gutterTemplate}
                showTimelineBarForRows={false}
                onDoubleClick={handleRowDoubleClick}
              />
            )}
          </div>

          {/* Navigation List pinned at the Bottom */}
          <div className='flex-shrink-0'>
            <SearchResultsNavList
              page={page}
              totalItemsCount={totalItemsCount}
              itemsPerPage={itemsPerPage}
              setPage={setPage}
              setItemsPerPage={setItemsPerPage}
            />
          </div>
        </div>
      ),
      layout: { i: "reports", x: 0, y: 0, w: 1, h: 1 },
    },
  ];

  return (
    <ApplicationPage pageName='Reports'>
      {/* RemainingHeightContainer for dynamic height adjustment */}
      <RemainingHeightContainer
        ids={["applicationPageHeader"]}
        initialClassName=''
        minHeight='400px'
        onHeightChange={setContainerHeight} // New prop to set dynamic height
      >
        <div style={{ height: `${containerHeight}px` }} className='flex-grow'>
          {/* Use the tiles with ResponsiveGridLayout */}
          <ResponsiveGridLayout
            className='layout'
            layouts={{ lg: tiles.map((tile) => tile.layout) }}
            breakpoints={{ lg: 1200 }}
            cols={{ lg: 1 }}
            rowHeight={containerHeight - 20} // Dynamic height from state
            width={1200}
            containerPadding={[15, 15]}
            draggableHandle='.drag-handle'
          >
            {tiles.map((tile) => (
              <div key={tile.id} className='p-0 m-0'>
                <DashboardTile
                  key={tile.id}
                  id={tile.id}
                  title={tile.title}
                  onRemove={() => {}}
                >
                  {tile.content}
                </DashboardTile>
              </div>
            ))}
          </ResponsiveGridLayout>
        </div>
      </RemainingHeightContainer>
    </ApplicationPage>
  );
};

export default ChartReportsPage;
