import React, { useCallback, useEffect, useRef, useState } from "react";
import { GoTriangleLeft, GoTriangleRight } from "react-icons/go";
import { useAuth } from "../../contexts/AuthContext";
import ContextAltClickMenu from "../Menus/ContextAltClickMenu";

const DEBUG = import.meta.env.VITE_DEBUG ? import.meta.env.VITE_DEBUG : false;

interface Event {
  time: number;
  icon: React.ReactNode;
  color: string;
  text: string;
}

export interface Region {
  start: number;
  end: number;
}

interface TimelineProps {
  playPosition: number;
  totalTime: number;
  events: Event[];
  inactivePeriods?: { start: number; end: number }[]; // Make it optional
  onTimelineClick: (percentage: number) => void;
  displayOnly?: boolean; // Optional prop to enable display-only mode
  selectedRegions: Region[]; // Pass selected regions to Timeline
  setSelectedRegions: React.Dispatch<React.SetStateAction<Region[]>>; // Allow Timeline to update selected regions
}

const TimelineForRrwebPlayer: React.FC<TimelineProps> = ({
  playPosition,
  totalTime,
  events,
  inactivePeriods = [], // Default to an empty array
  onTimelineClick,
  displayOnly = false,
  selectedRegions,
  setSelectedRegions,
}) => {
  const { isSailfishQAMember } = useAuth(); // Assume user includes { name, picture }
  const [undoStack, setUndoStack] = useState<
    { regions: Region[]; cursor: number | null; playPosition: number | null }[]
  >([]);

  const [redoStack, setRedoStack] = useState<
    { regions: Region[]; cursor: number | null; playPosition: number | null }[]
  >([]);
  const [pendingRegions, setPendingRegions] = useState<
    { start: number; end: number; action: string }[]
  >([]);
  const [pendingRegion, setPendingRegion] = useState<Region | null>(null);
  const [lastVerticalBarPosition, setLastVerticalBarPosition] = useState<
    number | null
  >(null);
  // const [clickOrigin, setClickOrigin] = useState<number | null>(null);
  const [dragStartPosition, setDragStartPosition] = useState<number | null>(
    null,
  );
  const [, setCurrentTime] = useState<number>(0); // Current time in seconds
  const [hoveredEvent, setHoveredEvent] = useState<Event | null>(null);
  // const [currentTime, setCurrentTime] = useState<number>(0); // Current time in seconds
  const [isDragging, setIsDragging] = useState(false);
  const [isDragSelecting, setIsDragSelecting] = useState<boolean>(false);
  const [shiftAnchorPosition, setShiftAnchorPosition] = useState<number | null>(
    null,
  );
  const [isShiftPressed, setIsShiftPressed] = useState<boolean>(false);
  const [hoveredRegion, setHoveredRegion] = useState<number | null>(null);
  const [, setHoveredSide] = useState<"left" | "right" | null>(null);
  // const [hoveredSide, setHoveredSide] = useState<"left" | "right" | null>(null);
  const [verticalBarPosition, setVerticalBarPosition] = useState<number | null>(
    playPosition,
  );
  const [currentPlayPosition, setCurrentPlayPosition] = useState<number | null>(
    playPosition,
  );

  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [contextRegionIndex, setContextRegionIndex] = useState<number | null>(
    null,
  );
  const [dragHandleInfo, setDragHandleInfo] = useState<{
    index: number;
    side: "left" | "right";
  } | null>(null);

  const isDraggingRef = useRef<boolean>(false);

  const timelineRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setVerticalBarPosition(playPosition);
    setCurrentPlayPosition(playPosition);
    if (DEBUG) console.log("playPosition", playPosition);
  }, [playPosition]);

  useEffect(() => {
    if (verticalBarPosition === null) {
      setVerticalBarPosition(verticalBarPosition); // Capture the initial position
    }
  }, [verticalBarPosition]);

  const pushToUndoStack = useCallback(() => {
    setUndoStack((prevStack) => [
      ...prevStack,
      {
        regions: selectedRegions,
        cursor: verticalBarPosition,
        playPosition: currentPlayPosition,
      },
    ]);
    setRedoStack([]); // Clear redo stack on new action
  }, [selectedRegions, verticalBarPosition, currentPlayPosition]);

  const handleUndo = useCallback(() => {
    if (undoStack.length > 0) {
      const lastState = undoStack[undoStack.length - 1];
      setUndoStack((prevStack) => prevStack.slice(0, -1));

      setRedoStack((prevStack) => [
        ...prevStack,
        {
          regions: selectedRegions,
          cursor: verticalBarPosition,
          playPosition: currentPlayPosition,
        },
      ]);

      setSelectedRegions(lastState.regions);
      setVerticalBarPosition(lastState.cursor);
      setCurrentPlayPosition(lastState.playPosition);

      if (undoStack.length === 1) {
        setSelectedRegions([]); // Clear all highlighted regions
        setVerticalBarPosition(playPosition); // Reset to initial cursor position
        setCurrentPlayPosition(playPosition); // Reset play position to initial value
      }
    }
  }, [
    undoStack,
    selectedRegions,
    verticalBarPosition,
    currentPlayPosition,
    playPosition,
    setSelectedRegions,
  ]);

  const handleRedo = useCallback(() => {
    if (redoStack.length > 0) {
      const nextState = redoStack[redoStack.length - 1];
      setRedoStack((prevStack) => prevStack.slice(0, -1));

      setUndoStack((prevStack) => [
        ...prevStack,
        {
          regions: selectedRegions,
          cursor: verticalBarPosition,
          playPosition: currentPlayPosition,
        },
      ]);

      setSelectedRegions(nextState.regions);
      setVerticalBarPosition(nextState.cursor);
      setCurrentPlayPosition(nextState.playPosition);
    }
  }, [
    redoStack,
    selectedRegions,
    verticalBarPosition,
    currentPlayPosition,
    setSelectedRegions,
  ]);

  const processSelectedRegions = (
    regions: { start: number; end: number; action?: string }[],
  ): { start: number; end: number }[] => {
    const finalRegions: { start: number; end: number }[] = [];

    regions.forEach((region) => {
      const normalizedRegion = {
        start: Math.min(region.start, region.end),
        end: Math.max(region.start, region.end),
      };

      const action = region.action || "normal";

      if (action === "normal" || action === "add") {
        finalRegions.push(normalizedRegion);
      } else if (action === "subtract") {
        for (let i = 0; i < finalRegions.length; i++) {
          const existingRegion = finalRegions[i];
          if (
            normalizedRegion.start <= existingRegion.end &&
            normalizedRegion.end >= existingRegion.start
          ) {
            if (
              normalizedRegion.start <= existingRegion.start &&
              normalizedRegion.end >= existingRegion.end
            ) {
              finalRegions.splice(i, 1);
              i--;
            } else if (
              normalizedRegion.start > existingRegion.start &&
              normalizedRegion.end < existingRegion.end
            ) {
              const newRegion = {
                start: normalizedRegion.end,
                end: existingRegion.end,
              };
              finalRegions[i].end = normalizedRegion.start;
              finalRegions.splice(i + 1, 0, newRegion);
            } else if (normalizedRegion.start <= existingRegion.start) {
              finalRegions[i].start = normalizedRegion.end;
            } else if (normalizedRegion.end >= existingRegion.end) {
              finalRegions[i].end = normalizedRegion.start;
            }
          }
        }
      }
    });

    finalRegions.sort((a, b) => a.start - b.start);
    for (let i = 0; i < finalRegions.length - 1; i++) {
      if (finalRegions[i].end >= finalRegions[i + 1].start) {
        finalRegions[i].end = Math.max(
          finalRegions[i].end,
          finalRegions[i + 1].end,
        );
        finalRegions.splice(i + 1, 1);
        i--;
      }
    }

    return [...finalRegions]; // Return a new array to ensure immutability
  };

  // Updated functions to track changes
  const updateRegions = useCallback(
    (newRegions: Region[]) => {
      setSelectedRegions(newRegions);
    },
    [setSelectedRegions],
  );

  const handleRemoveHighlight = useCallback(() => {
    if (contextRegionIndex !== null) {
      const updatedRegions = selectedRegions.filter(
        (_, i) => i !== contextRegionIndex,
      );
      updateRegions(updatedRegions);
      pushToUndoStack(); // Save the state after removing a highlight
      handleContextMenuClose();
    }
  }, [contextRegionIndex, selectedRegions, updateRegions, pushToUndoStack]);

  const handleHighlightOnlyThis = useCallback(() => {
    if (contextRegionIndex !== null) {
      const region = selectedRegions[contextRegionIndex];
      updateRegions([region]);
      pushToUndoStack(); // Save the state after highlighting only this
      handleContextMenuClose();
    }
  }, [contextRegionIndex, selectedRegions, updateRegions, pushToUndoStack]);

  const handleHighlightAllLeft = useCallback(() => {
    if (contextRegionIndex !== null) {
      const region = selectedRegions[contextRegionIndex];
      const newRegion = { start: 0, end: region.end };
      updateRegions([newRegion]);
      pushToUndoStack(); // Save the state after highlighting all to the left
      handleContextMenuClose();
    }
  }, [contextRegionIndex, selectedRegions, updateRegions, pushToUndoStack]);

  const handleHighlightAllRight = useCallback(() => {
    if (contextRegionIndex !== null) {
      const region = selectedRegions[contextRegionIndex];
      const newRegion = { start: region.start, end: 100 }; // Assuming 100 is the end of the timeline
      updateRegions([newRegion]);
      pushToUndoStack(); // Save the state after highlighting all to the right
      handleContextMenuClose();
    }
  }, [contextRegionIndex, selectedRegions, updateRegions, pushToUndoStack]);

  const contextMenuItems = [
    {
      label: "Remove highlight",
      action: handleRemoveHighlight,
      disabled: false,
    },
    {
      label: "Highlight only this",
      action: handleHighlightOnlyThis,
      disabled: false,
    },
    {
      label: "Highlight all left",
      action: handleHighlightAllLeft,
      disabled: false,
    },
    {
      label: "Highlight all right",
      action: handleHighlightAllRight,
      disabled: false,
    },
  ];

  // Function to normalize the regions
  const normalizeRegions = (regions: Region[]): Region[] => {
    if (DEBUG) console.log("normalizeRegions; start =", regions);

    // Merge any overlapping or contiguous regions
    regions.sort((a, b) => a.start - b.start);
    const mergedRegions: Region[] = [];

    regions.forEach((region) => {
      if (mergedRegions.length === 0) {
        mergedRegions.push(region);
      } else {
        const lastRegion = mergedRegions[mergedRegions.length - 1];
        if (region.start <= lastRegion.end) {
          // Regions overlap or are contiguous, merge them
          lastRegion.end = Math.max(lastRegion.end, region.end);
        } else {
          // No overlap, add as a new region
          mergedRegions.push(region);
        }
      }
    });

    if (DEBUG) console.log("normalizeRegions; final =", mergedRegions);
    return mergedRegions;
  };

  // Usage in the component
  const handleDragHandleStart = (
    e: React.MouseEvent<HTMLDivElement>,
    index: number,
    side: "left" | "right",
  ) => {
    if (displayOnly) return; // Prevent dragging in display-only mode

    e.preventDefault(); // Prevent text selection
    e.stopPropagation();
    setIsDragging(true);
    setDragHandleInfo({ index, side });
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (displayOnly) return; // Prevent interactions in display-only mode

      e.preventDefault(); // Prevent text selection
      if (!isDragging || dragHandleInfo === null) return;

      const rect = timelineRef.current?.getBoundingClientRect();
      if (!rect) return;

      let percentage = ((e.clientX - rect.left) / rect.width) * 100;

      const updatedRegions = [...selectedRegions];
      const currentRegion = { ...updatedRegions[dragHandleInfo.index] };

      if (dragHandleInfo.side === "left") {
        currentRegion.start = Math.min(percentage, currentRegion.end - 1);
      } else if (dragHandleInfo.side === "right") {
        currentRegion.end = Math.max(percentage, currentRegion.start + 1);
      }

      updatedRegions[dragHandleInfo.index] = currentRegion;

      updateRegions(updatedRegions);
    },
    [displayOnly, isDragging, dragHandleInfo, selectedRegions, updateRegions],
  );

  const handleNativeMouseMove = useCallback(
    (e: MouseEvent) => {
      const rect = timelineRef.current?.getBoundingClientRect();
      if (rect && dragStartPosition !== null) {
        const percentage = ((e.clientX - rect.left) / rect.width) * 100;
        const lastRegion = pendingRegions[pendingRegions.length - 1];

        if (lastRegion) {
          lastRegion.end = percentage;
          setPendingRegions([...pendingRegions]);
        }
      }
    },
    [dragStartPosition, pendingRegions],
  );

  // Update the handleMouseUp function with explicit typing for prevRegions
  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      e.preventDefault(); // Prevent text selection

      // Define handleNativeMouseUp inside handleMouseUp
      const handleNativeMouseUp = (e: MouseEvent) => {
        handleMouseUp(e);
      };

      if (isDragging && dragHandleInfo !== null) {
        // Handle dragging (iMovie effect)
        setSelectedRegions((prevRegions: Region[]) => {
          const updatedRegions = [...prevRegions];
          const currentRegion = { ...updatedRegions[dragHandleInfo.index] }; // Copy the current region
          let mergeStart = currentRegion.start;
          let mergeEnd = currentRegion.end;

          // Determine the extents of the union
          // Check regions before the current region
          for (let i = dragHandleInfo.index - 1; i >= 0; i--) {
            const region = updatedRegions[i];
            if (region.end >= mergeStart) {
              mergeStart = Math.min(mergeStart, region.start);
              mergeEnd = Math.max(mergeEnd, region.end);
              updatedRegions.splice(i, 1); // Remove the merged region
              dragHandleInfo.index -= 1; // Adjust the index after merging
            } else {
              break; // No more overlaps
            }
          }

          // Check regions after the current region
          for (
            let i = dragHandleInfo.index + 1;
            i < updatedRegions.length;
            i++
          ) {
            const region = updatedRegions[i];
            if (region.start <= mergeEnd) {
              mergeStart = Math.min(mergeStart, region.start);
              mergeEnd = Math.max(mergeEnd, region.end);
              updatedRegions.splice(i, 1); // Remove the merged region
              i--; // Adjust index after removal
            } else {
              break; // No more overlaps
            }
          }

          // Clamp the start and end positions to the timeline bounds
          mergeStart = Math.max(0, mergeStart); // Ensure start is not less than 0
          mergeEnd = Math.min(100, mergeEnd); // Ensure end is not more than 100

          // Replace the current region with a new one that covers the merged extent
          const newRegion = { start: mergeStart, end: mergeEnd };
          updatedRegions.splice(dragHandleInfo.index, 1); // Remove the old region
          updatedRegions.push(newRegion); // Add the new region

          return normalizeRegions(updatedRegions); // Return the updated regions for state
        });

        // Update the currentPlayPosition to the current cursor position or clamp it to the timeline bounds
        const rect = timelineRef.current?.getBoundingClientRect();
        if (rect) {
          let percentage = ((e.clientX - rect.left) / rect.width) * 100;

          // Clamp to bounds of the timeline
          if (percentage < 0) {
            percentage = 0; // Clamp to time 0
          } else if (percentage > 100) {
            percentage = 100; // Clamp to time MAX
          }

          setCurrentPlayPosition(percentage); // Update the play position to match the cursor
        }

        // Finalize the drag operation
        setIsDragging(false);
        setDragHandleInfo(null);
        pushToUndoStack(); // Save the state after dragging a handle

        // Remove global event listeners
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      } else if (isDragSelecting) {
        // Handle drag-and-select
        const rect = timelineRef.current?.getBoundingClientRect();
        if (rect && pendingRegions.length > 0) {
          let { start, end } = pendingRegions[pendingRegions.length - 1];

          // Lock the selection to the timeline bounds
          if (start < 0) start = 0;
          if (end > 100) end = 100;
          if (start > 100) start = 100;
          if (end < 0) end = 0;

          if (Math.abs(start - end) > 0) {
            setSelectedRegions([...selectedRegions, { start, end }]);
            pushToUndoStack(); // Save the state after dragging
          }
        }

        setPendingRegions([]); // Clear pending regions
        setDragStartPosition(null); // Reset drag start position
        setIsDragSelecting(false); // End drag-and-select

        // Clean up event listeners
        window.removeEventListener("mousemove", handleNativeMouseMove);
        window.removeEventListener("mouseup", handleNativeMouseUp);
      }
    },
    [
      isDragging,
      dragHandleInfo,
      isDragSelecting,
      timelineRef,
      pendingRegions,
      selectedRegions,
      handleMouseMove,
      handleNativeMouseMove,
      setSelectedRegions,
      setIsDragging,
      setDragHandleInfo,
      setPendingRegions,
      setDragStartPosition,
      setIsDragSelecting,
      pushToUndoStack,
      setCurrentPlayPosition,
    ],
  );

  useEffect(() => {
    // Trigger a re-render by updating a dummy state or forcing an update
    if (DEBUG) console.log("selectedRegions updated: ", selectedRegions);
  }, [selectedRegions]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey && e.key === "z") {
        e.preventDefault();
        if (e.shiftKey) {
          handleRedo();
        } else {
          handleUndo();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    undoStack,
    redoStack,
    selectedRegions,
    verticalBarPosition,
    handleRedo,
    handleUndo,
  ]);

  const updateVerticalBarPosition = useCallback(
    (newPosition: number | null) => {
      setVerticalBarPosition(newPosition);
    },
    [setVerticalBarPosition],
  );

  const handleGlobalMouseUp = (e: MouseEvent) => {
    // If the mouse was dragging and is now released, finalize the selection
    if (DEBUG) console.log("handleGlobalMouseUp");
    if (isDraggingRef.current) {
      if (DEBUG) console.log("END THE DRAG");
      handleDragEnd();
    }
  };

  const cancelDragAndSelect = useCallback(() => {
    isDraggingRef.current = false;
    setDragStartPosition(null);
    setPendingRegions([]); // Reset pending regions

    if (lastVerticalBarPosition !== null) {
      updateVerticalBarPosition(lastVerticalBarPosition); // Return to the last blue bar position
      setCurrentPlayPosition(lastVerticalBarPosition); // Update play position to the last valid cursor position
    } else {
      // Ensure the bar resets on the first interaction
      setVerticalBarPosition(currentPlayPosition); // Use current play position if no previous position
    }

    // Reset any drag-related or handle-dragging state
    setIsDragging(false);
    setDragHandleInfo(null);
  }, [
    lastVerticalBarPosition,
    currentPlayPosition,
    updateVerticalBarPosition,
    setDragStartPosition,
    setPendingRegions,
    setCurrentPlayPosition,
    setVerticalBarPosition,
    setIsDragging,
    setDragHandleInfo,
  ]);

  const handleNativeMouseUp = useCallback(
    (e: MouseEvent) => {
      handleMouseUp(e);
    },
    [handleMouseUp],
  );

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  useEffect(() => {
    if (isDragSelecting) {
      window.addEventListener("mousemove", handleNativeMouseMove);
      window.addEventListener("mouseup", handleNativeMouseUp);
    } else {
      window.removeEventListener("mousemove", handleNativeMouseMove);
      window.removeEventListener("mouseup", handleNativeMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleNativeMouseMove);
      window.removeEventListener("mouseup", handleNativeMouseUp);
    };
  }, [isDragSelecting, handleNativeMouseMove, handleNativeMouseUp]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (DEBUG) console.log("Key Down:", e.key);
      if (e.key === "Shift") {
        if (DEBUG) console.log("Shift key pressed");
        setIsShiftPressed(true);
        if (verticalBarPosition !== null) {
          setShiftAnchorPosition(verticalBarPosition);
        }
      }

      // Handle ESC key to cancel the current action
      if (e.key === "Escape") {
        cancelDragAndSelect();
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (DEBUG) console.log("Key Up:", e.key);
      if (e.key === "Shift") {
        if (DEBUG) console.log("Shift key released");
        setIsShiftPressed(false);

        // Finalize the pending region if it exists
        if (pendingRegion !== null) {
          updateRegions([...selectedRegions, pendingRegion]);
          setPendingRegion(null);
          pushToUndoStack(); // Save the state after a shift-click operation
        }

        if (shiftAnchorPosition !== verticalBarPosition) {
          setShiftAnchorPosition(null);
        } else {
          updateVerticalBarPosition(shiftAnchorPosition);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      if (DEBUG) console.log("Removing event listeners");
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    verticalBarPosition,
    shiftAnchorPosition,
    pendingRegion,
    cancelDragAndSelect,
    pushToUndoStack,
    selectedRegions,
    updateRegions,
    updateVerticalBarPosition,
  ]);

  // const formatTime = (seconds: number): string => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);
  //   const secs = seconds % 60;

  //   let timeString = `${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  //   if (hours > 0)
  //     timeString = `${hours.toString().padStart(2, "0")}:${timeString}`;
  //   return timeString;
  // };

  const getMajorTicks = (): number[] => {
    const tickCount = Math.min(totalTime / 60, 12); // Example: 12 major ticks or one per minute
    const tickInterval = totalTime / tickCount;
    return Array.from({ length: tickCount + 1 }, (_, i) =>
      Math.floor(i * tickInterval),
    );
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (displayOnly) {
      const rect = timelineRef.current?.getBoundingClientRect();
      if (rect) {
        const percentage = ((e.clientX - rect.left) / rect.width) * 100;
        const newTime = Math.floor((percentage / 100) * totalTime);
        setCurrentTime(newTime);
        onTimelineClick(percentage);
      }
      return;
    }

    // Ignore right-clicks
    if (e.button !== 0) return;

    const rect = timelineRef.current?.getBoundingClientRect();
    if (rect) {
      const percentage = ((e.clientX - rect.left) / rect.width) * 100;
      const newTime = Math.floor((percentage / 100) * totalTime);

      // Push initial state to undo stack on first interaction
      if (undoStack.length === 0) {
        pushToUndoStack(); // Save the initial empty state
      }

      setCurrentTime(newTime);
      setCurrentPlayPosition(percentage); // Update play position to match the click
      updateVerticalBarPosition(percentage); // Update the vertical bar position
      onTimelineClick(percentage);
    }

    // Reset dragging flag to ensure correct behavior on the next interaction
    isDraggingRef.current = false;
  };

  const handleContextMenu = (
    e: React.MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    e.preventDefault(); // Prevent the default browser context menu
    if (DEBUG) console.log("Right-click detected on region:", index); // Debugging log
    setContextMenuPosition({ x: e.clientX, y: e.clientY });
    setContextRegionIndex(index); // Track which region was right-clicked
  };

  const handleContextMenuClose = () => {
    setContextMenuPosition(null);
    setContextRegionIndex(null);
  };

  const handleDragStart = (e: React.MouseEvent<HTMLDivElement>) => {
    // Only initiate drag for left-clicks
    if (e.button !== 0) return;

    e.preventDefault(); // Prevent text selection during drag

    const rect = timelineRef.current?.getBoundingClientRect();
    if (rect) {
      const percentage = ((e.clientX - rect.left) / rect.width) * 100;
      setDragStartPosition(percentage);

      // Check if the user is dragging the iMovie-like handles
      if (dragHandleInfo !== null) {
        isDraggingRef.current = true; // Set dragging to true for handle dragging
        setIsDragSelecting(false); // Ensure we're not in drag-and-select mode
      } else {
        // Otherwise, start a drag-and-select operation
        setIsDragSelecting(true); // Start drag-and-select
        setLastVerticalBarPosition(verticalBarPosition);

        if (e.metaKey || e.ctrlKey || isShiftPressed) {
          // CMD/CTRL or Shift key is held
          const inSelectedRegion = selectedRegions.some(
            (region) =>
              percentage >= Math.min(region.start, region.end) &&
              percentage <= Math.max(region.start, region.end),
          );

          setPendingRegions([
            ...pendingRegions,
            {
              start: percentage,
              end: percentage,
              action: inSelectedRegion ? "subtract" : "add",
            },
          ]);
        } else {
          setPendingRegions([
            { start: percentage, end: percentage, action: "normal" },
          ]);
        }

        updateVerticalBarPosition(percentage); // Start moving the blue vertical bar
      }
    }
  };

  const handleDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragSelecting) return;

    const rect = timelineRef.current?.getBoundingClientRect();
    if (rect && dragStartPosition !== null) {
      const percentage = ((e.clientX - rect.left) / rect.width) * 100;
      const lastRegion = pendingRegions[pendingRegions.length - 1];

      if (lastRegion) {
        lastRegion.end = percentage;
        setPendingRegions([...pendingRegions]);
      }
    }
  };

  // Update the handleDragEnd function to respect the canceled state
  const handleDragEnd = () => {
    if (!isDraggingRef.current) return; // If dragging was canceled, do nothing

    isDraggingRef.current = false;

    const rect = timelineRef.current?.getBoundingClientRect();

    if (rect && pendingRegions.length > 0) {
      const lastPendingRegion = pendingRegions[pendingRegions.length - 1];
      let start = Math.min(lastPendingRegion.start, lastPendingRegion.end);
      let end = Math.max(lastPendingRegion.start, lastPendingRegion.end);

      // Lock the start or end positions to the timeline boundaries if dragged beyond
      if (start < 0) start = 0;
      if (end > 100) end = 100;

      // Ensure the region is locked within the timeline bounds
      if (end > 100) {
        end = 100;
      } else if (start < 0) {
        start = 0;
      }

      // Update the region with the locked boundaries
      if (Math.abs(start - end) > 0) {
        updateRegions([...selectedRegions, { start, end }]); // Add only if region has non-zero width
        pushToUndoStack(); // Save the state after dragging
      }
    }

    setPendingRegions([]); // Clear pending regions
    setDragStartPosition(null); // Reset drag start position

    // Ensure dragging is fully stopped
    setIsDragging(false);
    setDragHandleInfo(null);

    // Clean up event listeners
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleGlobalMouseUp);
  };

  const handleEventHover = (event: Event | null) => {
    setHoveredEvent(event);
  };

  const renderActiveInactiveBackground = () => {
    // if (isSailfishQAMember) console.log("inactivePeriods..", inactivePeriods);
    return inactivePeriods.map((region, index) => {
      const isInactive = inactivePeriods.some(
        (inactive) =>
          region.start >= inactive.start && region.end <= inactive.end,
      );

      return (
        <div
          key={index}
          className={`absolute h-full ${
            isInactive ? "bg-blue-50" : "bg-blue-300"
          }`}
          style={{
            left: `${region.start}%`,
            width: `${region.end - region.start}%`,
          }}
        />
      );
    });
  };

  return (
    <div
      className={`relative flex items-center shadow-md ${
        displayOnly ? "h-3" : "h-6"
      } rounded`}
    >
      <div
        ref={timelineRef}
        className={`relative w-full ${
          displayOnly ? "h-3" : "h-6"
        } bg-blue-300 rounded-full border border-blue-300`}
        onMouseDown={displayOnly ? undefined : handleDragStart}
        onMouseUp={displayOnly ? undefined : handleDragEnd}
        onClick={handleClick}
        onContextMenu={displayOnly ? undefined : (e) => e.preventDefault()} // Prevent default context menu if not display-only
        onMouseMove={displayOnly ? undefined : handleDrag}
      >
        {/* Render Active/Inactive Background */}
        {renderActiveInactiveBackground()}

        {/* Play position background */}
        <div
          className='absolute h-full rounded-full bg-blue-600 border border-blue-600'
          style={{
            width: `${
              verticalBarPosition !== null
                ? verticalBarPosition
                : currentPlayPosition
            }%`,
          }}
        />
        {/* Major Ticks */}
        {!displayOnly &&
          getMajorTicks().map((tick: number, index: number) => (
            <React.Fragment key={index}>
              <div
                className='absolute h-full border-l border-gray-400'
                style={{
                  left: `${(tick / totalTime) * 100}%`,
                  width: "1px",
                }}
              />
              {/* <div
                className='absolute text-xs text-gray-600'
                style={{
                  left: `${(tick / totalTime) * 100}%`,
                  bottom: "-15px",
                  transform: "translateX(-50%)",
                }}
              >
                {formatTime(tick)}
              </div> */}
            </React.Fragment>
          ))}
        {/* Selected regions */}
        {processSelectedRegions([...selectedRegions, ...pendingRegions]).map(
          (region, index) => {
            const isHovered = hoveredRegion === index;

            return (
              <div
                key={index}
                className='absolute top-0 h-full'
                style={{
                  left: `${Math.min(region.start, region.end)}%`,
                  width: `${Math.abs(region.end - region.start)}%`,
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  handleContextMenu(e, index);
                }} // Ensure this event is properly attached
                onMouseEnter={() => setHoveredRegion(index)}
                onMouseLeave={(e) => {
                  const relatedTarget = e.relatedTarget as HTMLElement | null;
                  if (
                    !relatedTarget ||
                    !(relatedTarget instanceof HTMLElement) ||
                    (!relatedTarget.closest(".interactive-region") &&
                      !relatedTarget.closest(".drag-handle"))
                  ) {
                    setHoveredRegion(null);
                    setHoveredSide(null);
                  }
                }}
              >
                <div
                  className='relative h-full bg-red-400 opacity-50 interactive-region'
                  style={{
                    zIndex: 1, // Ensure this is below the handles
                  }}
                >
                  {isHovered && (
                    <>
                      {/* Left Drag Handle */}
                      <div
                        className='absolute left-0 top-0 h-full -translate-x-full bg-blue-800 flex items-center justify-center drag-handle cursor-w-resize w-8'
                        style={{
                          zIndex: 2, // Ensure this is above the highlighted region
                          // marginLeft: "-12px", // Increase accessibility to the handle
                        }}
                        onMouseDown={(e) =>
                          handleDragHandleStart(e, index, "left")
                        }
                      >
                        <GoTriangleLeft size={16} color='white' />
                      </div>

                      {/* Right Drag Handle */}
                      <div
                        className='absolute right-0 top-0 h-full translate-x-full bg-blue-800 flex items-center justify-center drag-handle cursor-e-resize w-8'
                        style={{
                          zIndex: 2, // Ensure this is above the highlighted region
                          // marginRight: "-12px", // Increase accessibility to the handle
                        }}
                        onMouseDown={(e) =>
                          handleDragHandleStart(e, index, "right")
                        }
                      >
                        <GoTriangleRight size={16} color='white' />
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          },
        )}
        {/* Pending Region with Brighter Blue Border */}
        {pendingRegion !== null && (
          <div
            className='absolute bg-blue-300 opacity-50 border-2 border-blue-500 rounded-full'
            style={{
              left: `${Math.min(pendingRegion.start, pendingRegion.end)}%`,
              width: `${Math.abs(pendingRegion.end - pendingRegion.start)}%`,
              top: 0, // Ensures the box covers the full height
              height: "100%",
            }}
          />
        )}
        {/* Purple Anchor Bar */}
        {shiftAnchorPosition !== null && isShiftPressed && (
          <div
            className='absolute h-full border-blue-800 bg-white border-2 w-3 rounded-full'
            style={{
              left: `${shiftAnchorPosition}%`,
              transform: "translateX(-40%)", // Correctly center the purple line
            }}
          />
        )}
        {/* Blue Vertical bar (Current Cursor Position) */}
        {/* {verticalBarPosition !== null && (
          <div
            className='absolute h-full bg-blue-500 rounded-full'
            style={{
              left: `${verticalBarPosition}%`,
              width: "2px",
            }}
          />
        )} */}
        {/* Event icons */}
        {events.map((event, index) => (
          <div
            key={index}
            className='absolute flex items-center justify-center top-1 -translate-x-1/2 -translate-y-1/2'
            style={{
              left: `${(event.time / totalTime) * 100}%`,
            }}
            onMouseEnter={() => handleEventHover(event)} // Show tooltip on hover
            onMouseLeave={() => handleEventHover(null)} // Hide tooltip when not hovering
          >
            <div
              className='rounded-full'
              style={{ backgroundColor: event.color }}
            >
              {event.icon}
            </div>
          </div>
        ))}
        {hoveredEvent && (
          <div
            ref={(el) => {
              if (el) {
                const parentWidth =
                  el.parentElement?.getBoundingClientRect().width || 0;
                const tooltipWidth = el.getBoundingClientRect().width;
                const leftPosition =
                  (hoveredEvent.time / totalTime) * parentWidth;

                let newTransform = "translateX(-50%)"; // Default is centered
                if (leftPosition + tooltipWidth / 2 > parentWidth) {
                  newTransform = "translateX(-100%)"; // Shift fully left if overflow to the right
                } else if (leftPosition - tooltipWidth / 2 < 0) {
                  newTransform = "translateX(0%)"; // Shift fully right if overflow to the left
                }

                el.style.transform = newTransform;
              }
            }}
            className='absolute p-2 bg-black text-white rounded-md z-20'
            style={{
              left: `${(hoveredEvent.time / totalTime) * 100}%`,
              top: "-50px", // Adjust as needed for correct positioning above the icon
              whiteSpace: "nowrap",
            }}
          >
            {hoveredEvent.text}
          </div>
        )}
      </div>

      {/* Context Menu */}
      {!displayOnly && contextMenuPosition && (
        <ContextAltClickMenu
          position={contextMenuPosition}
          menuItems={contextMenuItems}
          onClose={handleContextMenuClose}
        />
      )}
    </div>
  );
};

export default TimelineForRrwebPlayer;
