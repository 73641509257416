import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ExceptionRecordType } from "../../__generated__/graphql";

interface ExceptionsListProps {
  exceptions: Array<ExceptionRecordType>;
  onSelect: (exception: ExceptionRecordType) => void;
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  loading: boolean;
  exceptionsPerPage: number;
  setExceptionsPerPage: (exceptionsPerPage: number) => void;
}

const ExceptionsList: React.FC<ExceptionsListProps> = ({
  exceptions,
  onSelect,
  page,
  totalPages,
  onPageChange,
  loading,
  exceptionsPerPage,
  setExceptionsPerPage,
}) => {
  const [inputPage, setInputPage] = useState<string>(page.toString());
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const pageEntryWidth = 20 + 10 * totalPages.toString().length;

  useEffect(() => {
    if (warningMessage) {
      const timer = setTimeout(() => {
        setWarningMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [warningMessage]);

  const handlePageInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInputPage(event.target.value);
  };

  const handlePageInputSubmit = () => {
    let newPage = parseInt(inputPage, 10);
    if (isNaN(newPage) || newPage <= 0) {
      newPage = 1;
      setWarningMessage("Entered page is less than 1. Setting to page 1.");
    } else if (newPage > totalPages) {
      newPage = totalPages;
      setWarningMessage(
        `Entered page is greater than the total pages. Setting to page ${totalPages}.`,
      );
    }
    setInputPage(newPage.toString());
    onPageChange(newPage);
  };

  useEffect(() => {
    setInputPage(page.toString());
  }, [page]);

  const handlePageInputKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      handlePageInputSubmit();
    }
  };

  const handleExceptionSelect = (exception: ExceptionRecordType) => {
    onSelect(exception);
    searchParams.set("exceptionId", exception.id); // Update the URL with the selected exception ID
    setSearchParams(searchParams);
  };

  return (
    <Box display='flex' flexDirection='column' height='100%'>
      <Box flex='1' overflow='auto'>
        {loading ? (
          <div>Loading exceptions...</div>
        ) : (
          <List>
            {exceptions.map((exception) => (
              <ListItem
                button
                key={exception.id}
                onClick={() => handleExceptionSelect(exception)}
              >
                <ListItemText
                  primary={`Exception ${exception.id}`}
                  secondary={`${new Date(
                    parseInt(exception.timestampMs),
                  ).toLocaleString()}`}
                  // secondary={`Occurred at: ${new Date(
                  //   parseInt(exception.timestampMs),
                  // ).toLocaleString()}`}
                />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
      <Box bgcolor='white'>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          p={2}
          bgcolor='white'
          flexGrow={1}
        >
          <Button disabled={page <= 1} onClick={() => onPageChange(page - 1)}>
            Previous
          </Button>
          <Box display='flex' alignItems='center' mx={2}>
            <span>Page </span>
            <Tooltip
              title={warningMessage}
              open={!!warningMessage}
              placement='top'
            >
              <TextField
                value={inputPage}
                onChange={handlePageInputChange}
                onBlur={handlePageInputSubmit}
                onKeyDown={handlePageInputKeyDown}
                size='small'
                inputProps={{
                  style: {
                    textAlign: "center",
                    padding: "0",
                    height: "30px",
                    width: `${pageEntryWidth}px`,
                  },
                }}
                style={{
                  verticalAlign: "middle",
                  margin: "0 8px",
                  height: "30px",
                  width: `${pageEntryWidth}px`,
                }}
              />
            </Tooltip>
            <span> of {totalPages}</span>
          </Box>
          <Select
            value={exceptionsPerPage}
            onChange={(event) => {
              setExceptionsPerPage(event.target.value as number);
            }}
            size='small'
            style={{ marginLeft: "16px" }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
          <Button
            disabled={page >= totalPages}
            onClick={() => onPageChange(page + 1)}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ExceptionsList;
