// parseTextWithBackticks.tsx

const parseTextWithBackticks = (text: string): JSX.Element => {
  const parts = text.split(/(`[^`]*`)/).filter(Boolean);
  return (
    <>
      {parts.map((part, index) =>
        part.startsWith("`") && part.endsWith("`") ? (
          <span
            key={index}
            style={{
              fontWeight: "bold",
              backgroundColor: "#f0f0f0",
              padding: "2px 5px",
              borderRadius: "5px",
            }}
          >
            {part.substring(1, part.length - 1)}
          </span>
        ) : (
          part
        ),
      )}
    </>
  );
};

export default parseTextWithBackticks;
