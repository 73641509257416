import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { FaBug, FaCalendarAlt, FaInfinity } from "react-icons/fa";
import { HiMiniShieldCheck, HiOutlineShieldExclamation } from "react-icons/hi2";
import { MdNotifications, MdNotificationsOff } from "react-icons/md";
import { RiShipFill } from "react-icons/ri";
import { TbZzz, TbZzzOff } from "react-icons/tb";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  ExceptionRecordType,
  SnoozeIssueInput,
  useGetIssueLazyQuery,
  useSnoozeIssueMutation,
  useToggleIssueFollowingStatusForUserMutation,
  useToggleIssueResolvedStatusMutation,
} from "../__generated__/graphql";
import client from "../apolloClient";
import CtaButton from "../components/Buttons/CtaButton";
import HoverMenuButton from "../components/Buttons/HoverMenuButton";
import DashboardTile from "../components/DashboardTile";
import ExceptionView from "../components/Exceptions/ExceptionView"; // Imported ExceptionView
import ExpandableAndSelectableLists from "../components/ExpandableAndSelectableLists";
import ApplicationPageWithCta from "../components/Layout/ApplicationPageWithCta";
import OccurrenceDetails from "../components/Layout/OccurrenceDetails";
import ExceptionsList from "../components/ListViews/ExceptionsList";
import LoadingAnimation from "../components/LoadingAnimation";
import CtaModal from "../components/Modal/CtaModal";
import RecordingsList from "../components/RecordingsList";
import RRWebPlayer from "../components/RRWebPlayer";
import { useAuth } from "../contexts/AuthContext";
import { useCompany } from "../contexts/CompanyContext";
import { GET_LOGS_FOR_RECORDING } from "../graphql/logs";
import { GET_RECORDING_SESSION_UNZIPPED_EVENTS } from "../graphql/recording";

client.clearStore(); // Clears cached data

const DEBUG = import.meta.env.VITE_DEBUG ? import.meta.env.VITE_DEBUG : false;

const ResponsiveGridLayout = WidthProvider(Responsive);

interface Recording {
  id: string;
  formattedStartTimestamp: string;
  durationMs: number;
  numberOfEventsSeen: number;
}

interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: React.ComponentType;
}

interface HighlightedSection {
  startTime: number;
  endTime: number;
  text: string;
  color: string;
}

interface Tile {
  id: string;
  title: string;
  content: React.ReactNode;
  layout: Layout;
}

const sanitizeEventAttributes = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(sanitizeEventAttributes);
  } else if (obj && typeof obj === "object") {
    const sanitizedObj = Object.keys(obj).reduce((acc, key) => {
      const sanitizedKey = key.replace("@", ""); // Remove '@' from key names
      acc[sanitizedKey] = sanitizeEventAttributes(obj[key]);
      return acc;
    }, {} as any);

    // Ensure required properties are present and valid
    if (sanitizedObj.type === 4 && !sanitizedObj.data) {
      sanitizedObj.data = {}; // Ensure data property is present for type 4 events
    }

    return sanitizedObj;
  }
  return obj;
};

const DEFAULT_RECORDINGS_PER_PAGE = 25;

const IssuePage: React.FC = () => {
  // Refs
  const rrwebPlayerRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Router hooks
  const { issueId } = useParams<{ issueId: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();

  // State hooks
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [issueTitle, setIssueTitle] = useState<string>("");
  const [issueDescription, setIssueDescription] = useState<string>("");
  const [recordings, setRecordings] = useState<any[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [selectedRecording, setSelectedRecording] = useState<any>(null);
  const [playerDimensions, setPlayerDimensions] = useState({
    width: 640,
    height: 480,
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [unzippedEvents, setUnzippedEvents] = useState<any>(null);
  const [recordingsPerPage, setRecordingsPerPage] = useState(
    DEFAULT_RECORDINGS_PER_PAGE,
  );
  const [highlightedSections, setHighlightedSections] = useState<any[]>([]);
  const [eventsError, setEventsError] = useState(false);
  const [logs, setLogs] = useState<any[]>([]); // State for logs
  const [otherSimilarRecordingsPage, setOtherSimilarRecordingsPage] =
    useState(1);
  const [networkRequestPage, setNetworkRequestPage] = useState(1);
  const [logsPage, setLogsPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  const [searchParams] = useSearchParams();
  const exceptionIdParam = searchParams.get("exceptionId"); // Get the exceptionId from the URL
  const [selectedException, setSelectedException] =
    useState<ExceptionRecordType | null>(null);
  const [page, setPage] = useState(1);
  const [exceptionsPerPage, setExceptionsPerPage] = useState(10);
  const [exceptionsPage, setExceptionsPage] = useState(1); // Exception page state

  // Context hooks
  const { selectedCompany } = useCompany();
  const { user, isSailfishQAMember } = useAuth();

  // Query parameters
  const queryParams = new URLSearchParams(search);
  const recordingIdParam = queryParams.get("recordingId");

  // Apollo queries and mutations
  const [refetch, { data, loading, error }] = useGetIssueLazyQuery({
    variables: {
      issueId: issueId ? parseInt(issueId, 10) : 0,
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
    client,
  });

  // Exceptions Data
  const handleExceptionSelect = (exception: ExceptionRecordType) => {
    setSelectedException(exception);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (selectedCompany || (user && !isSailfishQAMember)) {
      refetch(); // Execute query when selectedCompany changes
    }
  }, [user, isSailfishQAMember, selectedCompany]);

  useEffect(() => {
    if (
      exceptionIdParam &&
      data &&
      data!.getIssue &&
      data!.getIssue?.exceptions &&
      data!.getIssue?.exceptions.length > 0
    ) {
      const exceptionToScrollTo = data!.getIssue?.exceptions.find(
        (exception) => exception.id === exceptionIdParam,
      );
      if (exceptionToScrollTo) {
        setSelectedException(exceptionToScrollTo); // Scroll to the exception with the ID from the URL
      }
    }
  }, [exceptionIdParam, data]);

  const [fetchUnzippedEvents, { loading: loadingEvents }] = useLazyQuery(
    GET_RECORDING_SESSION_UNZIPPED_EVENTS,
    {
      onCompleted: (data) => {
        if (data && data.recordingSession) {
          const sanitizedEvents = sanitizeEventAttributes(
            data.recordingSession.unzippedEvents,
          );

          if (sanitizedEvents.length === 0) {
            console.error(`Error: No events found for the given session.`);
            setEventsError(true);
            return;
          }

          if (sanitizedEvents.length < 2) {
            console.error(
              `Error: Insufficient events found for the given session.`,
            );
            setEventsError(true);
            return;
          }

          const firstEvent = sanitizedEvents.find(
            (event: any) => event.type === 4,
          );

          if (firstEvent) {
            const { width, height } = firstEvent.data;
            setPlayerDimensions({ width, height });
          } else {
            setPlayerDimensions({ width: 640, height: 480 });
          }

          setUnzippedEvents(sanitizedEvents);
          setEventsError(false);
        }
      },
      onError: (error) => {
        setEventsError(true);
        console.error("Error fetching unzipped events:", error);
      },
    },
  );

  const [fetchLogs, { loading: loadingLogs, error: logsError }] = useLazyQuery(
    GET_LOGS_FOR_RECORDING,
    {
      client,
      onCompleted: (data) => {
        if (DEBUG)
          console.log("data.getLogsForRecording", data.getLogsForRecording);
        setLogs(data.getLogsForRecording);
      },
    },
  );

  const handlePageChangeExceptions = (newPage: number) => {
    setExceptionsPage(newPage);
  };

  // Calculated values
  const calculateTotalPages = (
    totalItems: number,
    itemsPerPage: number,
  ): number => {
    return Math.ceil(totalItems / itemsPerPage);
  };

  const totalPages = calculateTotalPages(totalItemsCount, recordingsPerPage);
  const totalExceptionPages =
    data &&
    data!.getIssue &&
    data!.getIssue?.exceptions &&
    data!.getIssue?.exceptions
      ? calculateTotalPages(
          data!.getIssue?.exceptions.length,
          exceptionsPerPage,
        )
      : 0;

  // Effects
  useEffect(() => {
    if (data && data.getIssue) {
      setIssueTitle(data.getIssue.title!);
      setIssueDescription(data.getIssue.description || "");

      const allRecordings = data.getIssue.triages.flatMap((triage: any) => [
        ...triage.triageRecordingData.map((d: any) => d.recording),
        ...triage.triageLogData.map((d: any) => d.recording),
      ]);

      setRecordings(allRecordings);
      setTotalItemsCount(allRecordings.length);

      const primaryTriage =
        data.getIssue.triages.length == 0 ? null : data.getIssue.triages[0];
      const primaryRecording =
        primaryTriage === null
          ? null
          : primaryTriage.triageRecordingData[0]?.recording || null;

      if (primaryTriage === null) return;
      if (primaryRecording === null) return;

      if (recordingIdParam) {
        const recordingExists = allRecordings.some(
          (recording: { id: string }) => recording.id === recordingIdParam,
        );

        if (recordingExists) {
          setSelectedRecording(
            allRecordings.find(
              (recording: { id: string }) => recording.id === recordingIdParam,
            ),
          );
        } else {
          queryParams.delete("recordingId");
          navigate({ search: queryParams.toString() });
          setErrorMessage(
            "The selected recording is not in the set of recordings for the given issue.",
          );
        }
      } else {
        setSelectedRecording(primaryRecording);
        const sections: HighlightedSection[] =
          primaryTriage.triageRecordingData[0]?.timeWindows.flatMap(
            (timeWindow: any) => ({
              startTime: timeWindow.timestampStart,
              endTime: timeWindow.timestampEnd,
              text: 'Marked as "Error" Segment',
              color: "#fcd34d",
            }),
          );

        setHighlightedSections(sections);
      }
    }
  }, [data, recordingIdParam, navigate]);

  useEffect(() => {
    if (selectedRecording && selectedCompany) {
      fetchUnzippedEvents({
        variables: {
          id: selectedRecording.id,
          companyId: parseInt(selectedCompany.value, 10),
        },
      });
      fetchLogs({
        variables: {
          sessionId: selectedRecording.id,
          companyId: parseInt(selectedCompany.value, 10),
        },
      });
    }
  }, [selectedRecording, selectedCompany, fetchUnzippedEvents, fetchLogs]);

  useEffect(() => {
    if ((selectedRecording || issueId) && unzippedEvents) {
      const handleResize = () => {
        if (containerRef.current) {
          const contentHolder = containerRef.current.querySelector(
            "#video-player-or-exceptions #content-holder",
          ) as HTMLElement;

          if (contentHolder) {
            const padding = parseInt(
              window.getComputedStyle(contentHolder).padding,
              10,
            );
            const playbackBar = contentHolder.querySelector(
              "#rrweb-playback-bar",
            ) as HTMLElement;
            const playbackBarHeight = playbackBar
              ? playbackBar.offsetHeight +
                parseInt(window.getComputedStyle(playbackBar).marginTop, 10) +
                parseInt(window.getComputedStyle(playbackBar).marginBottom, 10)
              : 0;

            const contentWidth = contentHolder.offsetWidth - padding * 2;
            const contentHeight =
              contentHolder.offsetHeight - padding * 2 - playbackBarHeight;

            let width = contentWidth;
            let height = contentHeight;

            const aspectRatio =
              playerDimensions.width / playerDimensions.height;

            if (contentWidth / contentHeight > aspectRatio) {
              width = contentHeight * aspectRatio;
            } else {
              height = contentWidth / aspectRatio;
            }

            setPlayerDimensions({ width, height });
          } else {
            console.warn("content-holder not found");
          }
        }
      };

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [
    selectedRecording,
    unzippedEvents,
    issueId,
    playerDimensions.width,
    playerDimensions.height,
  ]);

  // Event handlers
  const handleSelectRecording = (recording: Recording) => {
    if (selectedRecording?.id === recording.id) {
      rrwebPlayerRef.current?.resetPlayer();
    } else {
      setUnzippedEvents(null);
      setSelectedRecording(recording);
      navigate(`/recordings/${recording.id}`);
    }
    rrwebPlayerRef.current?.pause();
  };

  const handlePageChangeLogs = (newPage: number) => {
    setLogsPage(newPage);
  };

  const handlePageChangeNetworkRequests = (newPage: number) => {
    setNetworkRequestPage(newPage);
  };

  const handlePageChangeOtherSimilarRecordings = (newPage: number) => {
    setOtherSimilarRecordingsPage(newPage);
  };

  const handleTileResize = (layout: any, oldItem: any, newItem: any) => {
    if (containerRef.current) {
      const tileElement = containerRef.current.querySelector(
        `#${newItem.i}`,
      ) as HTMLElement;
      if (tileElement) {
        const contentHolder = tileElement.querySelector(
          "#content-holder",
        ) as HTMLElement;
        if (contentHolder) {
          const padding = parseInt(
            window.getComputedStyle(contentHolder).padding,
            10,
          );
          const playbackBar = tileElement.querySelector(
            "#rrweb-playback-bar",
          ) as HTMLElement;
          const playbackBarHeight = playbackBar
            ? playbackBar.offsetHeight +
              parseInt(window.getComputedStyle(playbackBar).marginTop, 10) +
              parseInt(window.getComputedStyle(playbackBar).marginBottom, 10)
            : 0;

          const contentWidth = contentHolder.offsetWidth - padding * 2;
          const contentHeight =
            contentHolder.offsetHeight - padding * 2 - playbackBarHeight;

          let width = contentWidth;
          let height = contentHeight;

          const aspectRatio = playerDimensions.width / playerDimensions.height;

          if (contentWidth / contentHeight > aspectRatio) {
            width = contentHeight * aspectRatio;
          } else {
            height = contentWidth / aspectRatio;
          }

          setPlayerDimensions({ width, height });
        } else {
          console.warn("content-holder not found");
        }
      } else {
        console.warn("tileElement not found");
      }
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const isUserFollowingIssue = useMemo(() => {
    if (!user || !data || !data.getIssue || !data.getIssue.followers) {
      return false;
    }
    return data.getIssue.followers.some(
      (follower) => follower.email === user.email,
    );
  }, [user, data]);

  const isIssueResolved = useMemo(() => {
    if (!user || !data || !data.getIssue || !data.getIssue.isResolved) {
      return false;
    }
    return data.getIssue.isResolved;
  }, [user, data]);

  const [
    toggleFollowIssueMutation,
    { loading: loadingFollowToggle, error: errorFollowToggle },
  ] = useToggleIssueFollowingStatusForUserMutation({ client });

  const [
    toggleResolvedStatusMutation,
    { loading: loadingResolvedStatusToggle, error: errorResolvedStatusToggle },
  ] = useToggleIssueResolvedStatusMutation({ client });

  const [
    updateIssueSnoozeStatus,
    { loading: loadingSnoozeIssue, error: errorSnoozeIssue },
  ] = useSnoozeIssueMutation({ client });

  const handleFollowToggle = async () => {
    if (!data?.getIssue?.id) return;
    await toggleFollowIssueMutation({
      variables: {
        input: {
          issueId: Number(data.getIssue.id),
          wantsToFollow: !isUserFollowingIssue,
        },
      },
    });
    // Refetch the issue data to update the UI
    refetch();
  };

  const handleResolvedToggle = async () => {
    if (!data?.getIssue?.id) return;
    await toggleResolvedStatusMutation({
      variables: {
        input: {
          issueId: Number(data.getIssue.id),
          setResolvedStateTo: !isIssueResolved,
        },
      },
    });
    // Refetch the issue data to update the UI
    refetch();
  };

  const handleUpdateSnoozeMode = async (
    snoozeMode: "NewRelease" | "Indefinite" | "Until" | "Disable",
    snoozedUntil?: string, // ISO string for 'Until' mode
  ) => {
    const issueId = data?.getIssue?.id;
    if (!issueId) return;

    console.log(`Snooze mode: ${snoozeMode}`);

    const input: SnoozeIssueInput = {
      issueId: Number(issueId),
      snoozedMode: snoozeMode,
      snoozedPermanently: snoozeMode === "Indefinite",
      ...(snoozeMode === "Until" && snoozedUntil ? { snoozedUntil } : {}),
    };

    await updateIssueSnoozeStatus({ variables: { input } });

    // Refetch the issue data to update the UI
    refetch();
  };

  // Early returns after all hooks
  if (loading || (!data && !error))
    return <LoadingAnimation text="Loading issue details..." />;
  if (error) return <div>Error loading issue details</div>;

  // Variables dependent on data
  const pageName = `Issues > #${issueId}: ${issueTitle}`;

  const timelineEvents: TimelineEvent[] = [];

  // Templates for ExpandableAndSelectableLists
  const headerTemplate = (log: any) => {
    const logLevelLowercase = log.level.toLowerCase();
    const logDisplayString =
      logLevelLowercase === "log"
        ? "Info"
        : log.level.charAt(0).toUpperCase() + log.level.slice(1);

    return (
      <div className='flex flex-col'>
        <div className='flex items-start'>
          <span
            className={`min-w-[70px] ${
              logLevelLowercase === "error"
                ? "text-red-500"
                : logLevelLowercase === "warning"
                ? "text-yellow-500"
                : "text-slate-500"
            } text-lg font-bold`}
          >
            {logDisplayString}
          </span>
          <div className='ml-2 flex flex-col'>
            <span className='text-slate-800 text-lg font-bold'>
              {log.payload}
            </span>
            <span className='text-slate-500 text-base'>
              {log.formattedTimestamp}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const gutterTemplate = (log: any) => (
    <div>
      <div className='flex flex-col text-base text-slate-700'>
        <strong className=''>Trace:</strong>
        {log.trace.map((traceLine: string, index: number) => (
          <div key={index} className=' pl-4'>
            {traceLine}
          </div>
        ))}
      </div>
      <div>
        <strong className=''>Timestamp:</strong> {log.timestamp}
      </div>
    </div>
  );

  const logsContent = (
    <div className='flex-grow overflow-auto h-full'>
      <ExpandableAndSelectableLists
        items={logs}
        loading={loadingLogs}
        error={logsError}
        headerTemplate={headerTemplate}
        gutterTemplate={gutterTemplate}
        showTimelineBarForRows={false}
      />
    </div>
  );

  const occurrenceContent = (
    <OccurrenceDetails
      metadataItems={[
        {
          title: "Severity",
          content: <span>--</span>,
        },
        { title: "% Affected", content: <span>--</span> },
        {
          title: "Change/24h",
          content: <span>--</span>,
        },
        { title: "Last Seen", content: <span>--</span> },
        {
          title: "Devices",
          content: <span>--</span>,
        },
      ]}
    />
  );

  const tiles: (Tile | null)[] = [
    {
      id: "video-player-or-exceptions",
      title: recordings.length > 0 ? "Video Player" : "Exception",
      content:
        recordings.length > 0 ? ( // Only display RRWebPlayer if there are recordings
          loadingEvents ? (
            <LoadingAnimation text={"Loading..."} />
          ) : unzippedEvents && !eventsError && selectedRecording ? (
            <div className='h-full'>
              <RRWebPlayer
                ref={rrwebPlayerRef}
                events={unzippedEvents}
                timelineEvents={timelineEvents}
                highlightedSections={highlightedSections}
                width={playerDimensions.width}
                height={playerDimensions.height}
              />
            </div>
          ) : (
            <div className='h-full flex items-center justify-center'>
              {eventsError
                ? "Select a different recording; current recording is unfetchable"
                : "Select a Recording"}
            </div>
          )
        ) : (
          data &&
          data!.getIssue &&
          data!.getIssue?.exceptions.length > 0 && (
            // Display ExceptionView if there are no recordings
            <ExceptionView {...data!.getIssue?.exceptions[0]} />
          )
        ),
      layout: {
        i: "video-player-or-exceptions",
        x: 0,
        y: recordings.length > 0 ? 0 : 5,
        w: 3,
        h: 5,
      },
    },
    {
      id: "other-occurrences",
      title: "Other Occurrences",
      content:
        recordings.length > 0 ? (
          <RecordingsList
            recordings={recordings}
            onSelect={handleSelectRecording}
            page={otherSimilarRecordingsPage}
            totalPages={totalPages}
            onPageChange={handlePageChangeOtherSimilarRecordings}
            loading={loading}
            recordingsPerPage={recordingsPerPage}
            setRecordingsPerPage={setRecordingsPerPage}
          />
        ) : data!.getIssue?.exceptions &&
          data!.getIssue?.exceptions.length > 0 ? (
          <ExceptionsList
            exceptions={data!.getIssue?.exceptions}
            onSelect={(exception) => {
              setSelectedException(exception);
              navigate({
                search: `?exceptionId=${exception.id}`,
              });
            }}
            page={exceptionsPage}
            totalPages={totalExceptionPages}
            onPageChange={handlePageChangeExceptions}
            exceptionsPerPage={exceptionsPerPage}
            setExceptionsPerPage={setExceptionsPerPage}
            loading={false}
          />
        ) : (
          <div>No exceptions found</div> // Fallback if there are no exceptions
        ),
      layout: { i: "other-occurrences", x: 3, y: 0, w: 1, h: 7 },
    },
    {
      id: "occurrence-details",
      title: "Occurrence Details",
      content: occurrenceContent,
      layout: {
        i: "occurrence-details",
        x: 0,
        y: recordings.length > 0 ? 5 : 0,
        w: 3,
        h: 2,
      },
    },
    {
      id: "logs",
      title: "Logs",
      content: logsContent,
      layout: { i: "logs", x: 0, y: 7, w: recordings.length > 0 ? 2 : 4, h: 3 },
    },
    recordings.length > 0
      ? {
          id: "exceptions",
          title: "Exceptions",
          content: (
            <ExpandableAndSelectableLists
              items={[]}
              headerTemplate={(log) => <div>{log.payload}</div>}
              gutterTemplate={(log) => <div>{log.timestamp}</div>}
            />
          ),
          layout: { i: "exceptions", x: 3, y: 7, w: 2, h: 3 },
        }
      : null,
  ];

  const filteredTiles: Tile[] = tiles.filter(
    (tile): tile is Tile => tile !== null,
  );

  const specificDateSubMenu = (
    <div className='m-6'>
      <div className='flex flex-col items-center'>
        <div className='flex items-start space-x-4'>
          {" "}
          {/* Flexbox for date and time pickers */}
          <DatePicker
            showTimeSelect
            dateFormat='MMMM d, yyyy h:mm aa'
            selected={selectedDate}
            onChange={(date: Date | null) => {
              setSelectedDate(date);
              // if (date) {
              //   handleUpdateSnoozeMode("Until", date.toISOString());
              // }
            }}
            timeIntervals={15}
            // timeCaption='Time'
            inline
            calendarClassName='react-datepicker-custom' // Adding a custom class for styling
          />
        </div>

        {/* Additional Snooze Button */}
        <button
          onClick={() => {
            if (selectedDate) {
              handleUpdateSnoozeMode("Until", selectedDate.toISOString());
            }
          }}
          className='mt-4 px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'
        >
          Snooze
        </button>
      </div>
    </div>
  );

  const snoozeIcon = (() => {
    switch (data?.getIssue?.snoozedMode) {
      case "NewRelease":
        return <RiShipFill />;
      case "Until":
        return <FaCalendarAlt />;
      case "Indefinite":
        return <FaInfinity />;
      case "Disabled":
      default:
        return <TbZzz />;
    }
  })();

  return (
    <ApplicationPageWithCta
      pageName={pageName}
      CtaButton={
        <CtaButton
          titlePrimary='Edit Issue'
          titleHoverPrimary='Edit'
          icon={<FaBug />}
          onClick={handleOpenModal}
        />
      }
    >
      <div className='flex justify-between items-center'>
        <span className='text-xl font-medium'>{issueDescription}</span>
        <div className='flex items-center space-x-4'>
          <div className='flex items-center justify-end space-x-4 mt-4'>
            <CtaButton
              titlePrimary='Not Following'
              titleHoverPrimary='Follow'
              titleSecondary='Following'
              titleHoverSecondary='Unfollow'
              bgPrimaryClass='bg-amber-100'
              bgSecondaryClass='bg-amber-300'
              bgPrimaryHoverClass='bg-amber-300'
              bgSecondaryHoverClass='bg-amber-100'
              textPrimaryClass='font-semibold text-slate-400'
              textSecondaryClass='font-bold text-slate-700'
              textPrimaryHoverClass='font-bold text-slate-700'
              textSecondaryHoverClass='font-semibold ext-slate-400'
              iconPrimary={<MdNotificationsOff />}
              iconPrimaryHover={<MdNotifications />}
              iconSecondary={<MdNotifications />}
              iconSecondaryHover={<MdNotificationsOff />}
              isPrimary={!isUserFollowingIssue}
              onClick={handleFollowToggle}
            />

            <HoverMenuButton
              titlePrimary='Snooze'
              titleHoverPrimary='Snooze Until'
              titleSecondary='Snoozed until'
              titleHoverSecondary='Modify Snooze'
              icon={snoozeIcon}
              items={[
                {
                  title: "Next release",
                  onClick: () => handleUpdateSnoozeMode("NewRelease"),
                  icon: <RiShipFill />,
                  selected: data?.getIssue?.snoozedMode === "NewRelease",
                  hoveredNotSelectedClassName:
                    "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                  hoveredSelectedClassName: "bg-blue-500 text-white font-bold",
                  notHoveredNotSelectedClassName: "bg-white text-slate-700",
                  notHoveredSelectedClassName:
                    "bg-blue-500 text-white font-bold",
                },
                {
                  title: "Specific Date/Time",
                  icon: <FaCalendarAlt />,
                  selected: data?.getIssue?.snoozedMode === "Until",
                  hoveredNotSelectedClassName:
                    "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                  hoveredSelectedClassName: "bg-sky-400 text-white font-bold",
                  notHoveredNotSelectedClassName: "bg-white text-slate-700",
                  notHoveredSelectedClassName:
                    "bg-blue-500 text-white font-bold",
                  subMenu: specificDateSubMenu,
                  subMenuPosition: "left",
                },
                {
                  title: "End of Time",
                  onClick: () => handleUpdateSnoozeMode("Indefinite"),
                  icon: <FaInfinity />,
                  selected: data?.getIssue?.snoozedMode === "Indefinite",
                  hoveredNotSelectedClassName:
                    "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                  hoveredSelectedClassName: "bg-blue-500 text-white font-bold",
                  notHoveredNotSelectedClassName: "bg-white text-slate-700",
                  notHoveredSelectedClassName:
                    "bg-blue-500 text-white font-bold",
                },
                ...(data?.getIssue?.snoozedMode !== "Disable"
                  ? [
                      {
                        title: "Unsnooze",
                        onClick: () => handleUpdateSnoozeMode("Disable"),
                        icon: <TbZzzOff />,
                        selected: data?.getIssue?.snoozedMode === "Disable",
                        hoveredNotSelectedClassName:
                          "hover:bg-sky-200 hover:text-slate-700 hover:font-semibold",
                        notHoveredNotSelectedClassName:
                          "bg-white text-slate-700",
                      },
                    ]
                  : []),
              ]}
              bgPrimaryClass='bg-gray-300'
              bgPrimaryHoverClass='bg-gray-500'
              bgSecondaryClass='bg-gray-500'
              bgSecondaryHoverClass='bg-gray-500'
              textPrimaryClass='font-semibold text-slate-700'
              textPrimaryHoverClass='text-white'
              textSecondaryClass='text-white'
              textSecondaryHoverClass='text-white'
              fontBold={true}
              hideDelay={500}
              isPrimary={data?.getIssue?.snoozedMode === "Disable"}
            />

            <CtaButton
              titlePrimary='Unresolved'
              titleHoverPrimary='Resolve'
              titleSecondary='Resolved'
              titleHoverSecondary='Unresolve'
              bgPrimaryClass='bg-green-100'
              bgSecondaryClass='bg-green-500'
              bgPrimaryHoverClass='bg-green-500'
              bgSecondaryHoverClass='bg-green-100'
              textPrimaryClass='font-semibold text-slate-800'
              textSecondaryClass='text-slate-50'
              textPrimaryHoverClass='font-bold text-slate-50'
              textSecondaryHoverClass='text-slate-500'
              outlinePrimaryClass='outline outline-2 outline-offset-0 outline-slate-400'
              outlineSecondaryClass='outline outline-2 outline-offset-0 outline-black'
              outlinePrimaryHoverClass='outline outline-2 outline-offset-0 outline-slate-800'
              outlineSecondaryHoverClass='outline outline-2 outline-offset-0 outline-black'
              showOutlinePrimary={true}
              showOutlineSecondary={true}
              showOutlineHoverPrimary={true}
              showOutlineHoverSecondary={true}
              iconPrimary={<HiOutlineShieldExclamation size={20} />}
              iconPrimaryHover={<HiMiniShieldCheck size={20} />}
              iconSecondary={<HiMiniShieldCheck size={20} />}
              iconSecondaryHover={<HiOutlineShieldExclamation size={20} />}
              isPrimary={!isIssueResolved}
              onClick={handleResolvedToggle}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CtaModal
          title='Edit Issue'
          closeModal={handleCloseModal}
          fields={[
            {
              label: "Issue Title",
              type: "input",
              defaultValue: issueTitle,
              placeholderText: "Enter a title for the issue",
              onChange: (
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setIssueTitle(e.target.value),
            },
            {
              label: "Description",
              type: "textarea",
              defaultValue: issueDescription,
              placeholderText: "Issue description...",
              onChange: (
                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setIssueDescription(e.target.value),
            },
          ]}
          buttons={[
            {
              button: (
                <CtaButton
                  titlePrimary='Cancel'
                  bgPrimaryClass='bg-gray-300'
                  textPrimaryClass='text-secondary'
                  bgPrimaryHoverClass='hover:bg-gray-500'
                  textPrimaryHoverClass='hover:text-primary-dark'
                  outlinePrimaryHoverClass='hover:outline hover:outline-2 hover:outline-offset-0 hover:outline-gray-800'
                  additionalClassNames='w-full'
                  onClick={handleCloseModal}
                />
              ),
              width: 50,
            },
            {
              button: (
                <CtaButton
                  titlePrimary='Save'
                  additionalClassNames='w-full font-semibold'
                  onClick={() => {
                    // Implement save functionality
                  }}
                />
              ),
              width: 50,
            },
          ]}
        />
      )}

      {errorMessage && <div className='error'>{errorMessage}</div>}

      <div ref={containerRef} className='relative'>
        <ResponsiveGridLayout
          className='layout'
          layouts={{ lg: filteredTiles.map((tile) => tile.layout) }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 4 }}
          rowHeight={100}
          width={1200}
          containerPadding={[15, 15]}
          draggableHandle='.drag-handle'
          onResizeStop={(layout, oldItem, newItem) =>
            handleTileResize(layout, oldItem, newItem)
          }
        >
          {filteredTiles.map((tile) => (
            <div key={tile.id} className='p-0 m-0'>
              <DashboardTile
                key={tile.id}
                id={tile.id}
                title={tile.title}
                onRemove={() => {}}
              >
                {tile.content}
              </DashboardTile>
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </ApplicationPageWithCta>
  );
};

export default IssuePage;
