import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import client from "../../apolloClient";
import { useCompany } from "../../contexts/CompanyContext";
import { useUnsavedChanges } from "../../contexts/UnsavedChangesContext";
import {
  GET_CAPTURE_SETTINGS,
  UPDATE_CAPTURE_SETTINGS,
} from "../../graphql/settingsQueries";
import ActionBanner from "../ActionBanner";
import Checkbox from "../Checkbox";
import LoadingAnimation from "../LoadingAnimation";
import Section from "./Section";

// Define the type of the settings state
interface Settings {
  recordCanvas: boolean;
  recordCrossOriginIframes: boolean;
  collectFonts: boolean;
  inlineImages: boolean;
  sampling: any; // Adjust the type as necessary
  canEdit?: boolean; // Make canEdit optional
}

const Recording = () => {
  const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
  const { data, loading, error, refetch } = useQuery(GET_CAPTURE_SETTINGS, {
    client,
    variables: {
      companyId: selectedCompany
        ? parseInt(selectedCompany.value, 10)
        : undefined,
    },
  });
  const [settings, setSettings] = useState<Settings>({
    recordCanvas: false,
    recordCrossOriginIframes: false,
    collectFonts: false,
    inlineImages: false,
    sampling: {},
    canEdit: false,
  });

  const originalSettingsRef = useRef<Settings>(settings);

  const {
    isDirty,
    setExternalSaveFunction,
    markSettingAsChanged,
    clearUnsavedChanges,
  } = useUnsavedChanges();

  useEffect(() => {
    if (data && data.captureSettings) {
      setSettings(data.captureSettings);
      originalSettingsRef.current = data.captureSettings;
    }
  }, [data]);

  // Refetch the query whenever the selectedCompany changes
  useEffect(() => {
    if (selectedCompany) {
      refetch({
        companyId: parseInt(selectedCompany.value, 10),
      });
    } else {
      refetch({
        companyId: undefined,
      });
    }
  }, [selectedCompany, refetch]);

  const handleChange = (field: keyof Settings) => (value: boolean) => {
    setSettings((prevSettings) => {
      const newSettings = { ...prevSettings, [field]: value };
      if (prevSettings[field] !== value) {
        markSettingAsChanged("recording");
      }
      return newSettings;
    });
  };

  const handleSaveChanges = useCallback(async () => {
    if (isDirty("recording")) {
      try {
        await client.mutate({
          mutation: UPDATE_CAPTURE_SETTINGS,
          variables: {
            input: {
              recordCanvas: settings.recordCanvas,
              recordCrossOriginIframes: settings.recordCrossOriginIframes,
              collectFonts: settings.collectFonts,
              inlineImages: settings.inlineImages,
              sampling: JSON.stringify(settings.sampling),
            },
          },
        });
        originalSettingsRef.current = settings;
        clearUnsavedChanges("recording");
      } catch (error) {
        console.error("Error updating capture settings:", error);
      }
    }
  }, [isDirty, settings, clearUnsavedChanges]);

  useEffect(() => {
    setExternalSaveFunction("recording", handleSaveChanges);
  }, [handleSaveChanges, setExternalSaveFunction]);

  const handleDiscardChanges = useCallback(() => {
    setSettings(originalSettingsRef.current);
    clearUnsavedChanges("recording");
  }, [clearUnsavedChanges]);

  const disabledTitle =
    "You need Admin or CompanyAdmin privileges to edit these settings.";

  return (
    <>
      {loading ? (
        <LoadingAnimation text={"Loading..."} />
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <>
          <Section title='General'>
            <div className='space-y-4'>
              <Checkbox
                label='Record Canvas'
                checked={settings.recordCanvas}
                onChange={handleChange("recordCanvas")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
              <Checkbox
                label='Record Cross-Origin IFrames'
                checked={settings.recordCrossOriginIframes}
                onChange={handleChange("recordCrossOriginIframes")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
              <Checkbox
                label='Collect Fonts'
                checked={settings.collectFonts}
                onChange={handleChange("collectFonts")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
              <Checkbox
                label='Inline Images'
                checked={settings.inlineImages}
                onChange={handleChange("inlineImages")}
                disabled={!settings.canEdit}
                tooltip={settings.canEdit ? "" : disabledTitle}
              />
            </div>
          </Section>
          <Section title='Privacy'>
            <p className='mb-2 italic'>
              The following data is anonymized unless you have checked the box
              to record the actual data. Some fields are unable to be selected,
              which are grayed-out.
            </p>
            <div className='space-y-4'>
              <Section
                title='Secret Data'
                headerClassName='bg-sky-400 text-white p-2 rounded-t'
              >
                <Checkbox
                  label="Record user's Password"
                  checked={false}
                  onChange={() => {
                    console.error(
                      "User tried to enable recording of passwords",
                    );
                  }}
                  disabled={true}
                  tooltip={"Users cannot enable the recording of passwords"}
                />
              </Section>
              <Section
                title='Personally Identifiable Information (PII) Data'
                headerClassName='bg-sky-400 text-white p-2 rounded-t'
              >
                <Checkbox
                  label="Record user's real name"
                  checked={true}
                  onChange={() => {}} // TODO - implement
                  disabled={!settings.canEdit}
                  tooltip={settings.canEdit ? "" : disabledTitle}
                />
                <Checkbox
                  label="Record user's credit card information"
                  checked={false}
                  onChange={() => {
                    console.error(
                      "User tried to enable recording of credit card information",
                    );
                  }}
                  disabled={true}
                  tooltip={
                    "Users cannot enable the recording of credit card information"
                  }
                />
                <Checkbox
                  label="Record user's Social Security Number (SSN)"
                  checked={false}
                  onChange={() => {
                    console.error("User tried to enable recording of SSN data");
                  }}
                  disabled={true}
                  tooltip={"Users cannot enable the recording of SSN data"}
                />
                <Checkbox
                  label="Record user's Date of Birth (DOB)"
                  checked={false}
                  onChange={() => {
                    console.error("User tried to enable recording of DOB data");
                  }}
                  disabled={true}
                  tooltip={"Users cannot enable the recording of DOB data"}
                />
              </Section>
              <Section
                title='Additional Elements'
                headerClassName='bg-sky-400 text-white p-2 rounded-t'
              >
                <p>
                  Sailfish can further anonymize data upon ingestion, giving
                  engineers more control over what we store.&nbsp; E.g. you may
                  want to not store data such as instant messages between
                  co-workers. In that case, do the following:
                </p>
                <br />
                <p style={{ textIndent: "2em" }}>
                  Add &nbsp;
                  <b>
                    <code>sailfishSanitize</code>
                  </b>
                  &nbsp; to the <code>className</code> of the element you want
                  to sanitize:&nbsp;&nbsp;
                  <code>
                    <b>&lt;... className='... sailfishSanitize' ...&gt;</b>
                  </code>
                </p>
              </Section>
            </div>
            {isDirty("recording") && (
              <ActionBanner
                onSave={() => handleSaveChanges()}
                onDiscard={() => handleDiscardChanges()}
                onUndo={() => {}}
                onRedo={() => {}}
                canUndo={false}
                canRedo={false}
              />
            )}
          </Section>
        </>
      )}
    </>
  );
};

export default Recording;
