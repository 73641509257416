import { Box } from "@mui/material";
import React, { ReactNode } from "react";

interface ApplicationPageProps {
  pageName: string;
  children: ReactNode;
}

const ApplicationPage: React.FC<ApplicationPageProps> = ({
  pageName,
  children,
}) => {
  return (
    <Box height={"100%"} width={"100%"}>
      <h1 id='applicationPageHeader' style={{ fontSize: 36 }}>
        {pageName}
      </h1>
      {children}
    </Box>
  );
};

export default ApplicationPage;
