import { gql } from "@apollo/client";

export const GET_RECORDING_SESSION_UNZIPPED_EVENTS = gql`
  query GetRecordingSessionUnzippedEvents($id: String!, $companyId: Int) {
    recordingSession(id: $id, companyId: $companyId) {
      unzippedEvents
      pageVisits {
        url
        activeFrom
        activeUntil
      }
      userInteractions {
        clicks {
          timestamp
          type
          element
          textContent # New field for concatenated text content from child nodes
          children
          details
        }
        pageNavigations {
          timestamp
          url
          details
        }
        textEdits {
          timestamp
          element
          attributes
          title # New field for the title attribute
          placeholder # New field for the placeholder attribute
          children
          details
        }
      }
      metadata {
        user
        device {
          isBot
          isTelevision
          isMobile
          isDesktop
          device {
            brand
            model
            type
          }
          client {
            name
            type
            version
          }
          os {
            name
            version
          }
        }
        language
        timeZone
        region
      }
    }
  }
`;

export const GET_RECORDING_SESSIONS = gql`
  query GetRecordingSessions($limit: Int!, $offset: Int!, $companyId: Int!) {
    recordingSessions(limit: $limit, offset: $offset, companyId: $companyId) {
      items {
        id
        formattedStartTimestamp
        durationMs
        numberOfEventsSeen
      }
      totalItemsCount
    }
  }
`;
