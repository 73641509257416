import React, { createContext, useCallback, useContext, useState } from "react";

type TabNames = "setup" | "recording" | "configuration" | "notifications";

interface UnsavedChangesContextProps {
  isDirty: (tab: TabNames) => boolean;
  markSettingAsChanged: (tab: TabNames) => void;
  clearUnsavedChanges: (tab: TabNames) => void;
  setExternalSaveFunction: (tab: TabNames, saveFn: () => Promise<void>) => void;
  externalSave: (tab: TabNames) => Promise<void>;
}

const UnsavedChangesContext = createContext<UnsavedChangesContextProps | null>(
  null,
);

export const UnsavedChangesProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [dirtyTabs, setDirtyTabs] = useState<Record<TabNames, boolean>>({
    setup: false,
    recording: false,
    configuration: false,
    notifications: false,
  });

  const [saveFunctions, setSaveFunctions] = useState<
    Record<TabNames, () => Promise<void>>
  >({
    setup: async () => {},
    recording: async () => {},
    configuration: async () => {},
    notifications: async () => {},
  });

  const isDirty = useCallback((tab: TabNames) => dirtyTabs[tab], [dirtyTabs]);

  const markSettingAsChanged = useCallback((tab: TabNames) => {
    setDirtyTabs((prev) => ({ ...prev, [tab]: true }));
  }, []);

  const clearUnsavedChanges = useCallback((tab: TabNames) => {
    setDirtyTabs((prev) => ({ ...prev, [tab]: false }));
  }, []);

  const setExternalSaveFunction = useCallback(
    (tab: TabNames, saveFn: () => Promise<void>) => {
      setSaveFunctions((prev) => ({ ...prev, [tab]: saveFn }));
    },
    [],
  );

  const externalSave = useCallback(
    async (tab: TabNames) => {
      await saveFunctions[tab]();
      clearUnsavedChanges(tab);
    },
    [saveFunctions, clearUnsavedChanges],
  );

  return (
    <UnsavedChangesContext.Provider
      value={{
        isDirty,
        markSettingAsChanged,
        clearUnsavedChanges,
        setExternalSaveFunction,
        externalSave,
      }}
    >
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChanges = () => {
  const context = useContext(UnsavedChangesContext);
  if (!context) {
    throw new Error(
      "useUnsavedChanges must be used within an UnsavedChangesProvider",
    );
  }
  return context;
};
