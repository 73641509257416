import ApplicationPage from "../components/ApplicationPage";

const Transactions = () => {
  return (
    <ApplicationPage pageName='Transactions'>
      <h3>Seamless Transactions</h3>
      <p>Lorem ipsum dolor sit amet...</p>
    </ApplicationPage>
  );
};

export default Transactions;
