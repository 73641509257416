import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";

interface CtaButtonProps {
  titlePrimary: string;
  titleSecondary?: string;
  titleHoverPrimary?: string;
  titleHoverSecondary?: string;
  bgPrimaryClass?: string;
  bgSecondaryClass?: string;
  textPrimaryClass?: string;
  textSecondaryClass?: string;
  outlinePrimaryClass?: string;
  outlineSecondaryClass?: string;
  bgPrimaryHoverClass?: string;
  bgSecondaryHoverClass?: string;
  textPrimaryHoverClass?: string;
  textSecondaryHoverClass?: string;
  outlinePrimaryHoverClass?: string;
  outlineSecondaryHoverClass?: string;
  disabled?: boolean;
  showOutlinePrimary?: boolean;
  showOutlineSecondary?: boolean;
  showOutlineHoverPrimary?: boolean;
  showOutlineHoverSecondary?: boolean;
  isPrimary?: boolean;
  fontBold?: boolean;
  additionalClassNames?: string;
  icon?: React.ReactNode;
  iconPrimary?: React.ReactNode;
  iconPrimaryHover?: React.ReactNode;
  iconSecondary?: React.ReactNode;
  iconSecondaryHover?: React.ReactNode;
  onClick?: () => void;
}

const CtaButton: React.FC<CtaButtonProps> = ({
  titlePrimary,
  titleSecondary,
  titleHoverPrimary,
  titleHoverSecondary,
  bgPrimaryClass = "bg-primary",
  bgSecondaryClass = "bg-primary-verylight",
  textPrimaryClass = "text-white",
  textSecondaryClass = "text-black",
  outlinePrimaryClass = "outline outline-2 outline-offset-0 outline-black",
  outlineSecondaryClass = "outline outline-2 outline-offset-0 outline-primary",
  bgPrimaryHoverClass = "bg-primary-verylight",
  bgSecondaryHoverClass = "bg-secondary",
  textPrimaryHoverClass = "text-primary",
  textSecondaryHoverClass = "text-secondary",
  outlinePrimaryHoverClass = "hover:outline hover:outline-2 hover:outline-offset-0 hover:outline-primary-light",
  outlineSecondaryHoverClass = "hover:outline hover:outline-2 hover:outline-offset-0 hover:outline-secondary-dark",
  disabled = false,
  showOutlinePrimary = false,
  showOutlineSecondary = false,
  showOutlineHoverPrimary = true,
  showOutlineHoverSecondary = true,
  isPrimary = true,
  fontBold = false,
  additionalClassNames = "",
  icon = null,
  iconPrimary,
  iconPrimaryHover,
  iconSecondary,
  iconSecondaryHover,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [maxTitleWidth, setMaxTitleWidth] = useState(0);
  const titleRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      // Save the initial title to avoid flickering
      const initialTitle = titleRef.current.innerText;

      // Create a temporary span element to measure widths
      const tempSpan = document.createElement("span");
      tempSpan.style.visibility = "hidden";
      tempSpan.style.position = "absolute";
      tempSpan.style.whiteSpace = "nowrap";
      document.body.appendChild(tempSpan);

      // Set the initial title for measurement
      tempSpan.innerText = initialTitle;
      let maxWidth = tempSpan.offsetWidth;

      // Measure the hover titles if present
      if (titleHoverPrimary) {
        tempSpan.innerText = titleHoverPrimary;
        maxWidth = Math.max(maxWidth, tempSpan.offsetWidth);
      }
      if (titleHoverSecondary) {
        tempSpan.innerText = titleHoverSecondary;
        maxWidth = Math.max(maxWidth, tempSpan.offsetWidth);
      }

      // Clean up the temporary element
      document.body.removeChild(tempSpan);

      // Set the maximum width
      setMaxTitleWidth(maxWidth);
    }
  }, [titlePrimary, titleHoverPrimary, titleSecondary, titleHoverSecondary]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const currentTitle = isHovered
    ? isPrimary
      ? titleHoverPrimary || titlePrimary
      : titleHoverSecondary || titleSecondary || titlePrimary
    : isPrimary
    ? titlePrimary
    : titleSecondary || titlePrimary;

  const currentIcon = isHovered
    ? isPrimary
      ? iconPrimaryHover || iconPrimary || icon
      : iconSecondaryHover || iconSecondary || icon
    : isPrimary
    ? iconPrimary || icon
    : iconSecondary || icon;

  // Determine the correct classes for background and text colors based on hover state and primary/secondary status
  const currentBgClass = isHovered
    ? isPrimary
      ? bgPrimaryHoverClass
      : bgSecondaryHoverClass
    : isPrimary
    ? bgPrimaryClass
    : bgSecondaryClass;

  const currentTextClass = isHovered
    ? isPrimary
      ? textPrimaryHoverClass
      : textSecondaryHoverClass
    : isPrimary
    ? textPrimaryClass
    : textSecondaryClass;

  const currentOutlineClass =
    (isHovered && showOutlineHoverPrimary && isPrimary) ||
    (isHovered && showOutlineHoverSecondary && !isPrimary)
      ? isPrimary
        ? outlinePrimaryHoverClass
        : outlineSecondaryHoverClass
      : (showOutlinePrimary && isPrimary) ||
        (showOutlineSecondary && !isPrimary)
      ? isPrimary
        ? outlinePrimaryClass
        : outlineSecondaryClass
      : "";

  const buttonClass = classNames(
    "inline-flex items-center h-10 px-5 transition-colors duration-150 rounded-lg focus:shadow-outline",
    additionalClassNames,
    fontBold ? "font-bold" : "",
    disabled ? "opacity-50 cursor-not-allowed" : "",
    currentBgClass,
    currentTextClass,
    currentOutlineClass,
    currentIcon ? "justify-between" : "justify-center",
  );

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      style={{
        width: `calc(${maxTitleWidth}px + 40px + ${
          currentIcon ? "40px" : "0"
        })`,
      }}
    >
      <span ref={titleRef} className='flex-1 text-center overflow-hidden'>
        {currentTitle}
      </span>
      {currentIcon && <span className='ml-3'>{currentIcon}</span>}
    </button>
  );
};

export default CtaButton;
