import { gql } from "@apollo/client";

export const GET_LOGS_FOR_RECORDING = gql`
  query GetLogsForRecording($sessionId: String!, $companyId: Int) {
    getLogsForRecording(sessionId: $sessionId, companyId: $companyId) {
      level
      trace
      payload
      timestamp
      formattedTimestamp
    }
  }
`;
