import { useCallback, useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import ApplicationPage from "../components/ApplicationPage";
import DashboardTile from "../components/DashboardTile";
import RecordList, { LogItem } from "../components/Search/RecordList";
import SearchBar from "../components/Search/SearchBar";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DEBUG = import.meta.env.VITE_DEBUG ? import.meta.env.VITE_DEBUG : false;
const DEFAULT_RECORDINGS_PER_PAGE = 25;

const LogsWithSearchAndExaminePage = () => {
  // const { selectedCompany } = useCompany();
  const [page, setPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  // const [eventsError, setEventsError] = useState(false);
  const [logsPerPage, setLogsPerPage] = useState(DEFAULT_RECORDINGS_PER_PAGE);
  const [loading, setLoading] = useState(false); // State for loading
  const totalPages = Math.ceil(totalItemsCount / logsPerPage);
  const [notifiedAboutFakeIdSet, setNotifiedAboutFakeIdSet] = useState(false);

  // Ref to store previous results to avoid reprocessing the same results
  // State and refs
  const [consoleLogs, setConsoleLogs] = useState<any[]>([]);
  const previousResultsRef = useRef<any[]>([]);

  // Ref to control if handleSearchResults should be invoked on first render
  const isInitialRender = useRef(true);

  const containerRef = useRef<HTMLDivElement>(null);
  // const navigate = useNavigate();
  // const { user, isSailfishQAMember } = useAuth();
  // const { isAdminEnabled } = useAdmin();

  const handleSearchResults = useCallback((results: any[]) => {
    // Check if the results have changed from the previous ones using deep comparison
    if (
      JSON.stringify(previousResultsRef.current) === JSON.stringify(results)
    ) {
      return; // Exit if results are the same to prevent unnecessary re-processing
    }

    if (DEBUG) console.log("handleSearchResults", "results", results);

    // Convert each result to the LogItem format
    const logItems: LogItem[] = results.map((result, index) => {
      const timestamp = result.data?.timestamp?.toString() || "";
      const timestampFormatted =
        result.data?.timestampFormatted?.toString() || "";
      // TODO - Sibyl post-launch - remove the in-line if and force to ID
      const id = result.data?.id ? result.data?.id : `${timestamp}-${index}`;
      if (!result.data?.id && !notifiedAboutFakeIdSet) {
        setNotifiedAboutFakeIdSet(true);
        if (DEBUG)
          console.log("Set ID on LogsWithSearchAndExaminePage to FakeID");
      }
      const message = result.data?.["data.payload.payload"]?.[0] || "";
      let recordingId: string = result.data?.["filename"];
      if (recordingId !== null) {
        recordingId = recordingId.replace(/^gs:\/\/[^/]+\/([^/]+)\/.*$/, "$1");
      }
      const fields = {
        trace: result.data?.["data.payload.trace"]?.join(", ") || "",
        level: result.data?.["data.payload.level"] || "",
      };

      return {
        id,
        recordingId,
        timestampFormatted,
        message,
        fields,
      };
    });

    // Update the state with the converted log items
    setConsoleLogs(logItems);
    if (DEBUG) console.log("Converted Console Logs:", logItems);

    // Update the ref to the current results after processing
    previousResultsRef.current = results;
  }, []);

  const handleTotalItemsCount = (count: number) => {
    setTotalItemsCount(count); // Update the total items count
  };

  // Effect to manage initial render and reset states when necessary
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false; // Mark initial render as handled
      return;
    }

    // Reset previous results when necessary
    previousResultsRef.current = [];
  }, [consoleLogs, loading]); // Runs when `consoleLogs` or `loading` state changes

  // Render the tiles including the updated SearchBar and RecordList components
  const tiles = [
    {
      id: "search-bar",
      title: "Search",
      content: (
        <SearchBar
          entity='consoleLogs'
          limit={logsPerPage}
          offset={(page - 1) * logsPerPage}
          onSearchResults={handleSearchResults}
          setTotalItemsCount={handleTotalItemsCount}
          onLoadingChange={setLoading}
        />
      ),
      layout: { i: "search-bar", x: 0, y: 0, w: 4, h: 2 },
    },
    {
      id: "logs",
      title: "Logs",
      // Pass the dynamic consoleLogs data to RecordList
      content: (
        <RecordList
          logs={consoleLogs}
          page={page}
          totalPages={totalPages}
          onPageChange={setPage}
          logsPerPage={logsPerPage}
          setLogsPerPage={setLogsPerPage}
          triageAllowed={true}
          loading={loading}
        />
      ),
      layout: { i: "logs", x: 0, y: 2, w: 4, h: 7 },
    },
  ];

  return (
    <ApplicationPage pageName='Logs'>
      <div ref={containerRef}>
        <ResponsiveGridLayout
          className='layout'
          layouts={{ lg: tiles.map((tile) => tile.layout) }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 4 }}
          rowHeight={100}
          width={1200}
          containerPadding={[15, 15]}
          draggableHandle='.drag-handle'
        >
          {tiles.map((tile) => (
            <div key={tile.id} className='p-0 m-0'>
              <DashboardTile
                key={tile.id}
                id={tile.id}
                title={tile.title}
                onRemove={() => {}}
              >
                {tile.content}
              </DashboardTile>
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </ApplicationPage>
  );
};

export default LogsWithSearchAndExaminePage;
