import React, { ReactElement, ReactNode } from "react";

interface ApplicationPageProps {
  pageName: string;
  children: ReactNode;
  CtaButton?: ReactElement; // Accepts a React element as a prop
}

const ApplicationPageWithCta: React.FC<ApplicationPageProps> = ({
  pageName,
  children,
  CtaButton,
}) => {
  return (
    <div className='h-full w-full'>
      <div
        id='headerContainer'
        className='flex items-center w-full overflow-hidden mb-2'
      >
        <span
          id='applicationPageHeader'
          className='inline-flex items-center font-semibold text-5xl leading-relaxed'
        >
          {pageName}
        </span>
        {CtaButton && <div className='ml-4 flex items-center'>{CtaButton}</div>}
      </div>
      {children}
    </div>
  );
};

export default ApplicationPageWithCta;
