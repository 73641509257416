import { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import Modal from "../components/Modal";
import RemainingAreaContainer from "../components/RemainingAreaContainer";
import Configuration from "../components/Settings/Configuration";
import NotificationSettings from "../components/Settings/Notifications";
import Recording from "../components/Settings/Recording";
import SetupInstall from "../components/Settings/SetupInstall";
import TabButton from "../components/TabButton";
import { useUnsavedChanges } from "../contexts/UnsavedChangesContext";

type TabNames = "setup" | "recording" | "configuration" | "notifications";

const Settings = () => {
  const [activeTab, setActiveTab] = useState<TabNames>("setup");
  const { isDirty, externalSave, clearUnsavedChanges } = useUnsavedChanges();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [nextLocation, setNextLocation] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentSection = location.pathname.split("/").pop() || "setup";
    setActiveTab(currentSection as TabNames);
  }, [location.pathname]);

  const handleSectionChange = (section: TabNames) => {
    const nextPath = `/settings/${section}`;
    if (isDirty(activeTab) && location.pathname !== nextPath) {
      setShowConfirmModal(true);
      setNextLocation(nextPath);
    } else {
      navigate(nextPath);
    }
  };

  const handleUserDecision = async (
    saveChanges: boolean,
    navigateAnyway: boolean,
  ) => {
    if (saveChanges) {
      await externalSave(activeTab);
    }
    if (navigateAnyway) {
      navigate(nextLocation);
    }
    setShowConfirmModal(false);
  };

  return (
    <ApplicationPage pageName='Settings'>
      <div
        id='settingsPageTabBar'
        className='flex justify-center border-b border-gray-300'
      >
        <TabButton
          onClick={() => handleSectionChange("setup")}
          selected={activeTab === "setup"}
        >
          Setup/Install
        </TabButton>
        <TabButton
          onClick={() => handleSectionChange("notifications")}
          selected={activeTab === "notifications"}
        >
          Notifications
        </TabButton>
        <TabButton
          onClick={() => handleSectionChange("recording")}
          selected={activeTab === "recording"}
        >
          Recording
        </TabButton>
        <TabButton
          onClick={() => handleSectionChange("configuration")}
          selected={activeTab === "configuration"}
        >
          Configuration
        </TabButton>
      </div>
      <RemainingAreaContainer
        idsX={[]}
        idsY={["settingsPageTabBar", "applicationPageHeader"]}
        initialClassName='mt-4'
      >
        <Routes>
          <Route path='/' element={<Navigate replace to='setup' />} />
          <Route path='setup' element={<SetupInstall />} />
          <Route path='notifications' element={<NotificationSettings />} />
          <Route path='configuration' element={<Configuration />} />
          <Route path='recording' element={<Recording />} />
          <Route path='*' element={<Navigate replace to='setup' />} />
        </Routes>
        {showConfirmModal && (
          <Modal
            onClose={() => handleUserDecision(false, false)}
            onConfirm={() => handleUserDecision(true, true)}
            onDiscard={() => handleUserDecision(false, true)}
            changes={[]}
          />
        )}
      </RemainingAreaContainer>
    </ApplicationPage>
  );
};

export default Settings;
