// components/PrivateRoute.tsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isTokenExpired } from "../utils/auth";

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const token = localStorage.getItem("jwt");
  const location = useLocation();

  if (isTokenExpired(token)) {
    localStorage.setItem("redirectAfterLogin", location.pathname);
    return <Navigate to='/login' />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
