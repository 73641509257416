import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { FaCheckCircle, FaCircle } from "react-icons/fa";
import { FiLink2 } from "react-icons/fi";
import LoadingAnimation from "./LoadingAnimation";

interface ExpandableAndSelectableListsProps {
  items: any[];
  headerTemplate: (item: any) => JSX.Element;
  gutterTemplate: (item: any) => JSX.Element;
  showTimelineBarForRows?: boolean;
  isSelectable?: boolean; // New prop to determine if rows are selectable
  onDoubleClick?: (id: string) => void; // New prop to handle double-click
  loading?: boolean; // New prop for loading state
  error?: Error | null; // New prop for error state
}

const ExpandableAndSelectableLists: React.FC<
  ExpandableAndSelectableListsProps
> = ({
  items,
  headerTemplate,
  gutterTemplate,
  showTimelineBarForRows = false,
  isSelectable = true,
  onDoubleClick,
  loading = false,
  error = null,
}) => {
  // State to keep track of expanded items
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>(
    {},
  );
  // State to keep track of selected items
  const [selectedItems, setSelectedItems] = useState<Record<number, boolean>>(
    {},
  );

  if (loading) {
    return <LoadingAnimation text='Loading' />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Function to toggle the expanded state of an item
  const toggleItemExpansion = (index: number) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // Function to toggle the selection state of an item
  const toggleItemSelection = (index: number) => {
    setSelectedItems((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  // const hoverColor = "hover:bg-sky-50";
  // const groupHoverColor = "group-hover:bg-sky-50";
  const hoverColor = "";
  const groupHoverColor = "";
  const nonTimelineExpandedLineColor = "bg-zinc-400";
  const borderColorL = "border-l-zinc-400";
  const navButtonHoverColor = "hover:text-blue-500";

  return (
    <div className='overflow-auto flex-grow'>
      {items.map((item, index) => (
        <div
          key={index}
          className={`relative cursor-pointer ${hoverColor} group ring ring-inset ring-transparent hover:ring-sky-200`}
          onDoubleClick={(e) => {
            e.stopPropagation(); // Prevent other click handlers from firing
            onDoubleClick && onDoubleClick(item.id);
          }}
        >
          {/* Container for both Header and Expandable Content */}
          <div className={`group ${hoverColor}`}>
            {/* Row Container */}
            <div
              className={`p-4 flex items-center relative ${groupHoverColor}`}
              onClick={() => toggleItemExpansion(index)} // Only handle single click for expanding/collapsing
            >
              {/* Timeline and Select Button */}
              {showTimelineBarForRows && (
                <div className='absolute left-4 top-0 bottom-0 flex items-center'>
                  <div className='w-0.5 bg-gray-400 h-full relative flex items-center'></div>
                  {isSelectable && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleItemSelection(index);
                      }}
                      className='absolute left-1/2 transform -translate-x-1/2 w-5 h-5 bg-transparent rounded-full flex items-center justify-center'
                    >
                      {selectedItems[index] ? (
                        <FaCheckCircle
                          className='text-[#1F75FE] bg-white rounded-full'
                          style={{ backgroundColor: "white" }}
                        />
                      ) : (
                        <FaCircle
                          className='text-white bg-white rounded-full'
                          style={{
                            border: "1px solid gray",
                            boxSizing: "border-box",
                          }}
                        />
                      )}
                    </button>
                  )}
                </div>
              )}

              {/* Header Content */}
              <div className='flex items-center flex-grow pl-8'>
                {headerTemplate(item)}
              </div>

              {/* Add link icon button to navigate */}
              {onDoubleClick && (
                <Tooltip title='Go to item page' placement='top'>
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent row click
                      if (onDoubleClick) onDoubleClick(item.id);
                    }}
                    className={`ml-auto mr-8 rounded ${navButtonHoverColor}`} // Icon button with hover color change
                  >
                    <FiLink2 size={20} />
                  </button>
                </Tooltip>
              )}
            </div>

            {/* Expandable Section */}
            <div style={{ display: expandedItems[index] ? "block" : "none" }}>
              {/* Collapsible Content */}
              <div className={`relative ${groupHoverColor}`}>
                {/* Arrow-like lines for expanded content */}
                {/* {!showTimelineBarForRows && expandedItems[index] && ( */}
                {/* <div className='absolute h-full left-4 top-0 flex flex-col items-center'> */}
                {/* Top vertical line segment */}
                {/* <div
                      className={`w-px h-1/2 ${nonTimelineExpandedLineColor}`}
                    /> */}
                {/* Horizontal line to the expanded content, slightly shorter */}
                {/* <div
                      className={`h-px w-7 translate-x-1/2 ${nonTimelineExpandedLineColor} relative`}
                    > */}
                {/* Arrow cap */}
                {/* <div
                        className={`absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-full w-0 h-0 border-l-4 ${borderColorL} border-y-4 border-y-transparent`}
                      /> */}
                {/* </div> */}
                {/* </div> */}
                {/* )} */}
                {/* Vertical continuation line if timeline is shown */}
                {/* {showTimelineBarForRows && (
                  <div className='absolute left-4 top-0 bottom-0 w-0.5 bg-gray-400'></div>
                )} */}
                {/* Expandable Content */}
                <div
                  className={`pl-16 pr-4 py-4 bg-gray-100 ${groupHoverColor}`}
                >
                  <div
                    className={`border bg-white rounded-md dark:bg-gray-900 p-4`}
                  >
                    {gutterTemplate(item)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ExpandableAndSelectableLists;
