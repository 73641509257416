import React, { useState } from "react";

interface LoadingErrorModalProps {
  error: string;
}

const LoadingErrorModal: React.FC<LoadingErrorModalProps> = ({ error }) => {
  const [isOpen, setIsOpen] = useState(true); // State to manage the open/close status of the modal

  if (!isOpen) return null; // Do not render the modal if it is not open

  return (
    <div
      className='relative inset-0 h-full bg-gray-600 bg-opacity-50 flex justify-center items-center'
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className='relative w-full max-w-md bg-white border-2 border-black shadow-xl p-4'>
        <button
          onClick={() => setIsOpen(false)} // Update state to close the modal
          className='absolute top-2 right-2 text-black'
          aria-label='Close modal'
        >
          <svg
            className='w-6 h-6'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M6 18L18 6M6 6l12 12'
            ></path>
          </svg>
        </button>
        <h2 id='modal-modal-title' className='text-lg font-bold'>
          Loading Error
        </h2>
        <p id='modal-modal-description' className='mt-2'>
          {error}
        </p>
      </div>
    </div>
  );
};

export default LoadingErrorModal;
