// ActionBanner.tsx
import React from "react";

interface ActionBannerProps {
  onSave: () => void;
  onDiscard: () => void;
  onUndo: () => void;
  onRedo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}

const ActionBanner: React.FC<ActionBannerProps> = ({
  onSave,
  onDiscard,
  onUndo,
  onRedo,
  canUndo,
  canRedo,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        boxShadow: "0 -2px 10px rgba(0,0,0,0.2)",
        padding: "10px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div>
        {/* <button
          style={{
            marginRight: "8px",
            backgroundColor: canUndo ? "#007bff" : "#ccc",
            color: canUndo ? "white" : "black",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: canUndo ? "pointer" : "not-allowed",
          }}
          onClick={onUndo}
          disabled={!canUndo}
        >
          Undo
        </button>
        <button
          style={{
            marginRight: "8px",
            backgroundColor: canRedo ? "#007bff" : "#ccc",
            color: canRedo ? "white" : "black",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: canRedo ? "pointer" : "not-allowed",
          }}
          onClick={onRedo}
          disabled={!canRedo}
        >
          Redo
        </button> */}
      </div>
      <div>
        <button
          style={{
            backgroundColor: "#28a745",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}
          onClick={onSave}
        >
          Save
        </button>
        <button
          style={{
            backgroundColor: "#dc3545",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            border: "none",
            cursor: "pointer",
          }}
          onClick={onDiscard}
        >
          Discard
        </button>
      </div>
    </div>
  );
};

export default ActionBanner;
