import React, { useEffect, useState } from "react";

interface Props {
  ids: string[];
  initialClassName: string;
  children: React.ReactNode;
  minHeight?: string;
  onHeightChange?: (height: number) => void; // New prop
}

const RemainingHeightContainer: React.FC<Props> = ({
  ids,
  initialClassName,
  children,
  minHeight = "400px",
  onHeightChange, // New prop
}) => {
  const [remainingHeightStyle, setRemainingHeightStyle] = useState("");

  useEffect(() => {
    const calcSectionInitialHeight = () => {
      const element = document.getElementById("mainAppPagesSection");
      if (!element) return 0;

      const style = window.getComputedStyle(element);
      const height = element.clientHeight;
      const paddingTop = parseInt(style.paddingTop, 10);
      const paddingBottom = parseInt(style.paddingBottom, 10);

      return height - paddingTop - paddingBottom || 0;
    };
    const calculateRemainingHeight = () => {
      const totalHeight = ids.reduce((total, id) => {
        const element = document.getElementById(id);
        return total + (element?.clientHeight || 0);
      }, 0);
      const remainingHeight = calcSectionInitialHeight() - totalHeight;
      setRemainingHeightStyle(`calc(${remainingHeight}px)`);

      if (onHeightChange) {
        // Call the callback
        onHeightChange(remainingHeight);
      }
    };

    calculateRemainingHeight();

    window.addEventListener("resize", calculateRemainingHeight);

    return () => {
      window.removeEventListener("resize", calculateRemainingHeight);
    };
  }, [ids, onHeightChange]); // Added onHeightChange dependency

  return (
    <div
      style={{ height: remainingHeightStyle, minHeight }}
      className={`${initialClassName} overflow-y-auto`}
    >
      {children}
    </div>
  );
};

export default RemainingHeightContainer;
