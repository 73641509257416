import { Link } from "react-router-dom";
import LogoSquare from "..//images/sailfishLogoSquareCropped.png";
import ApplicationPage from "../components/ApplicationPage";

const WelcomePage = () => {
  return (
    <ApplicationPage pageName='Welcome'>
      <div className='flex flex-col items-center mt-10 p-6 bg-white shadow-md rounded-md'>
        <img src={LogoSquare} alt='Sailfish Logo' className='w-96 h-96 mb-6' />
        <h1 className='text-2xl font-bold mb-4'>Welcome to Sailfish</h1>
        <p className='text-lg mb-6'>
          Head to{" "}
          <Link to='/settings' className='text-blue-500 underline'>
            settings
          </Link>{" "}
          to get started.
        </p>
        <div className='text-left'>
          <h2 className='text-xl font-semibold mb-2'>
            We'll be adding the following functionality over the next few weeks,
            so keep an eye out on our navigation pane:
          </h2>
          <ul className='list-disc list-inside'>
            <li className='mb-2'>
              <strong>Session Playback</strong>
              <ul className='list-disc list-inside ml-4'>
                <li>Historical</li>
                <li>Live</li>
              </ul>
            </li>
            <li className='mb-2'>
              <strong>Session Stats</strong>
              <ul className='list-disc list-inside ml-4'>
                <li># of Daily, Weekly, and Monthly Active Sessions</li>
                <li># of Live Sessions</li>
              </ul>
            </li>
            <li className='mb-2'>
              <strong>User Stats</strong>
              <ul className='list-disc list-inside ml-4'>
                <li># of Daily, Weekly, and Monthly Active Users</li>
                <li># of Live Users</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </ApplicationPage>
  );
};

export default WelcomePage;
