// TabButton.tsx
import React from "react";

interface TabButtonProps {
  onClick: () => void;
  selected: boolean;
  children: React.ReactNode;
}

const TabButton: React.FC<TabButtonProps> = ({
  onClick,
  selected,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 mx-2 ${
        selected ? "text-blue-600 border-b-2 border-blue-600" : "text-gray-600"
      } hover:text-blue-600 focus:outline-none`}
    >
      {children}
    </button>
  );
};

export default TabButton;
