import { gql } from "@apollo/client";

export const CREATE_TRIAGE_MUTATION = gql`
  mutation CreateTriage(
    $triageInfoRecordings: [TriageInputRecording!]
    $triageInfoLogs: [TriageInputLogs!]
    $companyId: Int
  ) {
    createTriage(
      triageInfoRecordings: $triageInfoRecordings
      triageInfoLogs: $triageInfoLogs
      companyId: $companyId
    ) {
      id
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
// Removed this from above since we only need to know it was created
// triageRecordingData {
//   timeWindows {
//     timestampStart
//     timestampEnd
//   }
// }

export const GET_TRIAGE = gql`
  query GetTriage($triageId: Int!, $companyId: Int) {
    getTriage(triageId: $triageId, companyId: $companyId) {
      createdBy
      createdAt
      triageRecordingData {
        recording {
          id
          formattedStartTimestamp
          durationMs
          numberOfEventsSeen
        }
        timeWindows {
          timestampStart
          timestampEnd
        }
      }
      triageLogData {
        recording {
          id
          formattedStartTimestamp
          durationMs
          numberOfEventsSeen
        }
      }
      updatedBy
    }
  }
`;
