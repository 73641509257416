import { useMutation } from "@apollo/client";
import rrwebPlayer from "@sailfish-rrweb/rrweb-player";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { BsLightningChargeFill } from "react-icons/bs";
import {
  FaBriefcaseMedical,
  FaPause,
  FaPlay,
  FaStethoscope,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "rrweb-player/dist/style.css";
import { useCompany } from "../contexts/CompanyContext";
import { CREATE_TRIAGE_MUTATION } from "../graphql/triage";
import CtaButton from "./Buttons/CtaButton";
import ToggleIconButton from "./ToggleIconButton";
import TimelineForRrwebPlayer, {
  Region,
} from "./Triage/TimelineForRrwebPlayer";

interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: React.ComponentType;
}

interface HighlightedSection {
  startTime: number;
  endTime: number;
  text: string;
  color: string;
}

interface RRWebPlayerProps {
  id?: string;
  events: any[];
  timelineEvents: TimelineEvent[];
  highlightedSections: HighlightedSection[];
  width: number;
  height: number;
  initialTime?: number | null;
  onTimeUpdate?: (currentTime: number) => void;
}

//
//
// TODO - add the events to the timeline...
//
//

const RRWebPlayer = forwardRef<any, RRWebPlayerProps>(
  (
    {
      id,
      events,
      width,
      height,
      initialTime,
      onTimeUpdate,
      timelineEvents,
      highlightedSections,
    },
    ref,
  ) => {
    const playerRef = useRef<any>(null);
    const playerContainerRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [skipInactivity, setSkipInactivity] = useState(true);
    const [displayOnly, setDisplayOnly] = useState(true); // State to toggle displayOnly
    const [selectedRegions, setSelectedRegions] = useState<Region[]>([]);
    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    const { selectedCompany } = useCompany(); // Use the useCompany hook to get the selected company
    const navigate = useNavigate();

    // const [createTriage, { loading, error }] = useMutation(
    const [createTriage] = useMutation(CREATE_TRIAGE_MUTATION);
    const handleCreateTriage = () => {
      if (selectedRegions.length === 0) {
        alert(
          "Please select at least one time window before creating a triage.",
        );
        return;
      }

      // Mapping selected regions to match triageInfoRecordings or triageInfoLogs
      const triageInfoRecordings = selectedRegions.map((region) => ({
        recordingId: id!,
        identifiedProblemWindows: [
          {
            timestampStart: Math.round((region.start / 100) * totalTime),
            timestampEnd: Math.round((region.end / 100) * totalTime),
          },
        ],
      }));

      createTriage({
        variables: {
          triageInfoRecordings,
          triageInfoLogs: null, // Assuming only recordings are handled here, update if necessary
          companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
        },
      })
        .then((response) => {
          if (response.data?.createTriage) {
            navigate(`/triage/${response.data.createTriage.id}`, {
              state: { from: "RRWebPlayerWithTriage" },
            });
          }
        })
        .catch((err) => {
          console.error("Error creating triage:", err);
        });
    };

    const formatTime = (timeInSeconds: number): string => {
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = Math.floor(timeInSeconds % 60);

      const formattedHours = hours > 0 ? `${hours}:` : "";
      const formattedMinutes =
        minutes < 10 && hours > 0 ? `0${minutes}:` : `${minutes}:`;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

      return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
    };

    // Function to format time with hours, minutes, seconds, and milliseconds
    const formatTimeWithMilliseconds = (timeInMilliseconds: number): string => {
      const hours = Math.floor(timeInMilliseconds / 3600000); // 1 hour = 3600000 ms
      const minutes = Math.floor((timeInMilliseconds % 3600000) / 60000); // 1 minute = 60000 ms
      const seconds = Math.floor((timeInMilliseconds % 60000) / 1000); // 1 second = 1000 ms
      const milliseconds = Math.floor(timeInMilliseconds % 1000);

      const formattedHours = hours > 0 ? `${hours}:` : "";
      const formattedMinutes =
        minutes < 10 && hours > 0 ? `0${minutes}:` : `${minutes}:`;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
      const formattedMilliseconds =
        milliseconds < 100 ? `0${milliseconds}` : `${milliseconds}`;

      return `${formattedHours}${formattedMinutes}${formattedSeconds}.${formattedMilliseconds}`;
    };

    const getCurrentTimeString = () => formatTime(currentTime / 1000);

    const getTotalTimeString = () => formatTime(totalTime / 1000);

    useImperativeHandle(ref, () => ({
      play() {
        playerRef.current.play();
        setIsPlaying(true);
      },
      pause() {
        playerRef.current.pause();
        setIsPlaying(false);
      },
      seekToPercentage(time: number) {
        playerRef.current.goto(time);
        setCurrentTime(time);
      },
      seekToTime(time: number) {
        playerRef.current.goto(time - startTime);
        setCurrentTime(time - startTime);
      },
      resetPlayer() {
        playerRef.current.goto(0);
        playerRef.current.play();
        setCurrentTime(0);
        setIsPlaying(true);
      },
    }));

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.pause();
      }

      if (!playerRef.current) {
        playerRef.current = new rrwebPlayer({
          target: document.getElementById("rrweb-player")!,
          props: {
            events,
            autoPlay: false,
            showController: false,
            width: width,
            height: height,
            inactivePeriodThreshold: 10 * 1000,
            skipInactive: skipInactivity,
          },
        });

        const playerMetadata = playerRef.current.getMetaData();
        setStartTime(playerMetadata.startTime);
        setEndTime(playerMetadata.endTime);
        setTotalTime(playerMetadata.totalTime);

        if (initialTime) {
          playerRef.current.goto(initialTime);
        }

        playerRef.current.addEventListener(
          "ui-update-current-time",
          (event: any) => {
            setCurrentTime(event.payload);
          },
        );

        playerRef.current.addEventListener(
          "ui-update-player-state",
          (event: any) => {
            if (event.payload === "paused") {
              setIsPlaying(false);
            }
          },
        );
      } else {
        playerRef.current.$set({
          events,
          width,
          height,
        });
        playerRef.current.triggerResize();

        if (initialTime) {
          playerRef.current.goto(initialTime);
        }
      }
    }, [events, width, height, initialTime]);

    const togglePlayPause = () => {
      if (!isPlaying) {
        playerRef.current.goto(currentTime);
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    };

    const changeSpeed = (speed: number) => {
      setPlaybackSpeed(speed);
      playerRef.current.setSpeed(speed);
    };

    const toggleSkipInactivity = () => {
      playerRef.current.toggleSkipInactive();
      setSkipInactivity((prev) => !prev);
    };

    const toggleDisplayMode = () => {
      setDisplayOnly((prev) => !prev);
    };

    const timelineEventsMapped = timelineEvents.map((event) => ({
      time: event.time - startTime,
      // time: ((event.time - startTime) / totalTime) * 100,
      // time: event.time,
      icon: <event.Icon />,
      color: event.color !== undefined ? event.color : "blue", // Assuming a default color, adjust as needed
      text: event.text, // Adjust if you need a different text
    }));

    const inactiveTimes = playerRef.current?.inactivePeriods() as
      | [number, number][]
      | undefined;
    const formattedInactiveTimes = inactiveTimes
      ? inactiveTimes.map(([start, end]) => {
          const adjustedStart = ((start - startTime) / totalTime) * 100;
          const adjustedEnd = ((end - startTime) / totalTime) * 100;
          return {
            start: adjustedStart,
            end: adjustedEnd,
          };
        })
      : []; // Default to an empty array if inactiveTimes is undefined

    return (
      <div
        id='rrweb-tile'
        ref={playerContainerRef}
        className='w-full h-full flex flex-col'
      >
        <div id='rrweb-player' className='flex justify-center'></div>
        <div id='rrweb-playback-bar' className='relative mt-8 z-10'>
          <TimelineForRrwebPlayer
            playPosition={(currentTime / totalTime) * 100}
            totalTime={totalTime}
            events={timelineEventsMapped} // Pass the mapped events
            inactivePeriods={formattedInactiveTimes} // Pass the inactive periods
            onTimelineClick={(percentage: number) => {
              const newTime = (percentage / 100) * totalTime;
              setCurrentTime(newTime);
              playerRef.current.goto(newTime);
            }}
            displayOnly={displayOnly} // Pass the displayOnly state
            selectedRegions={selectedRegions} // Pass selected regions to Timeline
            setSelectedRegions={setSelectedRegions} // Allow Timeline to update selected regions
          />
          <div className='relative flex items-center justify-center my-2'>
            <div className='flex items-center space-x-4'>
              <ToggleIconButton
                onClick={togglePlayPause}
                size='text-2xl'
                flag={isPlaying}
                trueColor='text-slate-700'
                falseColor='text-green-500'
                icon={FaPlay}
                trueIcon={FaPause}
                falseIcon={FaPlay}
                trueTooltip='Pause'
                falseTooltip='Play'
              />
              <select
                value={playbackSpeed}
                onChange={(e) => changeSpeed(Number(e.target.value))}
                className='btn btn-primary m-2'
              >
                <option value={1}>1x</option>
                <option value={2}>2x</option>
                <option value={4}>4x</option>
              </select>
              <ToggleIconButton
                onClick={toggleSkipInactivity}
                size='text-2xl'
                flag={skipInactivity}
                trueColor='text-yellow-400'
                falseColor='text-slate-400'
                icon={BsLightningChargeFill}
                trueTooltip='Skipping inactivity'
                falseTooltip='Click to skip inactivity'
              />
              <div id='time-display' className='flex items-center text-lg mx-6'>
                <span>{getCurrentTimeString()}</span>
                <span className='mx-2'>/</span>
                <span>{getTotalTimeString()}</span>
              </div>
              {displayOnly ? (
                <CtaButton
                  titlePrimary='Examine'
                  titleHoverPrimary='Examining'
                  icon={<FaStethoscope />}
                  bgPrimaryClass='bg-red-500'
                  textPrimaryClass='text-white'
                  bgPrimaryHoverClass='hover:bg-red-100'
                  textPrimaryHoverClass='hover:text-red-500'
                  onClick={toggleDisplayMode}
                  isPrimary={true}
                />
              ) : (
                <CtaButton
                  titlePrimary='Examining'
                  titleHoverSecondary='Quit'
                  icon={<FaStethoscope />}
                  bgSecondaryClass='bg-red-100'
                  textSecondaryClass='text-red-500'
                  bgSecondaryHoverClass='hover:bg-red-500'
                  textSecondaryHoverClass='hover:text-white'
                  onClick={toggleDisplayMode}
                  isPrimary={false}
                />
              )}
              {displayOnly ? (
                <></>
              ) : (
                <CtaButton
                  titlePrimary='Triage'
                  titleHoverPrimary='Start'
                  icon={<FaBriefcaseMedical />}
                  bgPrimaryClass='bg-green-500'
                  textPrimaryClass='text-white'
                  bgPrimaryHoverClass='hover:bg-green-100'
                  textPrimaryHoverClass='hover:text-green-500'
                  onClick={handleCreateTriage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default RRWebPlayer;
