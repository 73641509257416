import React from "react";

interface MetadataItem {
  title: string;
  content: React.ReactNode;
}

interface OccurrenceDetailsProps {
  metadataItems: MetadataItem[];
}

const OccurrenceDetails: React.FC<OccurrenceDetailsProps> = ({
  metadataItems,
}) => {
  return (
    <div className='w-full pt-4'>
      <table className='w-full text-center border-none'>
        <thead className='border-none'>
          <tr className='h-10'>
            {metadataItems.map((item, index) => (
              <th key={index} className='px-4 border-none h-full text-3xl'>
                <div className='flex items-center justify-center h-full w-full'>
                  {item.title}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='border-none'>
          <tr className='h-20'>
            {metadataItems.map((item, index) => (
              <td key={index} className='px-4 h-full'>
                <div className='flex items-center justify-center h-full text-2xl'>
                  {item.content}
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OccurrenceDetails;
