import { formatDistanceToNow, parseISO } from "date-fns";

/**
 * Formats an ISO date string into a human-readable "time ago" format.
 * @param isoTime - ISO 8601 date string (e.g., "2024-08-27T01:10:34.396179+00:00").
 * @returns A formatted string indicating the relative time (e.g., "3 days ago").
 */
const formatRelativeTime = (isoTime: string): string => {
  // Parse the ISO string to a Date object
  const date = parseISO(isoTime);

  // Format the date as a relative time (e.g., "3 days ago")
  return formatDistanceToNow(date, { addSuffix: true });
};

export default formatRelativeTime;
