// CodeBlock.tsx
import React, { useState } from "react";
import { GoCopy } from "react-icons/go";
import CopyToClipboard from "../Functions/CopyToClipboard";

interface CodeBlockProps {
  title: string;
  code: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ title, code }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleCopy = () => {
    CopyToClipboard(code);
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };

  return (
    <div className='mb-6'>
      <div className='bg-gray-300 text-gray-500 p-2 rounded-t flex justify-between items-center'>
        <h5 className='text-lg font-bold'>{title}</h5>
        <button
          className='flex items-center justify-center bg-gray-300 text-gray-400 p-1 rounded hover:bg-gray-400 hover:text-white transition-colors relative'
          onClick={handleCopy}
        >
          <GoCopy className='transform rotate-180' size={24} />
          {showTooltip && (
            <div className='absolute top-full left-1/2 transform -translate-x-1/2 bg-black text-white text-xs rounded py-1 px-2 mt-2'>
              Copied!
              <div className='absolute bg-black h-2 w-2 transform rotate-45 -top-1 left-1/2 -translate-x-1/2'></div>
            </div>
          )}
        </button>
      </div>
      <div className='bg-gray-100 p-2 rounded-b overflow-x-auto'>
        <pre className='whitespace-pre'>{code}</pre>
      </div>
    </div>
  );
};

export default CodeBlock;
