import React, { useState } from "react";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import {
  FaAngleDown,
  FaAngleUp,
  FaCheckCircle,
  FaCircle,
  FaDesktop,
  FaMobileAlt,
  FaVideo,
} from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";

interface RecordingRowProps {
  id: string;
  userId?: string;
  duration: string; // duration in "HH:MM:SS" format
  startTime: string; // start time as a string, assumed to be in the app user's time zone
  deviceType: "desktop" | "mobile"; // 'desktop' or 'mobile' to indicate device type
  fields: { [key: string]: string }; // expandable section content, same as in LogRow
  isSelected: boolean;
  isHighlighted?: boolean;
  onSelectToggle: () => void;
  onClick?: (event: React.MouseEvent) => void;
  onMouseDown?: (event: React.MouseEvent) => void;
  onMouseUp?: (event: React.MouseEvent) => void;
  onMouseMove?: (event: React.MouseEvent) => void;
  onContextMenu?: (event: React.MouseEvent) => void;
  isSelectable?: boolean; // Add isSelectable prop
}

const RecordingRow: React.FC<RecordingRowProps> = ({
  id,
  userId,
  duration,
  startTime,
  deviceType,
  fields,
  isSelected,
  isHighlighted = false,
  onSelectToggle,
  onClick,
  onMouseDown,
  onMouseUp,
  onMouseMove,
  onContextMenu,
  isSelectable,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleRowClick = (event: React.MouseEvent) => {
    // If CMD/CTRL key is held, handle highlighting instead of expansion
    if (event.metaKey || event.ctrlKey) {
      event.stopPropagation(); // Prevent expanding the row when CMD/CTRL key is pressed
      if (onClick) {
        onClick(event); // Propagate the click for highlighting logic
      }
    } else {
      // Expand/Contract row on a regular click
      setIsExpanded((prev) => !prev);
    }
  };

  return (
    <div
      id={`recording-row-${id}`}
      className={`relative cursor-pointer group ${
        isHighlighted
          ? "bg-primary-verylight hover:bg-primary-light"
          : "hover:bg-primary-verylight"
      }`}
      onClick={handleRowClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onContextMenu={onContextMenu}
    >
      <div className='p-4 flex items-center relative'>
        {/* Timeline and Select Button */}
        <div className='absolute left-4 top-0 bottom-0 flex items-center'>
          <div className='w-0.5 bg-gray-400 h-full relative'>
            {isSelectable ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onSelectToggle();
                }}
                className='absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 w-5 h-5 bg-transparent rounded-full flex items-center justify-center'
              >
                {isSelected ? (
                  <FaCheckCircle
                    className='text-[#1F75FE] bg-white rounded-full'
                    style={{ backgroundColor: "white" }}
                  />
                ) : (
                  <FaCircle
                    className='text-white bg-white rounded-full'
                    style={{
                      border: "1px solid gray",
                      boxSizing: "border-box",
                    }}
                  />
                )}
              </button>
            ) : (
              <FaCircle
                className='absolute left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2 text-black'
                style={{ fontSize: "25%", fill: "black" }}
              />
            )}
          </div>
        </div>
        {/* Camcorder Icon */}
        <FaVideo className='text-gray-600 dark:text-gray-400 mr-4 ml-8' />{" "}
        {/* Added extra margin to compensate for timeline */}
        {/* User ID */}
        <span className='text-sm text-gray-600 dark:text-gray-400'>
          {userId || "{unknownUser}"}
        </span>
        {/* Duration */}
        <span className='text-sm font-medium dark:text-gray-200 ml-4'>
          {duration}
        </span>
        {/* Start Time */}
        <span className='text-sm font-medium dark:text-gray-200 ml-4'>
          {startTime}
        </span>
        {/* Device Type Icon */}
        <div className='ml-auto mr-4'>
          {deviceType === "desktop" ? (
            <FaDesktop className='text-gray-600 dark:text-gray-400' />
          ) : (
            <FaMobileAlt className='text-gray-600 dark:text-gray-400' />
          )}
        </div>
        {/* Expand/Collapse Button */}
        <button className='text-lg focus:outline-none' onClick={handleRowClick}>
          {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
        </button>
      </div>

      {/* Expanded Section */}
      {isExpanded && (
        <div
          className={`relative ${
            isHighlighted
              ? "bg-primary-verylight hover:bg-primary-light"
              : "hover:bg-primary-verylight"
          }`}
        >
          {/* Vertical continuation line */}
          <div className='absolute left-4 top-0 bottom-0 w-0.5 bg-gray-400'></div>

          {/* Boxed table content */}
          <div className='pl-8 pr-4 py-4'>
            <div className='border rounded-md bg-white dark:bg-gray-900 p-4'>
              <table className='table-auto w-full text-sm'>
                <thead>
                  <tr className='text-left'>
                    <th className='pl-2 w-1/10'>Actions</th>
                    <th className='pl-2 w-1/6'>Fields</th>
                    <th className='pl-2 w-auto'>Values</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(fields).map(([key, value]) => (
                    <tr
                      key={key}
                      className='text-left hover:bg-primary-light dark:hover:bg-gray-700'
                    >
                      <td className='pl-2 w-1/10'>
                        <div className='flex space-x-2'>
                          <div className='flex space-x-2'>
                            <button className='focus:outline-none'>
                              <AiOutlineZoomIn />
                            </button>
                            <button className='focus:outline-none'>
                              <AiOutlineZoomOut />
                            </button>
                            <button className='focus:outline-none'>
                              <IoEyeOutline />
                            </button>
                            <button className='focus:outline-none'>
                              <BsGraphUp />
                            </button>
                          </div>
                        </div>
                      </td>
                      <td className='text-gray-800 pl-2 w-1/6 dark:text-gray-300'>
                        {key}
                      </td>
                      <td className='text-gray-600 pl-2 break-all w-auto dark:text-gray-400'>
                        {value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecordingRow;
