import { gql } from "@apollo/client";

export const GET_LATEST_BANNER_ANNOUNCEMENT = gql`
  query GetLatestBannerAnnouncement {
    latestBannerAnnouncement {
      id
      content
      createdAt
      type
    }
  }
`;

export const GET_SECTION_ANNOUNCEMENTS = gql`
  query GetSectionAnnouncements($page: Int!, $pageSize: Int!) {
    allAnnouncements(page: $page, pageSize: $pageSize) {
      announcements {
        id
        content
        createdAt
        type
      }
      page
      pageSize
      totalPages
      totalItems
    }
  }
`;

export const DISMISS_ANNOUNCEMENT = gql`
  mutation DismissAnnouncement($announcementId: Int!) {
    dismissAnnouncement(announcementId: $announcementId)
  }
`;
