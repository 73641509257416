import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";

interface SearchResultsNavListProps {
  page: number;
  totalItemsCount: number;
  itemsPerPage: number;
  setPage: (page: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
}

const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50, 100];

const SearchResultsNavList: React.FC<SearchResultsNavListProps> = ({
  page,
  totalItemsCount,
  itemsPerPage,
  setPage,
  setItemsPerPage,
}) => {
  return (
    <Box
      className='bottom-0 left-0 right-0 bg-white border-t-2 border-slate-200'
      p={2}
    >
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        flexGrow={1}
        sx={{ position: "relative" }}
      >
        <Button disabled={page <= 1} onClick={() => setPage(page - 1)}>
          Previous
        </Button>
        <Box display='flex' alignItems='center' mx={2}>
          <span>Page </span>
          <Tooltip title={`Page ${page}`} open={false} placement='top'>
            <TextField
              value={page.toString()}
              onChange={(e) => setPage(Number(e.target.value))}
              size='small'
              inputProps={{
                style: {
                  textAlign: "center",
                  padding: "0",
                  height: "30px",
                  width: "50px",
                },
              }}
              style={{
                verticalAlign: "middle",
                margin: "0 8px",
                height: "30px",
                width: "50px",
              }}
            />
          </Tooltip>
          <span> of {Math.ceil(totalItemsCount / itemsPerPage)}</span>
        </Box>
        <Select
          value={itemsPerPage}
          onChange={(event) => setItemsPerPage(event.target.value as number)}
          size='small'
          style={{ marginLeft: "16px" }}
        >
          {ITEMS_PER_PAGE_OPTIONS.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Button
          disabled={page >= Math.ceil(totalItemsCount / itemsPerPage)}
          onClick={() => setPage(page + 1)}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default SearchResultsNavList;
