import React, { useEffect, useState } from "react";

interface Props {
  idsX: string[];
  idsY: string[];
  initialClassName: string;
  children: React.ReactNode;
  minHeight?: string;
  minWidth?: string;
}

const RemainingAreaContainer: React.FC<Props> = ({
  idsX,
  idsY,
  initialClassName,
  children,
  minHeight = "400px",
  minWidth = "300px",
}) => {
  const [remainingHeightStyle, setRemainingHeightStyle] = useState("");
  const [remainingWidthStyle, setRemainingWidthStyle] = useState("");

  useEffect(() => {
    const calcSectionInitialHeight = () => {
      const element = document.getElementById("mainAppPagesSection");
      if (!element) return 0;

      const style = window.getComputedStyle(element);
      const height = element.clientHeight;
      const paddingTop = parseInt(style.paddingTop, 10);
      const paddingBottom = parseInt(style.paddingBottom, 10);

      return height - paddingTop - paddingBottom || 0;
    };

    const calcSectionInitialWidth = () => {
      const element = document.getElementById("mainAppPagesSection");
      if (!element) return 0;

      const style = window.getComputedStyle(element);
      const width = element.clientWidth;
      const paddingLeft = parseInt(style.paddingLeft, 10);
      const paddingRight = parseInt(style.paddingRight, 10);

      return width - paddingLeft - paddingRight || 0;
    };

    const calculateRemainingHeight = () => {
      const totalHeight = idsY.reduce((total, id) => {
        const element = document.getElementById(id);
        return total + (element?.clientHeight || 0);
      }, 0);
      setRemainingHeightStyle(
        `calc(${calcSectionInitialHeight()}px - ${totalHeight}px)`,
      );
    };

    const calculateRemainingWidth = () => {
      const totalWidth = idsX.reduce((total, id) => {
        const element = document.getElementById(id);
        return total + (element?.clientWidth || 0);
      }, 0);
      setRemainingWidthStyle(
        `calc(${calcSectionInitialWidth()}px - ${totalWidth}px)`,
      );
    };

    calculateRemainingHeight();
    calculateRemainingWidth();

    window.addEventListener("resize", calculateRemainingHeight);
    window.addEventListener("resize", calculateRemainingWidth);

    return () => {
      window.removeEventListener("resize", calculateRemainingHeight);
      window.removeEventListener("resize", calculateRemainingWidth);
    };
  }, [idsX, idsY]);

  return (
    <div
      style={{
        height: remainingHeightStyle,
        width: remainingWidthStyle,
        minHeight,
        minWidth,
      }}
      className={`${initialClassName} overflow-y-auto remainingAreaContainer`}
    >
      {children}
    </div>
  );
};

export default RemainingAreaContainer;
