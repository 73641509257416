import React, { useEffect, useRef } from "react";
import { ExceptionRecordType } from "../../__generated__/graphql";

const ExceptionView: React.FC<ExceptionRecordType> = ({
  timestampMs,
  exceptionMessage,
  traceStack,
}) => {
  const offenderRef = useRef<HTMLLIElement | null>(null); // Ref for the offender element
  const containerRef = useRef<HTMLDivElement | null>(null); // Ref for the scrollable container

  const formatDate = (ms: string) => new Date(parseInt(ms)).toLocaleString();

  const isLessProminent = (file: string) =>
    file.includes("/site-packages/") || file.includes("/dist-packages/");

  // Make a copy of the trace stack to ensure it's mutable
  const mutableTraceStack = traceStack.map((trace) => ({ ...trace }));

  // Check if any frame is already marked as the offender
  const hasOffender = mutableTraceStack.some((trace) => trace.offender);

  // If no offender is marked, set the top frame (index 0) as the offender
  if (!hasOffender && mutableTraceStack.length > 0) {
    mutableTraceStack[0].offender = true;
  }

  useEffect(() => {
    // Scroll only the container (not the full page) to bring the offender into view
    if (offenderRef.current && containerRef.current) {
      containerRef.current.scrollTo({
        top: offenderRef.current.offsetTop - containerRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, []); // Empty dependency array ensures it runs after initial render

  return (
    <div
      // className='exception-view mb-3'
      className='exception-view p-4 border rounded-md'
      ref={containerRef} // Ref for the container that will scroll
      style={{ maxHeight: "500px", overflowY: "auto" }} // Define scrollable container
    >
      <h2 className='text-lg font-semibold'>Exception Details</h2>
      <p>
        <strong>Occurred at:</strong> {formatDate(timestampMs)}
      </p>
      <p>
        <strong>Message:</strong> {exceptionMessage}
      </p>

      <div className='timeline mt-6'>
        <h3 className='font-semibold mb-2'>Trace Stack</h3>
        <ul className='relative border-l-2 border-gray-300 pl-6'>
          {mutableTraceStack
            .slice()
            .reverse()
            .map((trace, index) => {
              const lessProminent = isLessProminent(trace.file);
              const isOffender = trace.offender;

              return (
                <li
                  key={index}
                  ref={isOffender ? offenderRef : null} // Attach the ref to the offender element
                  className={`mb-8 last:mb-0 ml-4 relative p-4 border rounded-lg ${
                    isOffender
                      ? "shadow-xl shadow-slate-400 font-bold border-2 border-red-500 bg-red-200 text-black"
                      : lessProminent
                      ? "shadow-sm shadow-slate-300 text-gray-500 border-gray-300 bg-gray-50"
                      : "shadow-md shadow-slate-500 text-gray-950 border-gray-500 bg-white"
                  }`}
                >
                  {/* Dot representing trace stack */}
                  <div
                    className={`absolute w-3 h-3 rounded-full ${
                      isOffender
                        ? "bg-red-500"
                        : lessProminent
                        ? "bg-slate-300"
                        : "bg-gray-500"
                    }`}
                    style={{
                      left: "-3rem", // Centers the dot on the vertical line
                      top: "1.25rem", // Adjust vertical alignment
                    }}
                  ></div>

                  {/* Horizontal line from dot to the box */}
                  <div
                    className={`absolute ${
                      isOffender
                        ? "bg-red-500"
                        : lessProminent
                        ? "bg-slate-300"
                        : "bg-gray-500"
                    }`}
                    style={{
                      height: "2px", // Line thickness
                      width: "2.75rem", // Line width from dot to box
                      top: "1.54rem", // Same vertical alignment as the dot
                      left: "-2.75rem", // Starts at the dot
                    }}
                  ></div>

                  <p className='text-sm mb-1'>
                    <strong>File:</strong> {trace.file}:{trace.line}
                  </p>
                  <p className='text-sm mb-1'>
                    <strong>Function:</strong> {trace.function}
                  </p>
                  <pre
                    className={`p-2 rounded-md ${
                      isOffender
                        ? "border-2 border-red-500 bg-red-300"
                        : "border border-gray-300 bg-sky-50"
                    }`}
                  >
                    {trace.code}
                  </pre>

                  {/* Display locals in a table for proper alignment */}
                  {trace.locals && Object.keys(trace.locals).length > 0 && (
                    <div className='text-sm mt-4 w-full'>
                      <table className='table-auto w-full'>
                        <thead>
                          <tr className='font-bold text-left'>
                            <th className='w-auto pr-4 whitespace-nowrap'>
                              Variable
                            </th>
                            <th className='w-full'>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(trace.locals).map(([key, value]) => (
                            <tr key={key} className='border-t border-gray-200'>
                              {/* Adjusted font color to match frame prominence */}
                              <td
                                className={`font-medium pr-10 whitespace-nowrap ${
                                  isOffender
                                    ? "text-black"
                                    : lessProminent
                                    ? "text-gray-500"
                                    : "text-gray-950"
                                }`}
                              >
                                {key}
                              </td>
                              <td
                                className={`truncate whitespace-pre-wrap hover:overflow-visible hover:max-h-full max-h-10 overflow-hidden relative group ${
                                  isOffender
                                    ? "text-black"
                                    : lessProminent
                                    ? "text-gray-500"
                                    : "text-gray-950"
                                }`}
                              >
                                <span className='group-hover:overflow-visible'>
                                  {String(value)}
                                </span>
                                <span className='absolute opacity-0'>
                                  {String(value)}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default ExceptionView;
