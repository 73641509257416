import { ApolloProvider } from "@apollo/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { startRecording } from "@sailfish/recorder";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "video.js/dist/video-js.css";
import App from "./App";
import client from "./apolloClient";
import { AdminProvider } from "./contexts/AdminContext";
import { AuthProvider } from "./contexts/AuthContext"; // Ensure the path to AuthContext is correct
import { CompanyProvider } from "./contexts/CompanyContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const namespace = import.meta.env.VITE_NAMESPACE;
if (namespace === "production") {
  startRecording({
    apiKey: "76f9d63f-6fbb-4d4a-a39b-7a7f40e5fbb1",
    backendApi: "https://api-service.sailfishqa.com",
  });
} else {
  startRecording({
    apiKey: "e66bfbc9-c069-4591-bac6-605b0116b7eb",
    backendApi: "http://localhost:8000",
    // domainsToNotPropagateHeaderTo: ["localhost:9000"],
    // domainsToPropagateHeaderTo: ["localhost:8000"],
  });
}

const googleClientId =
  "750193212835-cjq1bsveacmqoa1a9mdc9lde874gpuc0.apps.googleusercontent.com";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <div>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={googleClientId}>
        <ApolloProvider client={client}>
          <AuthProvider>
            <CompanyProvider>
              <AdminProvider>
                <App />
              </AdminProvider>
            </CompanyProvider>
          </AuthProvider>
        </ApolloProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </div>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
