import React, { ReactNode, createContext, useContext, useState } from "react";

interface AdminContextType {
  isAdminEnabled: boolean;
  setIsAdminEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AdminContext = createContext<AdminContextType | undefined>(
  undefined,
);

export const AdminProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAdminEnabled, setIsAdminEnabled] = useState(() => {
    const storedIsAdminEnabled = localStorage.getItem("isAdminEnabled");
    return storedIsAdminEnabled ? JSON.parse(storedIsAdminEnabled) : false;
  });

  return (
    <AdminContext.Provider value={{ isAdminEnabled, setIsAdminEnabled }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = (): AdminContextType => {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
};
