import React, { useState } from "react";
import CreatableSelect, { CreatableProps } from "react-select/creatable";

interface EntityTextBoxProps extends CreatableProps<any, true, any> {
  onChange: (value: any) => void;
  value: Array<{ value: string; label: string }>;
}

const EntityTextBox: React.FC<EntityTextBoxProps> = ({
  onChange,
  value,
  components,
  styles,
  ...restProps
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (["Enter", " ", "Tab", ","].includes(event.key)) {
      event.preventDefault();
      const trimmedInput = inputValue.trim();
      if (trimmedInput) {
        onChange([...value, { value: trimmedInput, label: trimmedInput }]);
        setInputValue(""); // Clear the input after accepting the value
      }
    }
  };

  const handleInputChange = (newValue: string) => {
    setInputValue(newValue);
  };

  return (
    <CreatableSelect
      isMulti
      value={value}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={onChange}
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        ...components,
      }}
      styles={{
        menu: (provided) => ({
          ...provided,
          display: "none",
        }),
        control: (provided) => ({
          ...provided,
          minHeight: "48px",
          borderRadius: "0.5rem",
          borderColor: "#d1d5db",
          "&:hover": {
            borderColor: "#9ca3af",
          },
        }),
        ...styles,
      }}
      onKeyDown={handleKeyDown}
      {...restProps}
    />
  );
};

export default EntityTextBox;
