import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useDraggable,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { Tooltip } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import ApplicationPage from "../components/ApplicationPage";
import RemainingHeightContainer from "../components/RemainingHeightContainer";

type Block = {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

const GRID_SIZE = 100; // Fixed 100x100 grid cells

// Custom Pointer Sensor to prevent dragging from certain elements
class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown" as const,
      handler: ({ nativeEvent }: any) => {
        // Check if the event target or any of its parents has data-no-drag
        let element = nativeEvent.target as HTMLElement | null;
        while (element) {
          if (element.dataset && element.dataset.noDrag === "true") {
            return false; // Do not activate dragging
          }
          element = element.parentElement;
        }
        return true; // Activate dragging
      },
    },
  ];
}

const CreateAndEditDashboardPage = () => {
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [hoverBlock, setHoverBlock] = useState<{ x: number; y: number } | null>(
    null,
  );
  const [blockCounter, setBlockCounter] = useState(1); // To ensure unique block IDs
  const [isDraggingNewBlock, setIsDraggingNewBlock] = useState(false);
  const [dragBlockPreview, setDragBlockPreview] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
  } | null>(null);
  const dragStartPos = useRef<{ x: number; y: number } | null>(null);

  // Resizing state using refs
  const resizingBlockIdRef = useRef<number | null>(null);
  const resizingDirectionRef = useRef<string | null>(null);
  const initialMousePosRef = useRef<{ x: number; y: number } | null>(null);
  const initialBlockStateRef = useRef<Block | null>(null);
  const resizingPreviewBlockRef = useRef<Block | null>(null);
  const [resizingPreviewVersion, setResizingPreviewVersion] = useState(0);

  // Dragging state
  const draggingBlockIdRef = useRef<number | null>(null);
  const initialDragBlockStateRef = useRef<Block | null>(null);
  const [draggingPreviewVersion, setDraggingPreviewVersion] = useState(0);

  // Add block at a specific grid position
  const addBlock = (
    x: number,
    y: number,
    width: number = 1,
    height: number = 1,
  ) => {
    const newBlock: Block = {
      id: blockCounter, // Ensure unique block IDs
      x,
      y,
      width,
      height,
    };
    setBlocks((prevBlocks) => [...prevBlocks, newBlock]);
    setBlockCounter((prev) => prev + 1); // Increment block counter for unique IDs
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    const gridRect = e.currentTarget.getBoundingClientRect();
    const x = Math.floor((e.clientX - gridRect.left) / GRID_SIZE);
    const y = Math.floor((e.clientY - gridRect.top) / GRID_SIZE);

    // Handle block preview while dragging
    if (isDraggingNewBlock && dragStartPos.current) {
      const width = Math.abs(x - dragStartPos.current.x) + 1;
      const height = Math.abs(y - dragStartPos.current.y) + 1;
      setDragBlockPreview({
        x: Math.min(x, dragStartPos.current.x),
        y: Math.min(y, dragStartPos.current.y),
        width,
        height,
      });
    } else {
      // Handle hover preview for single block placement
      setHoverBlock({ x, y });
    }
  };

  const handleMouseLeave = () => {
    setHoverBlock(null);
  };

  // Handle click and drag to create larger blocks
  const handleMouseDown = (e: React.MouseEvent) => {
    const gridRect = e.currentTarget.getBoundingClientRect();
    const x = Math.floor((e.clientX - gridRect.left) / GRID_SIZE);
    const y = Math.floor((e.clientY - gridRect.top) / GRID_SIZE);

    // Only start dragging if the click happens in an empty space, not on a block
    if (!blocks.some((block) => isWithinBlock(block, x, y))) {
      dragStartPos.current = { x, y };
      setIsDraggingNewBlock(true);
      setDragBlockPreview({ x, y, width: 1, height: 1 }); // Initial preview size
    }
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (dragStartPos.current && dragBlockPreview) {
      const { x, y, width, height } = dragBlockPreview;
      addBlock(x, y, width, height);
    }
    // Reset dragging state
    dragStartPos.current = null;
    setIsDraggingNewBlock(false);
    setDragBlockPreview(null);
  };

  // Helper function to check if the coordinates are inside an existing block
  const isWithinBlock = (block: Block, x: number, y: number) => {
    return (
      x >= block.x &&
      x < block.x + block.width &&
      y >= block.y &&
      y < block.y + block.height
    );
  };

  // Handle the block drag start event
  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const activeId = active.id as number;
    draggingBlockIdRef.current = activeId;

    const block = blocks.find((b) => b.id === activeId);
    if (block) {
      initialDragBlockStateRef.current = { ...block };
    }

    window.addEventListener("keydown", handleKeyDown);
  };

  // Handle the block drop event to update block position
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, delta } = event;
    const activeId = active.id as number;

    // If the drag was not cancelled, update the block's position
    if (initialDragBlockStateRef.current) {
      setBlocks((prevBlocks) =>
        prevBlocks.map((block) => {
          if (block.id === activeId) {
            const newX = block.x + Math.round(delta.x / GRID_SIZE);
            const newY = block.y + Math.round(delta.y / GRID_SIZE);
            return { ...block, x: newX, y: newY };
          }
          return block;
        }),
      );
    }

    draggingBlockIdRef.current = null;
    initialDragBlockStateRef.current = null;
    setDraggingPreviewVersion((prev) => prev + 1);
    window.removeEventListener("keydown", handleKeyDown);
  };

  // Function to check if a block is fully covered by any other block
  const isBlockCovered = (blockToCheck: Block, allBlocks: Block[]) => {
    return allBlocks.some((otherBlock) => {
      if (otherBlock.id === blockToCheck.id) return false; // Skip the same block
      return (
        otherBlock.x <= blockToCheck.x &&
        otherBlock.y <= blockToCheck.y &&
        otherBlock.x + otherBlock.width >=
          blockToCheck.x + blockToCheck.width &&
        otherBlock.y + otherBlock.height >= blockToCheck.y + blockToCheck.height
      );
    });
  };

  // Determine which blocks are fully covered by others
  const coveredBlockIds = blocks
    .filter((block) => isBlockCovered(block, blocks))
    .map((block) => block.id);

  // Handle resizing start
  const handleResizeStart = (
    e: React.MouseEvent,
    blockId: number,
    direction: string,
  ) => {
    e.stopPropagation(); // Prevent triggering drag events
    e.preventDefault(); // Prevent default behavior

    resizingBlockIdRef.current = blockId;
    resizingDirectionRef.current = direction;
    initialMousePosRef.current = { x: e.clientX, y: e.clientY };

    const block = blocks.find((b) => b.id === blockId);
    if (block) {
      initialBlockStateRef.current = { ...block };
      resizingPreviewBlockRef.current = { ...block }; // Initialize the preview block
    }

    setResizingPreviewVersion((prev) => prev + 1);

    window.addEventListener("keydown", handleKeyDown);
  };

  // Resizing effect
  useEffect(() => {
    if (resizingBlockIdRef.current !== null) {
      const handleWindowMouseMove = (e: MouseEvent) => {
        if (
          resizingBlockIdRef.current !== null &&
          resizingDirectionRef.current &&
          initialMousePosRef.current &&
          initialBlockStateRef.current
        ) {
          const deltaX = e.clientX - initialMousePosRef.current.x;
          const deltaY = e.clientY - initialMousePosRef.current.y;

          let deltaGridX = Math.round(deltaX / GRID_SIZE);
          let deltaGridY = Math.round(deltaY / GRID_SIZE);

          let newBlockState = { ...initialBlockStateRef.current };

          // Handle resizing based on direction
          switch (resizingDirectionRef.current) {
            case "right":
              // Limit deltaGridX so that width doesn't go below 1
              deltaGridX = Math.max(
                -(initialBlockStateRef.current.width - 1),
                deltaGridX,
              );
              newBlockState.width =
                initialBlockStateRef.current.width + deltaGridX;
              break;
            case "left":
              deltaGridX = Math.min(
                initialBlockStateRef.current.width - 1,
                deltaGridX,
              );
              deltaGridX = Math.max(
                -initialBlockStateRef.current.x,
                deltaGridX,
              ); // Prevent x from going negative
              newBlockState.x = initialBlockStateRef.current.x + deltaGridX;
              newBlockState.width =
                initialBlockStateRef.current.width - deltaGridX;
              break;
            case "bottom":
              deltaGridY = Math.max(
                -(initialBlockStateRef.current.height - 1),
                deltaGridY,
              );
              newBlockState.height =
                initialBlockStateRef.current.height + deltaGridY;
              break;
            case "top":
              deltaGridY = Math.min(
                initialBlockStateRef.current.height - 1,
                deltaGridY,
              );
              deltaGridY = Math.max(
                -initialBlockStateRef.current.y,
                deltaGridY,
              ); // Prevent y from going negative
              newBlockState.y = initialBlockStateRef.current.y + deltaGridY;
              newBlockState.height =
                initialBlockStateRef.current.height - deltaGridY;
              break;
            case "top-left":
              // Left side
              deltaGridX = Math.min(
                initialBlockStateRef.current.width - 1,
                deltaGridX,
              );
              deltaGridX = Math.max(
                -initialBlockStateRef.current.x,
                deltaGridX,
              );
              newBlockState.x = initialBlockStateRef.current.x + deltaGridX;
              newBlockState.width =
                initialBlockStateRef.current.width - deltaGridX;

              // Top side
              deltaGridY = Math.min(
                initialBlockStateRef.current.height - 1,
                deltaGridY,
              );
              deltaGridY = Math.max(
                -initialBlockStateRef.current.y,
                deltaGridY,
              );
              newBlockState.y = initialBlockStateRef.current.y + deltaGridY;
              newBlockState.height =
                initialBlockStateRef.current.height - deltaGridY;
              break;
            case "top-right":
              // Right side
              deltaGridX = Math.max(
                -(initialBlockStateRef.current.width - 1),
                deltaGridX,
              );
              newBlockState.width =
                initialBlockStateRef.current.width + deltaGridX;

              // Top side
              deltaGridY = Math.min(
                initialBlockStateRef.current.height - 1,
                deltaGridY,
              );
              deltaGridY = Math.max(
                -initialBlockStateRef.current.y,
                deltaGridY,
              );
              newBlockState.y = initialBlockStateRef.current.y + deltaGridY;
              newBlockState.height =
                initialBlockStateRef.current.height - deltaGridY;
              break;
            case "bottom-left":
              // Left side
              deltaGridX = Math.min(
                initialBlockStateRef.current.width - 1,
                deltaGridX,
              );
              deltaGridX = Math.max(
                -initialBlockStateRef.current.x,
                deltaGridX,
              );
              newBlockState.x = initialBlockStateRef.current.x + deltaGridX;
              newBlockState.width =
                initialBlockStateRef.current.width - deltaGridX;

              // Bottom side
              deltaGridY = Math.max(
                -(initialBlockStateRef.current.height - 1),
                deltaGridY,
              );
              newBlockState.height =
                initialBlockStateRef.current.height + deltaGridY;
              break;
            case "bottom-right":
              // Right side
              deltaGridX = Math.max(
                -(initialBlockStateRef.current.width - 1),
                deltaGridX,
              );
              newBlockState.width =
                initialBlockStateRef.current.width + deltaGridX;

              // Bottom side
              deltaGridY = Math.max(
                -(initialBlockStateRef.current.height - 1),
                deltaGridY,
              );
              newBlockState.height =
                initialBlockStateRef.current.height + deltaGridY;
              break;
            default:
              break;
          }

          // Update the resizing preview block
          resizingPreviewBlockRef.current = newBlockState;
          setResizingPreviewVersion((prev) => prev + 1);
        }
      };

      const handleWindowMouseUp = (e: MouseEvent) => {
        if (
          resizingBlockIdRef.current !== null &&
          resizingPreviewBlockRef.current
        ) {
          // Update the block in blocks state with the final resized state
          setBlocks((prevBlocks) =>
            prevBlocks.map((block) =>
              block.id === resizingBlockIdRef.current
                ? resizingPreviewBlockRef.current!
                : block,
            ),
          );

          // Reset resizing state
          resizingBlockIdRef.current = null;
          resizingDirectionRef.current = null;
          initialMousePosRef.current = null;
          initialBlockStateRef.current = null;
          resizingPreviewBlockRef.current = null;

          setResizingPreviewVersion((prev) => prev + 1);

          window.removeEventListener("mousemove", handleWindowMouseMove);
          window.removeEventListener("mouseup", handleWindowMouseUp);
          window.removeEventListener("keydown", handleKeyDown);
        }
      };

      window.addEventListener("mousemove", handleWindowMouseMove);
      window.addEventListener("mouseup", handleWindowMouseUp);

      return () => {
        window.removeEventListener("mousemove", handleWindowMouseMove);
        window.removeEventListener("mouseup", handleWindowMouseUp);
      };
    }
  }, [resizingPreviewVersion]);

  // Handle ESC key to cancel dragging or resizing
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      if (
        draggingBlockIdRef.current !== null &&
        initialDragBlockStateRef.current
      ) {
        // Revert the block to its initial position
        setBlocks((prevBlocks) =>
          prevBlocks.map((block) =>
            block.id === draggingBlockIdRef.current
              ? initialDragBlockStateRef.current!
              : block,
          ),
        );
        // End the drag
        draggingBlockIdRef.current = null;
        initialDragBlockStateRef.current = null;
        setDraggingPreviewVersion((prev) => prev + 1);
        window.removeEventListener("keydown", handleKeyDown);
      }
      if (resizingBlockIdRef.current !== null) {
        // Cancel resizing
        resizingBlockIdRef.current = null;
        resizingDirectionRef.current = null;
        initialMousePosRef.current = null;
        initialBlockStateRef.current = null;
        resizingPreviewBlockRef.current = null;

        setResizingPreviewVersion((prev) => prev + 1);

        window.removeEventListener("keydown", handleKeyDown);
      }
    }
  };

  // Initialize the custom sensor
  const customSensor = useSensor(CustomPointerSensor);
  const sensors = useSensors(customSensor);

  return (
    <ApplicationPage pageName='Dashboard'>
      <RemainingHeightContainer
        ids={["applicationPageHeader"]}
        initialClassName='bg-sky-50 rounded-lg'
        minHeight='400px'
        onHeightChange={() => {}}
      >
        <div className='flex h-full'>
          {/* Sidebar */}
          <div className='w-1/6 bg-sky-100 p-4'>Filters</div>

          {/* Main Grid Area */}
          <DndContext
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            sensors={sensors}
          >
            <div
              className='w-5/6 p-4 relative'
              style={{
                backgroundSize: `${GRID_SIZE}px ${GRID_SIZE}px`,
                backgroundImage:
                  "radial-gradient(circle, #ccc 2px, transparent 1px)",
              }}
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              {/* Hover Preview for new block placement */}
              {hoverBlock && !isDraggingNewBlock && (
                <div
                  className='absolute bg-blue-200 opacity-50 rounded-lg'
                  style={{
                    width: `${GRID_SIZE}px`,
                    height: `${GRID_SIZE}px`,
                    top: `${hoverBlock.y * GRID_SIZE}px`,
                    left: `${hoverBlock.x * GRID_SIZE}px`,
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  }}
                />
              )}

              {/* Drag-and-Drop Block Preview */}
              {dragBlockPreview && (
                <div
                  className='absolute bg-green-200 opacity-50 rounded-lg'
                  style={{
                    width: `${dragBlockPreview.width * GRID_SIZE}px`,
                    height: `${dragBlockPreview.height * GRID_SIZE}px`,
                    top: `${dragBlockPreview.y * GRID_SIZE}px`,
                    left: `${dragBlockPreview.x * GRID_SIZE}px`,
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                  }}
                />
              )}

              {blocks.map((block) => (
                <DraggableBlock
                  key={block.id}
                  block={block}
                  isCovered={coveredBlockIds.includes(block.id)}
                  onResizeStart={handleResizeStart}
                  isResizing={resizingBlockIdRef.current === block.id}
                  isDragging={draggingBlockIdRef.current === block.id}
                  resizingPreviewBlock={
                    resizingPreviewBlockRef.current &&
                    resizingPreviewBlockRef.current.id === block.id
                      ? resizingPreviewBlockRef.current
                      : null
                  }
                />
              ))}
            </div>
          </DndContext>
        </div>
      </RemainingHeightContainer>
    </ApplicationPage>
  );
};

// Draggable block component with resize handles
const DraggableBlock = ({
  block,
  isCovered,
  onResizeStart,
  isResizing,
  isDragging,
  resizingPreviewBlock,
}: {
  block: Block;
  isCovered: boolean;
  onResizeStart: (
    e: React.MouseEvent,
    blockId: number,
    direction: string,
  ) => void;
  isResizing: boolean;
  isDragging: boolean;
  resizingPreviewBlock: Block | null;
}) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: block.id,
  });

  // Use the preview block if resizing
  const effectiveBlock = resizingPreviewBlock || block;

  // Calculate the grid snap during drag
  const translateX =
    isDragging && transform
      ? Math.round(transform.x / GRID_SIZE) * GRID_SIZE
      : 0;
  const translateY =
    isDragging && transform
      ? Math.round(transform.y / GRID_SIZE) * GRID_SIZE
      : 0;

  const style = {
    transform: `translate(${translateX}px, ${translateY}px)`,
    width: `${effectiveBlock.width * GRID_SIZE}px`,
    height: `${effectiveBlock.height * GRID_SIZE}px`,
    top: `${effectiveBlock.y * GRID_SIZE}px`,
    left: `${effectiveBlock.x * GRID_SIZE}px`,
    // position: "absolute",
    zIndex: isCovered ? 1 : 0, // Increase z-index if the block is covered
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={`${
        isDragging || isResizing
          ? "border-dashed border-2 border-blue-500"
          : "bg-white border"
      } rounded shadow-lg relative`}
      style={style}
    >
      {(isDragging || isResizing) && (
        <div className='absolute inset-0 bg-blue-100 opacity-50 rounded-lg'></div>
      )}
      {/* Content */}
      <div className='p-2 flex flex-col items-center justify-center space-y-8 h-full text-lg'>
        <Autocomplete
          options={[
            "Pie chart we created",
            "Bar chart that exists",
            "Geo/Choropleth insights plot we did",
          ]}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Select a chart'
              variant='outlined'
              fullWidth
            />
          )}
          onChange={(event, value) => {
            console.log(`Clicked on AutoComplete option "${value}"`);
          }}
          className='w-4/5 max-w-96 h-12'
          data-no-drag='true'
          // Removed disablePortal to allow options to display without width restrictions
        />
        <Tooltip title='Create new chart' placement='right'>
          <button
            onClick={(e) => {
              console.log('Clicked on "+ Create new chart" button');
            }}
            className='px-4 py-2 bg-blue-500 text-white rounded w-2/5 max-w-64 h-12 overflow-hidden whitespace-nowrap'
            data-no-drag='true'
          >
            +<span className='ml-1 create-chart-text'>Create new chart</span>
          </button>
        </Tooltip>
      </div>

      {/* Resize Handles */}
      {/* Right */}
      <div
        className='absolute top-0 right-0 w-2 h-full cursor-e-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "right");
        }}
        style={{ transform: "translateX(50%)" }}
      />
      {/* Left */}
      <div
        className='absolute top-0 left-0 w-2 h-full cursor-w-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "left");
        }}
        style={{ transform: "translateX(-50%)" }}
      />
      {/* Bottom */}
      <div
        className='absolute bottom-0 left-0 w-full h-2 cursor-s-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "bottom");
        }}
        style={{ transform: "translateY(50%)" }}
      />
      {/* Top */}
      <div
        className='absolute top-0 left-0 w-full h-2 cursor-n-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "top");
        }}
        style={{ transform: "translateY(-50%)" }}
      />
      {/* Top-Left */}
      <div
        className='absolute top-0 left-0 w-4 h-4 cursor-nw-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "top-left");
        }}
        style={{ transform: "translate(-50%, -50%)" }}
      />
      {/* Top-Right */}
      <div
        className='absolute top-0 right-0 w-4 h-4 cursor-ne-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "top-right");
        }}
        style={{ transform: "translate(50%, -50%)" }}
      />
      {/* Bottom-Left */}
      <div
        className='absolute bottom-0 left-0 w-4 h-4 cursor-sw-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "bottom-left");
        }}
        style={{ transform: "translate(-50%, 50%)" }}
      />
      {/* Bottom-Right */}
      <div
        className='absolute bottom-0 right-0 w-4 h-4 cursor-se-resize'
        data-no-drag='true'
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onResizeStart(e, block.id, "bottom-right");
        }}
        style={{ transform: "translate(50%, 50%)" }}
      />
    </div>
  );
};

export default CreateAndEditDashboardPage;
