import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { BsLightningChargeFill } from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import ToggleIconButton from "./ToggleIconButton";

interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: React.ComponentType;
}

interface HighlightedSection {
  startTime: number;
  endTime: number;
  text: string;
  color: string;
}

interface RRWebPlayerProps {
  events: any[];
  timelineEvents: TimelineEvent[];
  highlightedSections: HighlightedSection[];
  width: number;
  height: number;
  initialTime?: number | null;
  onTimeUpdate?: (currentTime: number) => void;
}

const RRWebPlayerWithTriage = forwardRef<any, RRWebPlayerProps>(
  (
    {
      events,
      width,
      height,
      initialTime,
      onTimeUpdate,
      timelineEvents,
      highlightedSections,
    },
    ref,
  ) => {
    const playerRef = useRef<any>(null);
    const playerContainerRef = useRef<HTMLDivElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const [skipInactivity, setSkipInactivity] = useState(false);
    const [hoveredEvent, setHoveredEvent] = useState<TimelineEvent | null>(
      null,
    );
    const [tooltipPosition, setTooltipPosition] = useState<{
      top: number;
      left: number;
    }>({ top: 0, left: 0 });
    const [hideTooltipTimeout, setHideTooltipTimeout] =
      useState<NodeJS.Timeout | null>(null);

    const formatTime = (timeInSeconds: number): string => {
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = Math.floor(timeInSeconds % 60);

      const formattedHours = hours > 0 ? `${hours}:` : "";
      const formattedMinutes =
        minutes < 10 && hours > 0 ? `0${minutes}:` : `${minutes}:`;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

      return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
    };

    const getCurrentTimeString = () => formatTime(currentTime / 1000);
    const getTotalTimeString = () => formatTime(totalTime / 1000);

    useImperativeHandle(ref, () => ({
      play() {
        playerRef.current.play();
        setIsPlaying(true);
      },
      pause() {
        playerRef.current.pause();
        setIsPlaying(false);
      },
      seekTo(time: number) {
        playerRef.current.goto(time);
        setCurrentTime(time);
      },
      resetPlayer() {
        playerRef.current.goto(0);
        playerRef.current.play();
        setCurrentTime(0);
        setIsPlaying(true);
      },
    }));

    useEffect(() => {
      if (playerRef.current) {
        playerRef.current.pause();
      }

      if (!playerRef.current) {
        playerRef.current = new rrwebPlayer({
          target: document.getElementById("rrweb-player")!,
          props: {
            events,
            autoPlay: false,
            showController: false,
            width: width,
            height: height,
          },
        });

        setTotalTime(playerRef.current.getMetaData().totalTime);

        if (initialTime) {
          playerRef.current.goto(initialTime);
        }

        playerRef.current.addEventListener(
          "ui-update-current-time",
          (event: any) => {
            setCurrentTime(event.payload);
          },
        );

        playerRef.current.addEventListener(
          "ui-update-player-state",
          (event: any) => {
            if (event.payload === "paused") {
              setIsPlaying(false);
            }
          },
        );
      } else {
        playerRef.current.$set({
          events,
          width,
          height,
        });
        playerRef.current.triggerResize();

        if (initialTime) {
          playerRef.current.goto(initialTime);
        }
      }
    }, [events, width, height, initialTime]);

    const togglePlayPause = () => {
      if (!isPlaying) {
        playerRef.current.goto(currentTime);
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    };

    const changeSpeed = (speed: number) => {
      setPlaybackSpeed(speed);
      playerRef.current.setSpeed(speed);
    };

    const toggleSkipInactivity = () => {
      playerRef.current.toggleSkipInactive();
      setSkipInactivity((prev) => !prev);
    };

    const handlePlaybackClick = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
      const rect = e.currentTarget.getBoundingClientRect();
      const clickX = e.clientX - rect.left;
      const clickTime = (clickX / rect.width) * totalTime;
      playerRef.current.goto(clickTime);
      setCurrentTime(clickTime);
    };

    const renderHighlightedSections = () => {
      if (!playerRef.current) return null;
      return highlightedSections.map((section: any, index: any) => {
        const startPercent = (section.startTime / totalTime) * 100;
        const endPercent = (section.endTime / totalTime) * 100;
        const widthPercent = endPercent - startPercent;
        return (
          <div
            key={index}
            className='absolute top-0 h-full'
            style={{
              left: `${startPercent}%`,
              width: `${widthPercent}%`,
              backgroundColor: section.color,
            }}
          />
        );
      });
    };

    const handleEventHover = (
      event: TimelineEvent,
      e: React.MouseEvent<HTMLDivElement>,
    ) => {
      if (hideTooltipTimeout) {
        clearTimeout(hideTooltipTimeout);
      }

      const rect = e.currentTarget.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top - 20, // Position it above the event icon
        left: rect.left + rect.width / 2,
      });
      setHoveredEvent(event);
    };

    const handleEventLeave = () => {
      const timeout = setTimeout(() => setHoveredEvent(null), 200);
      setHideTooltipTimeout(timeout);
    };

    const renderTimelineEvents = () => {
      if (!playerRef.current) return null;
      return timelineEvents.map((event, index) => {
        const leftPercent = (event.time / totalTime) * 100;
        return (
          <div
            key={index}
            className='absolute top-0 h-full flex flex-col items-center'
            style={{ left: `${leftPercent}%` }}
            onMouseEnter={(e) => handleEventHover(event, e)}
            onMouseLeave={handleEventLeave}
          >
            <div className='bg-white border rounded-full p-1 shadow'>
              <event.Icon />
            </div>
          </div>
        );
      });
    };

    return (
      <div
        id='rrweb-tile'
        ref={playerContainerRef}
        className='w-full h-full flex flex-col'
      >
        <div id='rrweb-player' className='flex justify-center'></div>
        <div id='rrweb-playback-bar' className='relative mt-8 z-10'>
          <div
            className='relative w-full h-2 bg-gray-200 cursor-pointer'
            onClick={handlePlaybackClick}
          >
            {playerRef.current && (
              <div
                className='absolute top-0 h-full bg-blue-600'
                style={{
                  width: `${(currentTime / totalTime) * 100}%`,
                }}
              />
            )}
            {renderHighlightedSections()}
            {renderTimelineEvents()}
          </div>
          <div className='relative flex items-center justify-center my-2'>
            <div className='flex items-center space-x-4'>
              <ToggleIconButton
                onClick={togglePlayPause}
                size='text-2xl'
                flag={isPlaying}
                trueColor='text-slate-400'
                falseColor='text-green-500'
                icon={FaPlay}
                trueIcon={FaPause}
                falseIcon={FaPlay}
                trueTooltip='Pause'
                falseTooltip='Play'
              />
              <select
                value={playbackSpeed}
                onChange={(e) => changeSpeed(Number(e.target.value))}
                className='btn btn-primary m-2'
              >
                <option value={1}>1x</option>
                <option value={2}>2x</option>
                <option value={4}>4x</option>
              </select>
              <ToggleIconButton
                onClick={toggleSkipInactivity}
                size='text-2xl'
                flag={skipInactivity}
                trueColor='text-yellow-500'
                falseColor='text-slate-400'
                icon={BsLightningChargeFill}
                trueTooltip='Skipping inactivity'
                falseTooltip='Click to skip inactivity'
              />
            </div>
            <div id='time-display' className='flex items-center text-lg mx-6'>
              <span>{getCurrentTimeString()}</span>
              <span className='mx-2'>/</span>
              <span>{getTotalTimeString()}</span>
            </div>
          </div>
        </div>
        {hoveredEvent &&
          ReactDOM.createPortal(
            <div
              className='absolute p-2 bg-black text-white rounded-md z-20'
              style={{
                top:
                  tooltipPosition.top > window.innerHeight
                    ? window.innerHeight - 50
                    : tooltipPosition.top, // Prevent going out of viewport bounds
                left:
                  tooltipPosition.left > window.innerWidth
                    ? window.innerWidth - 50
                    : tooltipPosition.left,
                transform: "translateX(-50%)",
              }}
            >
              {hoveredEvent.text}
            </div>,
            document.body,
          )}
      </div>
    );
  },
);

export default RRWebPlayerWithTriage;
