import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
// import { Helmet } from "react-helmet";
import { BsInputCursorText } from "react-icons/bs";
import { HiCursorClick } from "react-icons/hi";
import { IoMdBrowsers } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import ApplicationPage from "../components/ApplicationPage";
import DashboardTile from "../components/DashboardTile";
import LoadingAnimation from "../components/LoadingAnimation";
import ActionPickerForAccordion from "../components/Menus/ActionPickerForAccordion";
import VSCodeLikeAccordion from "../components/Menus/VSCodeLikeAccordion";
import RecordingsList from "../components/RecordingsList";
import RRWebPlayerWithTriage from "../components/RRWebPlayerWithTriage";
// import SearchBar from "../components/Search/SearchBar"; // Assuming SearchBar component is in the same folder
import { useGetRecordingSessionsLazyQuery } from "../__generated__/graphql";
import client from "../apolloClient";
import SearchComboboxes from "../components/Search/SearchComboboxes";
import { useAdmin } from "../contexts/AdminContext";
import { useAuth } from "../contexts/AuthContext";
import { useCompany } from "../contexts/CompanyContext";
import { GET_RECORDING_SESSION_UNZIPPED_EVENTS } from "../graphql/recording";

const ResponsiveGridLayout = WidthProvider(Responsive);

const GET_RECORDING_SESSIONS = gql`
  query GetRecordingSessions($limit: Int!, $offset: Int!, $companyId: Int!) {
    recordingSessions(limit: $limit, offset: $offset, companyId: $companyId) {
      items {
        id
        formattedStartTimestamp
        durationMs
        numberOfEventsSeen
      }
      totalItemsCount
    }
  }
`;

interface Click {
  timestamp?: string;
  details: string;
  type: string;
  element: any;
  textContent: string;
  children?: any;
}

interface PageNavigation {
  timestamp?: string;
  details: string;
  url: string;
}

interface PageVisit {
  url: string;
  activeFrom: string;
  activeUntil?: string;
}

interface TextEdit {
  timestamp?: string;
  details: string;
  element: any;
  attributes: any;
  title?: string;
  placeholder?: string;
  children?: any;
}

interface Recording {
  id: string;
  formattedStartTimestamp: string;
  durationMs: number;
  numberOfEventsSeen: number;
}

interface TimelineEvent {
  time: number;
  text: string;
  color?: string;
  Icon: React.ComponentType;
  type: "pageNavigation" | "textEdit" | "click";
}

interface HighlightedSection {
  startTime: number;
  endTime: number;
  text: string;
  color: string;
}

type Metadata = {
  user: string;
  device: {
    os: {
      name: string;
      version: string;
    };
    client: {
      name: string;
      version: string;
    };
  };
  timeZone: string;
  language: string;
};

const DEFAULT_RECORDINGS_PER_PAGE = 25;

// const isDebugMode = false; // import.meta.env.REACT_APP_DEBUG_MODE === "true" || false;

// const fetchLocalEvents = async () => {
//   const response = await fetch(
//     "/rrweb-recordings/replay_2024_04_03T23_17_15.json",
//   );
//   const data = await response.json();
//   return data.data; // Ensure to return the correct structure
// };

const sanitizeEventAttributes = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(sanitizeEventAttributes);
  } else if (obj && typeof obj === "object") {
    const sanitizedObj = Object.keys(obj).reduce((acc, key) => {
      const sanitizedKey = key.replace("@", ""); // Remove '@' from key names
      acc[sanitizedKey] = sanitizeEventAttributes(obj[key]);
      return acc;
    }, {} as any);

    // Ensure required properties are present and valid
    if (sanitizedObj.type === 4 && !sanitizedObj.data) {
      sanitizedObj.data = {}; // Ensure data property is present for type 4 events
    }

    return sanitizedObj;
  }
  return obj;
};

const ReplaysWithSearchAndExaminePage = () => {
  const rrwebPlayerRef = useRef<any>(null);

  const { recordingId } = useParams<{ recordingId?: string }>();
  const { selectedCompany } = useCompany();
  const [selectedRecording, setSelectedRecording] = useState<Recording | null>(
    null,
  );
  const [unzippedEvents, setUnzippedEvents] = useState<any>(null);
  const [timelineEvents, setTimelineEvents] = useState<TimelineEvent[]>([]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [page, setPage] = useState(1);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [playerDimensions, setPlayerDimensions] = useState({
    width: 640,
    height: 480,
  });
  const [eventsError, setEventsError] = useState(false);
  const [recordingsPerPage, setRecordingsPerPage] = useState(
    DEFAULT_RECORDINGS_PER_PAGE,
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { user, isSailfishQAMember } = useAuth();
  const { isAdminEnabled } = useAdmin();
  const [loading, setLoading] = useState(false);

  const [
    fetchRecordings,
    { loading: loadingRecordings, error: errorRecordings },
  ] = useGetRecordingSessionsLazyQuery({
    client,
    variables: {
      limit: recordingsPerPage,
      offset: (page - 1) * recordingsPerPage,
      companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
    },
    onCompleted: (data) => {
      if (!data.recordingSessions) return;
      setRecordings(
        data.recordingSessions.items.map((item) => ({
          ...item,
          durationMs: item.durationMs ? parseInt(item.durationMs, 10) : 0, // Ensure it's a number
        })),
      );
      setTotalItemsCount(data.recordingSessions.totalItemsCount);
    },
  });

  const [fetchUnzippedEvents, { loading: loadingEvents, error }] = useLazyQuery(
    GET_RECORDING_SESSION_UNZIPPED_EVENTS,
    {
      onCompleted: (data) => {
        if (data && data.recordingSession) {
          setMetadata(data.recordingSession.metadata);
          const componentIconMap: { [key: string]: React.ComponentType } = {
            click: HiCursorClick,
            pageNavigation: IoMdBrowsers,
            textEdit: BsInputCursorText,
          };
          const componentColorMap: { [key: string]: string } = {
            click: "fuchsia-200",
            pageNavigation: "sky-200",
            textEdit: "rose-200",
          };

          const tempTimelineEvents: TimelineEvent[] = [];
          data.recordingSession.userInteractions.clicks.forEach(
            (click: Click) => {
              const type = "click";
              if (click.timestamp === undefined) {
                return;
              }
              tempTimelineEvents.push({
                time: +click.timestamp,
                text: `${click.type} on ${click.textContent}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            },
          );
          if (data.recordingSession.pageVisits.length > 0) {
            data.recordingSession.pageVisits.forEach((pageVisit: PageVisit) => {
              const type = "pageNavigation";
              tempTimelineEvents.push({
                time: +pageVisit.activeFrom,
                text: `Navigated to ${pageVisit.url}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            });
          } else {
            data.recordingSession.userInteractions.pageNavigations.forEach(
              (pageNavigation: PageNavigation) => {
                const type = "pageNavigation";
                if (pageNavigation.timestamp === undefined) {
                  return;
                }
                tempTimelineEvents.push({
                  time: +pageNavigation.timestamp,
                  text: `Navigated to ${pageNavigation.url}`,
                  color: componentColorMap[type],
                  Icon: componentIconMap[type],
                  type,
                });
              },
            );
          }
          data.recordingSession.userInteractions.textEdits.forEach(
            (textEdit: TextEdit) => {
              const type = "textEdit";
              if (textEdit.timestamp === undefined) {
                return;
              }
              const fieldTextIdentifier =
                textEdit.placeholder !== undefined
                  ? `Placeholder: ${textEdit.placeholder}`
                  : textEdit.title !== undefined
                  ? `Title: ${textEdit.title}`
                  : textEdit.details;

              tempTimelineEvents.push({
                time: +textEdit.timestamp,
                text: `Typed into field with ${fieldTextIdentifier}`,
                color: componentColorMap[type],
                Icon: componentIconMap[type],
                type,
              });
            },
          );
          setTimelineEvents(tempTimelineEvents);

          const sanitizedEvents = sanitizeEventAttributes(
            data.recordingSession.unzippedEvents,
          );

          if (sanitizedEvents.length === 0) {
            console.error(`Error: No events found for the given session.`);
            setEventsError(true);
            return;
          }

          if (sanitizedEvents.length < 2) {
            console.error(
              `Error: Insufficient events found for the given session.`,
            );
            setEventsError(true);
            return;
          }

          const firstEvent = sanitizedEvents.find(
            (event: any) => event.type === 4,
          );

          if (firstEvent) {
            const { width, height } = firstEvent.data;
            setPlayerDimensions({ width, height });
          } else {
            setPlayerDimensions({ width: 640, height: 480 });
          }

          setUnzippedEvents(sanitizedEvents);
          setEventsError(false);
        }
      },
      onError: (error) => {
        setEventsError(true);
        console.error("Error fetching unzipped events:", error);
      },
    },
  );

  useEffect(() => {
    const fetchEvents = async () => {
      if (recordingId || selectedRecording) {
        const fetchVariables = selectedRecording
          ? {
              id: selectedRecording.id,
              companyId:
                isSailfishQAMember && selectedCompany && isAdminEnabled
                  ? parseInt(selectedCompany.value, 10)
                  : undefined,
            }
          : {
              id: recordingId,
              companyId:
                isSailfishQAMember && selectedCompany && isAdminEnabled
                  ? parseInt(selectedCompany.value, 10)
                  : undefined,
            };
        setEventsError(false);
        fetchUnzippedEvents({ variables: fetchVariables });
      }
    };

    fetchEvents();
  }, [
    recordingId,
    selectedRecording,
    selectedCompany,
    fetchUnzippedEvents,
    user?.email,
    isAdminEnabled,
    isSailfishQAMember,
  ]);

  // Metadata rendering
  const renderMetadata = (metadata: Metadata) => {
    return (
      <VSCodeLikeAccordion
        items={[
          {
            title: "Metadata",
            content: (
              <div
                style={{
                  textAlign: "center",
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <div>
                  <strong
                    style={{
                      display: "block",
                      marginBottom: "8px",
                      fontSize: "1.5em",
                    }}
                  >
                    User
                  </strong>
                  <span>{metadata.user}</span>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <strong
                    style={{
                      display: "block",
                      marginBottom: "8px",
                      fontSize: "1.5em",
                    }}
                  >
                    Device
                  </strong>
                  <span>
                    {`${metadata.device.os.name}, ${metadata.device.os.version}`}
                    <br />
                    {`${metadata.device.client.name}, ${metadata.device.client.version}`}
                  </span>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <strong
                    style={{
                      display: "block",
                      marginBottom: "8px",
                      fontSize: "1.5em",
                    }}
                  >
                    Time Zone
                  </strong>
                  <span>
                    {metadata.timeZone !== null ? metadata.timeZone : "UNKNOWN"}
                  </span>
                </div>
                <div style={{ marginTop: "20px" }}>
                  <strong
                    style={{
                      display: "block",
                      marginBottom: "8px",
                      fontSize: "1.5em",
                    }}
                  >
                    Language
                  </strong>
                  <span>{metadata.language}</span>
                </div>
              </div>
            ),
          },
          {
            title: "Action Picker",
            content: (
              <ActionPickerForAccordion
                events={timelineEvents}
                onHoverEvent={(time) => {
                  // Trigger a hover-like effect in the timeline
                  // Example: update state or call a method to highlight the timeline event
                }}
                onClickEvent={(time) => {
                  // Seek to the time in the player without changing its play state
                  if (rrwebPlayerRef.current) {
                    rrwebPlayerRef.current.seekToTime(time);
                  }
                }}
              />
            ),
            isExpanded: true,
          },
        ]}
      />
    );
  };

  useEffect(() => {
    fetchRecordings({
      variables: {
        limit: recordingsPerPage,
        offset: (page - 1) * recordingsPerPage,
        companyId: selectedCompany ? parseInt(selectedCompany.value, 10) : 0,
      },
    });
  }, [page, recordingsPerPage, fetchRecordings, selectedCompany]);

  const handleSearchResults = (results: any[]) => {
    setRecordings(results);
  };

  const handleTotalItemsCount = (count: number) => {
    setTotalItemsCount(count); // Update the total items count
  };

  useEffect(() => {
    if ((selectedRecording || recordingId) && unzippedEvents) {
      const handleResize = () => {
        if (containerRef.current) {
          const contentHolder = containerRef.current.querySelector(
            "#video-player #content-holder",
          ) as HTMLElement;
          if (contentHolder) {
            const padding = parseInt(
              window.getComputedStyle(contentHolder).padding,
              10,
            );
            const playbackBar = contentHolder.querySelector(
              "#rrweb-playback-bar",
            ) as HTMLElement;
            const playbackBarHeight = playbackBar
              ? playbackBar.offsetHeight +
                parseInt(window.getComputedStyle(playbackBar).marginTop, 10) +
                parseInt(window.getComputedStyle(playbackBar).marginBottom, 10)
              : 0;

            const contentWidth = contentHolder.offsetWidth - padding * 2;
            const contentHeight =
              contentHolder.offsetHeight - padding * 2 - playbackBarHeight;

            let width = contentWidth;
            let height = contentHeight;

            const aspectRatio =
              playerDimensions.width / playerDimensions.height;

            if (contentWidth / contentHeight > aspectRatio) {
              width = contentHeight * aspectRatio;
            } else {
              height = contentWidth / aspectRatio;
            }

            setPlayerDimensions({ width, height });
          } else {
            console.warn("content-holder not found");
          }
        }
      };

      handleResize();

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [
    selectedRecording,
    unzippedEvents,
    recordingId,
    playerDimensions.width,
    playerDimensions.height,
  ]);

  if (error) return <div>Error loading data</div>;

  const totalPages = Math.ceil(totalItemsCount / recordingsPerPage);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    // Calculate the new total pages based on the updated recordingsPerPage
    const newTotalPages = Math.ceil(totalItemsCount / recordingsPerPage);

    // If the current page exceeds the new total pages, set the page to the new max page
    if (page > newTotalPages && newTotalPages > 0) {
      setPage(newTotalPages);
    } else {
      fetchRecordings();
    }
  }, [
    page,
    recordingsPerPage,
    fetchRecordings,
    selectedCompany,
    totalItemsCount,
  ]);

  const handleSelectRecording = (recording: Recording) => {
    if (selectedRecording?.id === recording.id) {
      // Same recording selected, seek back to the start and keep playing
      rrwebPlayerRef.current?.resetPlayer();
    } else {
      // Different recording selected, pause the player and load new recording
      setUnzippedEvents(null); // Reset events to force RRWebPlayer re-initialization
      setSelectedRecording(recording);
      navigate(`/recordings/${recording.id}`);
    }
    rrwebPlayerRef.current?.pause();
  };

  const highlightedSections: HighlightedSection[] = [];

  const tiles = [
    {
      id: "video-player",
      title: "Video Player",
      content: loadingEvents ? (
        <LoadingAnimation text={"Loading..."} />
      ) : unzippedEvents &&
        !eventsError &&
        (selectedRecording || recordingId) ? (
        <div className='h-full'>
          <RRWebPlayerWithTriage
            id={recordingId}
            ref={rrwebPlayerRef}
            events={unzippedEvents}
            timelineEvents={timelineEvents}
            highlightedSections={highlightedSections}
            width={playerDimensions.width}
            height={playerDimensions.height}
          />
        </div>
      ) : (
        <div className='h-full flex items-center justify-center'>
          {eventsError
            ? "Select a different recording; current recording is unfetchable"
            : "Select a Recording"}
        </div>
      ),
      layout: { i: "video-player", x: 0, y: 0, w: 3, h: 5 },
    },
    {
      id: "metadata",
      title: "Metadata",
      content:
        metadata !== null ? (
          renderMetadata(metadata)
        ) : unzippedEvents ? (
          <div></div>
        ) : (
          <div>Loading Metadata...</div>
        ),
      layout: { i: "metadata", x: 3, y: 0, w: 1, h: 5 },
    },
    {
      id: "search-bar",
      title: "Search",
      content: (
        // <SearchBar
        <SearchComboboxes
          entity='Recordings'
          // fields={fields}
          limit={recordingsPerPage}
          offset={(page - 1) * recordingsPerPage}
          onSearchResults={handleSearchResults}
          setTotalItemsCount={handleTotalItemsCount}
          onLoadingChange={setLoading}
        />
      ),
      layout: { i: "search-bar", x: 0, y: 4, w: 4, h: 2 },
    },
    {
      id: "recordings-list",
      title: "Recordings",
      content: (
        <RecordingsList
          recordings={recordings}
          onSelect={handleSelectRecording}
          page={page}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          loading={loadingRecordings}
          recordingsPerPage={recordingsPerPage}
          setRecordingsPerPage={setRecordingsPerPage}
        />
      ),
      layout: { i: "recordings-list", x: 0, y: 6, w: 4, h: 3 },
    },
  ];

  const handleTileResize = (layout: any, oldItem: any, newItem: any) => {
    if (containerRef.current) {
      const tileElement = containerRef.current.querySelector(
        `#${newItem.i}`,
      ) as HTMLElement;
      if (tileElement) {
        const contentHolder = tileElement.querySelector(
          "#content-holder",
        ) as HTMLElement;
        if (contentHolder) {
          const padding = parseInt(
            window.getComputedStyle(contentHolder).padding,
            10,
          );
          const playbackBar = tileElement.querySelector(
            "#rrweb-playback-bar",
          ) as HTMLElement;
          const playbackBarHeight = playbackBar
            ? playbackBar.offsetHeight +
              parseInt(window.getComputedStyle(playbackBar).marginTop, 10) +
              parseInt(window.getComputedStyle(playbackBar).marginBottom, 10)
            : 0;

          const contentWidth = contentHolder.offsetWidth - padding * 2;
          const contentHeight =
            contentHolder.offsetHeight - padding * 2 - playbackBarHeight;

          let width = contentWidth;
          let height = contentHeight;

          const aspectRatio = playerDimensions.width / playerDimensions.height;

          if (contentWidth / contentHeight > aspectRatio) {
            width = contentHeight * aspectRatio;
          } else {
            height = contentWidth / aspectRatio;
          }

          setPlayerDimensions({ width, height });
        } else {
          console.warn("content-holder not found");
        }
      } else {
        console.warn("tileElement not found");
      }
    }
  };

  return (
    <ApplicationPage pageName='Recordings'>
      {/* <Helmet>
        <title>Sailfish - Recordings</title>
        <meta name='description' content='Nested component' />
      </Helmet> */}
      <div ref={containerRef}>
        <ResponsiveGridLayout
          className='layout'
          layouts={{ lg: tiles.map((tile) => tile.layout) }}
          breakpoints={{ lg: 1200 }}
          cols={{ lg: 4 }}
          rowHeight={100}
          width={1200}
          containerPadding={[15, 15]}
          draggableHandle='.drag-handle'
          onResizeStop={(layout, oldItem, newItem) =>
            handleTileResize(layout, oldItem, newItem)
          }
        >
          {tiles.map((tile) => (
            <div key={tile.id} className='p-0 m-0'>
              <DashboardTile
                key={tile.id}
                id={tile.id}
                title={tile.title}
                onRemove={() => {}}
              >
                {tile.content}
              </DashboardTile>
            </div>
          ))}
        </ResponsiveGridLayout>
      </div>
    </ApplicationPage>
  );
};

export default ReplaysWithSearchAndExaminePage;
