// Section.tsx
import React, { useState } from "react";
import {
  TbLayoutNavbarCollapse,
  TbLayoutNavbarExpandFilled,
} from "react-icons/tb";
// import { MdOutlineExpandMore } from "react-icons/md";

interface SectionProps {
  title: string;
  headerClassName?: string;
  children: React.ReactNode;
  defaultCollapsed?: boolean; // Optional prop to set the default collapsed state
}

const Section: React.FC<SectionProps> = ({
  title,
  headerClassName = "bg-blue-500 text-white p-2 rounded-t",
  children,
  defaultCollapsed = false, // Default value is false, meaning not collapsed by default
}) => {
  // Initialize the collapsed state with the defaultCollapsed prop
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  // Function to toggle the collapsed state
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className='mb-6' id={title.toLowerCase().replace(" ", "-")}>
      <div className={headerClassName}>
        <div className='flex justify-between items-center'>
          <h5 className='text-lg font-semibold'>{title}</h5>
          <button onClick={toggleCollapse} className='focus:outline-none'>
            {/* Toggle icon depending on collapse state */}
            {isCollapsed ? (
              <span>
                <TbLayoutNavbarExpandFilled size={20} />
              </span>
            ) : (
              <span>
                <TbLayoutNavbarCollapse size={20} />
              </span>
            )}
          </button>
        </div>
      </div>
      {/* Toggle displaying children based on the collapsed state */}
      {!isCollapsed && (
        <div className='bg-white p-4 rounded-b border border-gray-200'>
          {children}
        </div>
      )}
    </div>
  );
};

export default Section;
