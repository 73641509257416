import { gql, useMutation } from "@apollo/client";
import Button from "@mui/material/Button";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { FaGoogle } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import client from "../apolloClient";
import { useAuth } from "../contexts/AuthContext";
import LoadingAnimation from "./LoadingAnimation";

const AUTHENTICATE_USER_USING_ACCESS_TOKEN = gql`
  mutation AuthenticateUserUsingAccessToken($accessToken: String!) {
    authenticateUserUsingAccessToken(accessToken: $accessToken) {
      message
      token
      userId
      email
      picture
      name
    }
  }
`;

const Login: React.FC = () => {
  localStorage.removeItem("jwt");
  const navigate = useNavigate();
  // const { setAuthenticated, setUser, redirectPath, setRedirectPath } =
  const { setAuthenticated, setUser, setRedirectPath } = useAuth();
  const [authenticateUserUsingAccessToken, { loading, error }] = useMutation(
    AUTHENTICATE_USER_USING_ACCESS_TOKEN,
    {
      client,
    },
  );

  // const [profile, setProfile] = useState<any>();
  // const [appUserLocal, setAppUserLocal] = useState<any>();
  const [, setProfile] = useState<any>();
  const [appUserLocal] = useState<any>();

  const handleCredentialResponse = async (response: any) => {
    try {
      const result = await authenticateUserUsingAccessToken({
        variables: {
          accessToken: response.access_token,
        },
      });

      if (result.data.authenticateUserUsingAccessToken.token) {
        localStorage.setItem(
          "jwt",
          result.data.authenticateUserUsingAccessToken.token,
        );
        localStorage.setItem(
          "user",
          JSON.stringify({
            email: result.data.authenticateUserUsingAccessToken.email,
            name: result.data.authenticateUserUsingAccessToken.name,
            picture: result.data.authenticateUserUsingAccessToken.picture,
          }),
        );
        setUser({
          email: result.data.authenticateUserUsingAccessToken.email,
          name: result.data.authenticateUserUsingAccessToken.name,
          picture: result.data.authenticateUserUsingAccessToken.picture,
        });
        setAuthenticated(true);

        const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
        if (redirectAfterLogin) {
          localStorage.removeItem("redirectAfterLogin");
          navigate(redirectAfterLogin, { replace: true });
        } else {
          navigate("/", { replace: true });
        }
        setRedirectPath("/");
      }
    } catch (e) {
      console.error("Authentication error: ", e);
    }
  };

  const login = useGoogleLogin({
    onSuccess: handleCredentialResponse,
    onError: () => console.log("Login Failed"),
  });

  useEffect(() => {
    if (appUserLocal) {
      fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${appUserLocal.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${appUserLocal.access_token}`,
            Accept: "application/json",
          },
        },
      )
        .then((res) => res.json())
        .then((data) => setProfile(data))
        .catch((err) => console.log(err));
    }
  }, [appUserLocal]);

  const handleLoginClick = () => {
    login();
  };

  // const logOut = () => {
  //   googleLogout();
  //   setProfile(null);
  // };

  if (loading) return <LoadingAnimation text='Loading...' />;
  if (error) return <p>An error occurred: {error.message}</p>;

  return (
    <div className='bg-blue-300 h-screen flex justify-center items-center'>
      <div className='bg-white p-16 rounded-lg shadow-lg flex flex-col items-center'>
        <h2 className='text-5xl mb-6'>Login</h2>
        <Button
          onClick={handleLoginClick}
          startIcon={<FaGoogle />}
          // startIcon={<FcGoogle />} // TODO - enable the colorful icon
          variant='contained'
          style={{ backgroundColor: "#4285F4", color: "white" }}
        >
          Sign in with Google
        </Button>
        <hr className='my-6 border-gray-300 w-full' />
        <div>
          <a href="mailto:em@sailfishqa.com, md@sailfishqa.com?subject=Sign%20me%20up%20for%20Sailfish&body=Hey%20sailfish%20crew%2C%20I'd%20love%20to%20be%20a%20new%20customer!">
            New company? Register now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
