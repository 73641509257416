import { useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import LoadingAnimation from "../components/LoadingAnimation";
import { GET_SECTION_ANNOUNCEMENTS } from "../graphql/announcementsQueries";
import {
  AnnouncementType,
  PaginatedAnnouncements,
} from "../types/AnnoucementTypes";

const AnnouncementsPage = () => {
  const [page, setPage] = useState(1);
  const [announcements, setAnnouncements] = useState<AnnouncementType[]>([]);
  const [hasMore, setHasMore] = useState(false);

  // const { loading, error, data, fetchMore } = useQuery<
  const { loading, error, fetchMore } = useQuery<
    PaginatedAnnouncements,
    { page: number; pageSize: number }
  >(GET_SECTION_ANNOUNCEMENTS, {
    variables: { page, pageSize: 10 },
    notifyOnNetworkStatusChange: true, // Handles network status changes
    onCompleted: (data) => {
      const { announcements, page, totalPages } = data.allAnnouncements;
      if (announcements && Array.isArray(announcements)) {
        setAnnouncements((prev) => [...prev, ...announcements]);
      }
      // Update 'hasMore' based on the totalPages comparison
      setHasMore(page < totalPages);
    },
  });

  const loadMoreAnnouncements = useCallback(() => {
    if (loading || !hasMore) return; // Prevent loading more if already loading or no more data
    setHasMore(false); // Ensure no additional fetches are initiated until current fetch is evaluated

    const nextPage = page + 1;
    fetchMore({
      variables: {
        page: nextPage,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult.allAnnouncements) {
          return prev; // Safeguard against undefined fetch results
        }

        const {
          announcements: newAnnouncements,
          page: newPage,
          // totalPages,
        } = fetchMoreResult.allAnnouncements;
        // Safety check to ensure 'announcements' is always treated as an array
        const updatedAnnouncements = newAnnouncements
          ? [
              ...(prev.allAnnouncements.announcements || []),
              ...newAnnouncements,
            ]
          : prev.allAnnouncements.announcements;

        return {
          ...prev,
          allAnnouncements: {
            ...prev.allAnnouncements,
            announcements: updatedAnnouncements,
            page: newPage,
          },
        };
      },
    });
    setPage(nextPage); // Increment page count only after fetchMore is successfully called
  }, [fetchMore, hasMore, loading, page]);

  if (error) return <div>Error loading announcements: {error.message}</div>;

  return (
    <div>
      <h1>Announcements</h1>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreAnnouncements}
        hasMore={hasMore && !loading}
        loader={
          <div key='loader'>
            <LoadingAnimation text='Loading...' />
          </div>
        }
        useWindow={false}
      >
        {announcements.map((announcement, index) => (
          <div
            key={index}
            style={{
              padding: "10px",
              border: "1px solid #ccc",
              margin: "10px",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: announcement.content }} />
          </div>
        ))}
      </InfiniteScroll>
      {loading && <LoadingAnimation text='Loading...' />}
    </div>
  );
};

export default AnnouncementsPage;
